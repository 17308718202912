import { useEffect, useState } from 'react';
import { axiosV2Instance } from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AssessmentDefinitionResponse } from '../type';

interface useGetAssessmentDefinitionsResponse {
    data: AssessmentDefinitionResponse[];
    loading: boolean;
    error: unknown;
}

interface UseGetAssessmentDefinitionsProps {
    hasAssessments?: boolean; // Optional filter parameter
}

const useGetAssessmentDefinitions = ({ hasAssessments = false }: UseGetAssessmentDefinitionsProps = {}): useGetAssessmentDefinitionsResponse => {
    const [data, setData] = useState<AssessmentDefinitionResponse[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<unknown>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const params = hasAssessments ? { has_assessments: true } : {};
                const response = await axiosV2Instance.get<AssessmentDefinitionResponse[]>(
                    EndPoints.ASSESSMENT_DEFINITIONS,
                    { params }
                );
                setData(response.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []); // Runs once on component mount

    return { data, loading, error };
};

export default useGetAssessmentDefinitions;