import React, { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, Grid } from '@mui/material';
import GaugeChart from '../../../Evaluations/EvaluationAnalysis/EvaluationAnalysisContainer/GaugeChart';
import GasGaugeIcon from '../../../../assets/svg-components/GasGaugeIcon';
import { CustomAccountBranding } from '../../../Branding/type';
import { AssessmentDefinitionResilienceScore } from '../../type';
import { Transition } from 'react-d3-speedometer';
import { ResultChartType } from '../../../Assessments/type';
import BarChart from '../../../Assessments/AssessmentDetails/components/Analysis/BarChart';

interface TotalResilienceScoreGasGaugeProps {
    title: string;
    branding: CustomAccountBranding;
    data: AssessmentDefinitionResilienceScore | null;
}

const TotalResilienceScoreGasGauge: React.FC<TotalResilienceScoreGasGaugeProps> = ({ title, branding, data }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [gaugeWidth, setGaugeWidth] = useState<number | undefined>(undefined);

    const pluralEvaluationPropertyName = branding.evaluation_property_name_translation || "Assessments";
    const singularEvaluationPropertyName = pluralEvaluationPropertyName.endsWith("s")
        ? pluralEvaluationPropertyName.slice(0, -1) // Remove the last character
        : pluralEvaluationPropertyName;
    const titleCaseSingularEvaluationPropertyName = singularEvaluationPropertyName
        ? singularEvaluationPropertyName[0].toUpperCase() + singularEvaluationPropertyName.slice(1).toLowerCase()
        : '';

    useEffect(() => {
        let isMounted = true;

        const handleResize = (entries: ResizeObserverEntry[]) => {
            for (const entry of entries) {
                if (entry.contentRect && isMounted) {
                    const width = entry.contentRect.width;
                    const newGaugeWidth = Math.min(width * 0.8, 700);

                    setGaugeWidth(prevGaugeWidth => {
                        if (newGaugeWidth > 0 && (!prevGaugeWidth || Math.abs(newGaugeWidth - prevGaugeWidth) > 5)) {
                            return newGaugeWidth;
                        }
                        return prevGaugeWidth;
                    });
                }
            }
        };

        const observer = new ResizeObserver(handleResize);

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            isMounted = false;
            observer.disconnect(); // Clean up observer on unmount
        };
    }, []);

    if (!data) {
        return <Box display="flex" justifyContent="center" alignItems="center" minHeight="100%">
            <CircularProgress />
        </Box>
    }

    if (!data.average_score && !data.min_average_possible_score && !data.max_average_possible_score && !data.total_score && !data.max_possible_score) {
        return <Box display="flex" justifyContent="center" alignItems="center" minHeight="100%">
            No Data to Display
        </Box>
    }

    return (
        <Grid container
            ref={containerRef}
            sx={{
                minHeight: '100%',
                width: '100%',
                overflow: 'auto'
            }}>
            <Grid item md={12}
                sx={{
                    paddingTop: "24px",
                    paddingLeft: "24px",
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                }}>
                <GasGaugeIcon height={'20px'} width={'22px'} />
                <Box
                    sx={{
                        fontFamily: "Inter, sans-serif",
                        color: "#00314B",
                        fontWeight: 600,
                        fontSize: '20px',
                        lineHeight: '28px',
                    }}
                >
                    Total Resilience Score
                </Box >
            </Grid>
            <Grid item md={12}
                sx={{
                    paddingTop: "10px",
                    // paddingBottom: "10px",
                    paddingLeft: "24px",
                    fontFamily: "Inter, sans-serif",
                    color: "#2E3645",
                    fontWeight: 400,
                    fontSize: '16px',
                    lineHeight: '20px',
                }}
            >
                The Resilience Score is the average of <span style={{ fontWeight: 600 }}>All {pluralEvaluationPropertyName} by Type.</span>
            </Grid >

            {gaugeWidth !== undefined && data.assessment_definition.result_chart_type !== ResultChartType.BAR_CHART && (
                <Grid item md={12}
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1em', paddingBottom: '10px', marginBottom: 'auto' }}>
                    <GaugeChart
                        score={data.average_score}
                        forceRender={true}
                        minScore={data.min_average_possible_score}
                        maxScore={data.max_average_possible_score}
                        useCard={false}
                        redLegendText='At Risk'
                        yellowLegendText='Need Improvement'
                        greenLegendText='Optimal'
                        gaugeHeight={gaugeWidth * 0.6}
                        gaugeWidth={gaugeWidth}
                        ringWidth={100}
                        needleColor="#3D54A3"
                        displayNeedleScore={true}
                        valueTextFontSize='40px'
                        valuePaddingVertical={16}
                        legendWidth='14px'
                        legendHeight='14px'
                        legendMarginTop='10px'
                        legendMarginBottom='0em'
                        legendFontSize='19px'
                        legendFontWeight={600}
                        legendMaxWidth='100%'
                    // needleTransitionDuration={0}
                    />
                </Grid>
            )}

            {data.assessment_definition.result_chart_type === ResultChartType.BAR_CHART && (
                <Grid item md={12}
                    sx={{
                        paddingBottom: '10px',
                        marginBottom: 'auto',
                        paddingX: '1em',
                    }}>
                    <BarChart
                        cumulative_average_score={Number(data.total_score)}
                        max_cumulative_score={Number(data.max_possible_score)}
                        bar_height='70px'
                        bar_width='100%'
                        bar_color='#053d58'
                    />
                </Grid>
            )}
        </Grid >
    );
};
export default TotalResilienceScoreGasGauge;
