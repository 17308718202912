import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Container } from '@mui/material';
import { useBranding } from '../Branding/reducers/getBranding';
import { FeatureFlags } from '../Branding/type';
import { Navigate, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootStoreType } from '../../redux/store.type';
import AccountExecutiveDashboard from './AccountExecutiveDashboard/AccountExecutiveDashboard';
import ClientDashboard from './Client/ClientDashboard'
import useGetDashboardConfiguration from './hooks/useGetDashboardConfigurations';
import { createSelector } from 'reselect';

const Dashboard = () => {
    const branding = useBranding();
    const [loading, setLoading] = useState(true);
    const [dashboardAccess, setDashboardAccess] = useState<boolean | null>(null);

    const loggedInUser = useSelector((state: RootStoreType) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return state.authReducers.user.currentUser;
    });

    // const dashboardConfiguration = useSelector(
    //     (state: RootStoreType) =>
    //         state.dashboardReducers.getDashboardConfigurationReducer.dashboardConfiguration,
    //     // DashboardConfigurationItem[] | null
    // );

    const isAdminOrAE = (loggedInUser?.user_type?.includes('admin') ||
        loggedInUser?.user_type?.includes('account_executive'))

    const isClient = loggedInUser?.user_type?.includes('client');

    useEffect(() => {
        if (branding !== null) {
            setDashboardAccess(branding?.active_feature_flags?.[FeatureFlags.DASHBOARD] === true);
            setLoading(false);
        }
    }, [branding]);


    if (loading || !branding || !loggedInUser) {
        return (
            <Container>
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                    <CircularProgress />
                </Box>
            </Container>
        );
    }

    return (dashboardAccess && isAdminOrAE) ? (
        <AccountExecutiveDashboard
            branding={branding}
            loggedInUser={loggedInUser} />
    ) : (dashboardAccess && isClient) ? (
        <ClientDashboard
            branding={branding}
            loggedInUser={loggedInUser}
        />
    ) : <Navigate to="/account-list" replace />;
};

export default Dashboard;


