import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import { AxiosResponse } from 'axios';
import axiosInstance, { axiosV2Instance } from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { DashboardConfigurationItemComponent } from '../type';

export interface DashboardConfigurationReducer {
    status: RequestStatus;
    dashboardConfiguration: DashboardConfigurationItemComponent[] | null;
}

const initialState: DashboardConfigurationReducer = {
    status: RequestStatus.Init,
    dashboardConfiguration: null,
};

export const getDashboardConfiguration = createAsyncThunk<DashboardConfigurationItemComponent[], void>(
    '/getDashboardConfiguration',
    async (_, thunkAPI) => {
        try {
            const response: AxiosResponse = await axiosInstance.get(EndPoints.DASHBOARD_CONFIGURATION as string);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return response.data as DashboardConfigurationItemComponent[];
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

const getDashboardConfigurationSlice = createSlice({
    name: '/getDashboardConfiguration',
    initialState,
    reducers: {
        resetDashboardConfigurationState: (state) => {
            // Reset the state to the initial state
            Object.assign(state, initialState);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getDashboardConfiguration.pending, (state) => {
            state.status = RequestStatus.Loading;
        });
        builder.addCase(getDashboardConfiguration.fulfilled, (state, action) => {
            state.status = RequestStatus.Done;
            // Only update `dashboardConfiguration` if the data has changed
            if (JSON.stringify(state.dashboardConfiguration) !== JSON.stringify(action.payload)) {
                state.dashboardConfiguration = action.payload;
            }
        });
        builder.addCase(getDashboardConfiguration.rejected, (state) => {
            state.status = RequestStatus.Error;
        });
    },
});

export const { resetDashboardConfigurationState } = getDashboardConfigurationSlice.actions;

export default getDashboardConfigurationSlice.reducer;