import React, { useState, FunctionComponent, ChangeEvent, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import IntakeFormCompanySize from '../../IntakeFormCompanySize';
import IntakeFormCountyIntake from './IntakeFormCountyIntake';
import IntakeFormContactForm from './IntakeFormContactForm';
import IntakeFormIndustryType from './IntakeFormIndustryType';
import IntakeFormRegionalManufacturersAssoc from './IntakeFormRegionalManufacturersAssoc';
import IntakeFormLegalStatement from './IntakeFormLegalStatement';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IntakeFormCompanySelectionForm from './IntakeFormCompanySelectionForm';
import { CustomAccountBranding } from '../../../modules/Branding/type';
import { AssessmentDefinition } from '../../../modules/Assessments/type';
import { AccountDetails } from '../../../modules/Accounts/types';

export const ContinueButton: FunctionComponent<{
  onClick: () => void;
  disabled: boolean;
  text?: string;
}> = ({ onClick, disabled, text = "Continue" }) => (
  <div>
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      disabled={disabled}
    >
      {text} <ArrowForwardIcon sx={{ marginLeft: '8%' }} />
    </Button>
  </div>
);

export const PreviousButton: FunctionComponent<{ onClick: () => void }> = ({
  onClick,
}) => (
  <div>
    <Button variant="contained" color="primary" onClick={onClick}>
      <ArrowBackIcon sx={{ marginRight: '8%' }} /> Back
    </Button>
  </div>
);

interface IntakeFormDemographicsProps {
  formData: {
    firstName: string;
    lastName: string;
    jobTitle: string;
    email: string;
    companyName: string;
    companySize: string;
    industryType: string[];
    regionalManufacturersAssoc: string[];
    address2: string;
    phoneNumber: string;
    city: string;
    state: string;
    zipCode: string;
    county: string;
    participationAgreement: string;
  };
  onFormChange: (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
      | SelectChangeEvent<string[]>,
  ) => void;
  onNextClick: () => void;
  onStepChange: (number) => void;
  branding: CustomAccountBranding;
  assessmentDefinitions: AssessmentDefinition[] | null;
  selectedCompany: AccountDetails | null;
  selectedCompanyChange: React.Dispatch<React.SetStateAction<AccountDetails | null>>;
}

const IntakeFormDemographics: React.FC<IntakeFormDemographicsProps> = ({
  formData,
  onFormChange,
  onNextClick,
  onStepChange,
  branding,
  assessmentDefinitions,
  selectedCompany,
  selectedCompanyChange
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isNewCompanyNeeded, setIsNewCompanyNeeded] = useState(false);
  useEffect(() => {
    if (selectedCompany) {
      // pre-populate the form if we have a selected company
      formData.companyName = selectedCompany.name || '';
      formData.address2 = selectedCompany.address1 || '';
      formData.city = selectedCompany.city || '';
      formData.state = selectedCompany.state || '';
      formData.zipCode = selectedCompany.zipcode || '';
    }
  }, [selectedCompany]);
  const handleCompanySelect = (companyData = false) => {
    // if we have company data, we don't need to create a new company, 
    // reset the fields and selected company
    setIsNewCompanyNeeded(Boolean(companyData));
    selectedCompanyChange(null);
    formData.companyName = ''
    formData.address2 = ''
    formData.city = ''
    formData.state = ''
    formData.zipCode = ''
    formData.phoneNumber = ''
    handleNextStep();
  };

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    if (currentStep == 0) {
      //onStepChange is prop method to set parent tab back to previous tab
      //only want to do this when on the first or 0 step
      onStepChange(1);
      return;
    }
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const isStepValid = (): boolean => {
    switch (currentStep) {
      case 0:
        return !!selectedCompany;
      case 1: {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const validEmail = emailRegex.test(formData?.email || "");

        const phoneRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
        const validPhone = phoneRegex.test(formData?.phoneNumber || "");

        //theres got to be a better way, doing this for times sake.
        const requiredFields = [
          formData?.firstName,
          formData?.lastName,
          formData?.jobTitle,
          formData?.companyName,
          formData?.address2,
          formData?.city,
          formData?.state,
          formData?.zipCode,
        ];

        return requiredFields.every(field => !!field) && validEmail && validPhone;
      }
      case 2:
        return formData.county !== '';
      case 3:
        return formData.companySize !== '';
      case 4:
        return formData.industryType.length > 0;
      case 5:
        return formData.regionalManufacturersAssoc.length > 0;
      case 6:
        return formData.participationAgreement == 'I Agree';
      default:
        return true;
    }
  };

  const isNextButtonDisabled = !isStepValid();

  return (
    <Container>
      <>
        {currentStep === 0 && (
          <>
            <IntakeFormCompanySelectionForm
              onCompanySelect={handleCompanySelect}
              branding={branding}
              assessmentDefinitions={assessmentDefinitions}
              selectedCompany={selectedCompany}
              selectedCompanyChange={selectedCompanyChange}
            />
            <Grid
              item
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
                marginTop: '5%',
              }}
            >
              <PreviousButton onClick={handlePreviousStep} />
              <ContinueButton
                onClick={handleNextStep}
                disabled={isNextButtonDisabled}
              />
            </Grid>
          </>)
        }
        {currentStep === 1 && (
          <>
            <IntakeFormContactForm
              formData={formData}
              onFormChange={onFormChange}
            />


            <Grid
              item
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
                marginTop: '5%',
              }}
            >
              <PreviousButton onClick={handlePreviousStep} />
              <ContinueButton
                onClick={handleNextStep}
                disabled={isNextButtonDisabled}
              />
            </Grid>
          </>
        )
        }
        {currentStep === 2 && (
          <>
            <IntakeFormCountyIntake
              formData={formData}
              onFormChange={onFormChange}
            />
            <Grid
              item
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
                marginTop: '45%',
              }}
            >
              <PreviousButton onClick={handlePreviousStep} />
              <ContinueButton
                onClick={handleNextStep}
                disabled={isNextButtonDisabled}
              />
            </Grid>
          </>
        )}
        {currentStep === 3 && (
          <>
            <IntakeFormCompanySize
              value={formData.companySize}
              onChange={(value) => {
                const event = {
                  target: {
                    name: 'companySize',
                    value: value
                  }
                } as SelectChangeEvent<string>;
                onFormChange(event);
              }}
            />
            <Grid
              item
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
                marginTop: '45%',
              }}
            >
              <PreviousButton onClick={handlePreviousStep} />
              <ContinueButton
                onClick={handleNextStep}
                disabled={isNextButtonDisabled}
              />
            </Grid>
          </>
        )}
        {currentStep === 4 && (
          <>
            <IntakeFormIndustryType
              formData={formData}
              onFormChange={onFormChange}
            />
            <Grid
              item
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
                marginTop: '45%',
              }}
            >
              <PreviousButton onClick={handlePreviousStep} />
              <ContinueButton
                onClick={handleNextStep}
                disabled={isNextButtonDisabled}
              />
            </Grid>
          </>
        )}
        {currentStep === 5 && (
          <>
            <IntakeFormRegionalManufacturersAssoc
              formData={formData}
              onFormChange={onFormChange}
            />
            <Grid
              item
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
                marginTop: '45%',
              }}
            >
              <PreviousButton onClick={handlePreviousStep} />
              <ContinueButton
                onClick={handleNextStep}
                disabled={isNextButtonDisabled}
              />
            </Grid>
          </>
        )}
        {currentStep === 6 && (
          <>
            <IntakeFormLegalStatement
              formData={formData}
              onFormChange={onFormChange}
            />
            <Grid
              item
              sx={{
                justifyContent: 'space-between',
                display: 'flex',
                marginTop: '5%',
              }}
            >
              <PreviousButton onClick={handlePreviousStep} />
              <ContinueButton
                onClick={onNextClick}
                disabled={isNextButtonDisabled}
                text={"Submit"}
              />
            </Grid>
          </>
        )}
      </>
    </Container>
  );
};

export default IntakeFormDemographics;
