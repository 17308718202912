import React from 'react';
import { Box, IconButton, Typography, Grid, Tooltip } from '@mui/material';
import { LiteAssessmentOverviewDetails, AccountInfo } from '../../../../Assessments/type';
import { AccountSettingsUser } from '../../../../Accounts/AccountSettings/AccountSettings';
import { FieldName, FieldValue, GridItemFieldName, GridItemFieldValue } from '../EvaluationDetails.style';
import IdentificationBadgeIcon from '../../../../../assets/svg-components/IdentificationBadgeIcon';
import AtIcon from '../../../../../assets/svg-components/AtIcon';
import HouseSimpleIcon from '../../../../../assets/svg-components/HouseSimpleIcon';
import MapPinIcon from '../../../../../assets/svg-components/MapPinIcon';
import PhoneIcon from '../../../../../assets/svg-components/PhoneIcon';
import { AccountContact } from '../../../../Accounts/types';
import companySizeData from '../../../../../components/IntakeForm/helpers/CompanySize';
import CompanySizeDisplay from '../../../../../components/CompanySizeDisplay';
import industryTypes from '../../../../../components/IntakeForm/helpers/IndustryTypes';
import regionalManuAssoc from '../../../../../components/IntakeForm/helpers/RegionalManufacturersAssociations';
import counties from '../../../../../components/IntakeForm/helpers/Counties';


const formatPhoneNumber = (phone: string): string => {
  // Remove all non-digit characters
  const digits = phone.replace(/\D/g, "");

  // Apply formatting based on length
  if (digits.length === 10) {
    // Format as (123) 456-7890
    return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6)}`;
  } else if (digits.length === 11 && digits.startsWith("1")) {
    // Format as +1 (123) 456-7890
    return `+${digits[0]} (${digits.slice(1, 4)}) ${digits.slice(4, 7)}-${digits.slice(7)}`;
  } else if (digits.length === 7) {
    // Format as 123-4567
    return `${digits.slice(0, 3)}-${digits.slice(3)}`;
  }

  // If the phone number doesn't match expected lengths, return as-is
  return phone;
};

const GeneralAssessmentInfoCard = ({
  lightInfo,
  accountInfo,
  accountUserData,
  content,
  accountExecutiveNameTranslation
}: {
  lightInfo: LiteAssessmentOverviewDetails;
  accountInfo: AccountInfo;
  accountUserData: AccountSettingsUser[];
  content: string;
  accountExecutiveNameTranslation: string;
}) => {
  const primaryContact = accountInfo?.account_contacts?.find(
    (contact: AccountContact) => contact.is_primary_contact,
  );

  // Compute additional info
  // Fix the props to match the CompanySizeDisplay component interface
  const companySizeLabel = accountInfo?.company_size ? 
    <CompanySizeDisplay employeeCount={accountInfo.company_size} showCount={true} /> : 
    '-';
  
  const industryCode = accountInfo?.naics_codes && accountInfo.naics_codes.length ? accountInfo.naics_codes[0] : 0;
  const industryLabel = industryTypes.find(item => item.id === industryCode)?.definition || '';
  const regionalAssoc = accountInfo?.regional_manufacturers_associations && accountInfo.regional_manufacturers_associations.length
    ? regionalManuAssoc.filter(item => accountInfo.regional_manufacturers_associations.includes(item.id))
        .map(item => item.name).join(', ')
    : '';
  const countyName = accountInfo?.county || '';

  return (
    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            marginLeft: '10px',
            marginBottom: '1em',
            backgroundColor: '#FFF',
            padding: '30px 20px',
            borderRadius: '16px',
            border: '1px solid #E7E9F9',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              marginBottom: '1em',
            }}
            variant="h5"
            component="h2"
            gutterBottom
          >
            General Info
          </Typography>
          <Box>
            <Grid container spacing={2}>
              {/* Removed ID field */}
              {/* Removed Target Responses field */}
              <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton disabled sx={{ marginRight: 1, padding: 0 }}>
                  <IdentificationBadgeIcon width={20} height={20} />
                </IconButton>
                <Typography variant="body1">Contact</Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', justifyContent: content }}>
                <Typography variant="body1">
                  {primaryContact && primaryContact.first_name && primaryContact.last_name
                    ? `${primaryContact.first_name} ${primaryContact.last_name}`
                    : primaryContact?.name || 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton disabled sx={{ marginRight: 1, padding: 0 }}>
                  <HouseSimpleIcon width={20} height={20} />
                </IconButton>
                <Typography variant="body1">Company</Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', justifyContent: content }}>
                <Typography variant="body1">{accountInfo?.name || 'N/A'}</Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton disabled sx={{ marginRight: 1, padding: 0 }}>
                  <MapPinIcon width={20} height={20} />
                </IconButton>
                <Typography variant="body1">Location</Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', justifyContent: content }}>
                <Tooltip title={accountInfo?.address1 || 'N/A'} placement="top-start">
                  <Typography variant="body1" sx={{ maxWidth: '200px' }}>
                    {accountInfo?.address1 || 'N/A'}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton disabled sx={{ marginRight: 1, padding: 0 }}>
                  <PhoneIcon width={20} height={20} />
                </IconButton>
                <Typography variant="body1">Phone</Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', justifyContent: content }}>
                <Typography variant="body1">
                  {primaryContact?.phone ? formatPhoneNumber(primaryContact.phone) : 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton disabled sx={{ marginRight: 1, padding: 0 }}>
                  <AtIcon width={20} height={20} />
                </IconButton>
                <Typography variant="body1">Email</Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', justifyContent: content }}>
                <Typography variant="body1">{accountInfo?.email || 'N/A'}</Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton disabled sx={{ marginRight: 1, padding: 0 }}>
                  <IdentificationBadgeIcon width={20} height={20} />
                </IconButton>
                <Typography variant="body1">{accountExecutiveNameTranslation}</Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', justifyContent: content }}>
                <Typography variant="body1">
                  {accountUserData?.find(
                    (contact: AccountSettingsUser) =>
                      contact.role === 'account_executive',
                  )?.fullname || 'N/A'}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
      
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            marginLeft: '10px',
            marginBottom: '1em',
            backgroundColor: '#FFF',
            padding: '30px 20px',
            borderRadius: '16px',
            border: '1px solid #E7E9F9',
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 600,
              marginBottom: '1em',
            }}
            variant="h5"
            component="h2"
            gutterBottom
          >
            Demographic Information
          </Typography>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton disabled sx={{ marginRight: 1, padding: 0 }}>
                  {/* <BusinessIcon width={20} height={20} /> */}
                </IconButton>
                <Typography variant="body1">County</Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', justifyContent: content }}>
                <Typography variant="body1">{countyName || '-'}</Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton disabled sx={{ marginRight: 1, padding: 0 }}>
                  {/* <PeopleIcon width={20} height={20} /> */}
                </IconButton>
                <Typography variant="body1">Company Size</Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', justifyContent: content }}>
                <Typography variant="body1">{companySizeLabel}</Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton disabled sx={{ marginRight: 1, padding: 0 }}>
                  {/* <FactoryIcon width={20} height={20} /> */}
                </IconButton>
                <Typography variant="body1">Industry Type</Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', justifyContent: content }}>
                <Typography variant="body1">{industryCode ? industryLabel || '-' : '-'}</Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton disabled sx={{ marginRight: 1, padding: 0 }}>
                  {/* <PeopleIcon width={20} height={20} /> */}
                </IconButton>
                <Typography variant="body1">Regional Manufacturers Association</Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: 'flex', justifyContent: content }}>
                <Typography variant="body1">{regionalAssoc || '-'}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default GeneralAssessmentInfoCard;
