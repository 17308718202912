import React from 'react';
import { Box, Typography, Grid, Link, IconButton, Tooltip } from '@mui/material';
import { CustomAccountBranding } from './../../Branding/type';
import SurveryCompletionGraphic from '../../../assets/svg-components/SurveryCompletionGraphic';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';
import NotDoneYetBanner from '../../../assets/svg-components/NotDoneYetBanner';
import { CenterFocusStrong } from '@mui/icons-material';

interface CompletionConfirmationTabProps {
    childSurveyLink: string | null;
    branding: CustomAccountBranding;
}

const CompletionConfirmationTab: React.FC<CompletionConfirmationTabProps> = ({ childSurveyLink, branding }) => {
    return (<>
        {/* Main Thank You Message Section */}
        {!childSurveyLink && (
            <Grid container
                spacing={2}
                sx={{
                    padding: 2,
                    marginX: '5%',
                    marginTop: '3em',
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                <Grid item xs={12}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: 'white',
                            // padding: '20px',
                            borderRadius: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '85%',
                            gap: 5
                        }}
                    >
                        <Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    color: branding.primary_color || '#053D58',
                                    fontFamily: 'Inter',
                                    fontSize: '48px',
                                    fontWeight: 700,
                                }}
                            >
                                Thank you for completing the survey!
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    color: '#6A6A6A',
                                    fontFamily: 'Inter',
                                    fontSize: '24px',
                                    fontWeight: 500,
                                    mt: '5%',
                                    mb: '5%',
                                }}
                            >
                                Your feedback is greatly appreciated.
                            </Typography>
                        </Box>
                        <SurveryCompletionGraphic />
                    </Box>
                </Grid>
            </Grid>
        )}


        {childSurveyLink && (
            <Grid container
                spacing={2}
                sx={{
                    padding: 2,
                    marginX: '5%',
                    marginTop: '3em',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                <Grid item xs={12}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'start',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: 'white',
                            // padding: '20px',
                            borderRadius: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '85%',
                        }}
                    >
                        <Box>
                            <Typography
                                variant="h4"
                                sx={{
                                    color: '#626262',
                                    fontFamily: 'Inter',
                                    fontSize: '31px',
                                    fontWeight: 700,
                                    mb: '2%',
                                }}
                            >
                                Thank you!
                            </Typography>
                            <NotDoneYetBanner />
                            <Typography
                                variant="h4"
                                sx={{
                                    color: branding.primary_color || '#053D58',
                                    fontFamily: 'Inter',
                                    fontSize: '38px',
                                    fontWeight: 700,
                                    mt: '2%',
                                    mb: '2%',
                                }}
                            >
                                Continue with the Employee Engagement Survey!
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '0em' }}>
                    <Box
                        sx={{
                            borderTop: '2px solid #CE193B',
                            borderLeft: '2px solid #CE193B',
                            borderRight: '6px solid #CE193B',
                            borderBottom: '6px solid #CE193B',
                            borderRadius: '8px',
                            paddingY: '2em',
                            width: '85%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start', width: 'auto' }}>
                            <Typography
                                variant="h6"
                                sx={{
                                    color: '#CE193B',
                                    fontFamily: 'Inter',
                                    fontSize: '30px',
                                    fontWeight: 600,
                                    mb: 2,
                                }}
                            >
                                This is your direct link to the Employee Engagement Survey:
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: '#6A6A6A',
                                    fontFamily: 'Inter',
                                    fontSize: '24px',
                                    fontWeight: 500,
                                    mb: 2,
                                }}
                            >
                                Copy and share this link with your workforce to quickly assess their perceptions.
                            </Typography>

                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Tooltip title="Open link">
                                    <IconButton
                                        onClick={() => window.open(childSurveyLink, '_blank')}
                                        size="large"
                                        sx={{
                                            color: '#6A6A6A',
                                            backgroundColor: '#E9F2FA',
                                            borderRadius: '0px',
                                            padding: '12px',
                                            mr: 1,
                                            border: '2px solid #D7DDF0'
                                        }}
                                    >
                                        <LinkIcon fontSize="inherit" />
                                    </IconButton>
                                </Tooltip>

                                <Link
                                    href={childSurveyLink}
                                    sx={{
                                        color: '#2E3645',
                                        textDecoration: 'underline',
                                        fontFamily: 'Inter',
                                        fontSize: '18px',
                                        fontWeight: 400,
                                        wordBreak: 'break-all',
                                        backgroundColor: '#F7F7F7',
                                        borderRadius: '4px',
                                        padding: '12px',
                                        lineHeight: '27px',
                                    }}
                                >
                                    {childSurveyLink}
                                </Link>

                                {/* Tooltip with IconButton */}
                                <Tooltip title="Copy link">
                                    <IconButton
                                        onClick={() =>
                                            navigator.clipboard.writeText(childSurveyLink)
                                        }
                                        size="small"
                                        sx={{
                                            color: '#6A6A6A',
                                            ml: 1,
                                            backgroundColor: '#E9F2FA',
                                            borderRadius: '4px',
                                            padding: '12px',
                                        }}
                                    >
                                        <ContentCopyIcon fontSize="small" />
                                        <Typography
                                            sx={{
                                                fontSize: '18px',
                                                fontWeight: 600,
                                                ml: 0.5,
                                            }}
                                        >
                                            Copy
                                        </Typography>
                                    </IconButton>
                                </Tooltip>
                            </Box>

                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: '1em',
                        marginBottom: '5em'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '80%',
                        }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                color: '#514E4E',
                                fontFamily: 'Inter',
                                fontSize: '18px',
                                fontWeight: 400,
                            }}>

                            <span style={{
                                fontWeight: 600
                            }}>
                                The Employee Engagement Survey is the final component of the Employer of Choice Assessment, and accounts for 10% of the overall company score.
                            </span> Participation is confirmed when survey responses are received. The results of the Employee Engagement Survey are strictly confidential and intended for internal use only. They will not be shared, distributed, or disclosed to any external parties. The information gathered is solely for the purpose of improving workplace engagement, decision-making, and organizational growth.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        )}
    </>
    );
};

export default CompletionConfirmationTab;
