import {
  styled,
  Paper,
  Container,
  Typography,
  Box,
  Grid,
  Card,
  Button,
} from '@mui/material';

const flexDisplay = `
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const TypographyFontWeight = `
font-weight: 700;
margin-left: 20px;
`;

export const TeamMemberTypography = styled(Typography)`
  ${TypographyFontWeight}
  margin-top: 5%;
`;

export const CreateClientHeader = styled(Paper)`
  ${flexDisplay}
  align-items: center;
  padding: 20px;
  box-shadow: 0 0 0;
`;

export const ClientContainer = styled(Container)`
  ${flexDisplay}
  justify-content: flex-start;
  padding: 20px;
`;

export const Item = styled(Box)`
  background-color: #ffffff;
  padding: 20px;
`;

export const FormFieldContainer = styled(Box)`
  ${flexDisplay}
  flex-direction:column;
  padding-left: 10px;
  width: 95%;
  gap: 5px 0;
  margin-bottom: 18px;
`;

export const Form = styled('form')`
  max-width: 1240px;
`;

export const ContactFormGrid = styled(Grid)`
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 18px;
`;

export const CustomGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 0;
`;

export const FormArrayButton = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const AccountPageCard = styled(Card)`
  box-shadow: 0px 10px 20px 0px rgba(194, 194, 194, 0.16);
  padding: 10px;
  max-width: 1240px;
  border: none;
`;

export const AccountCardHeading = styled(Typography)`
  font-weight: 600;
  font-family: 'Inter';
  font-size: 14px;
  padding-left: 10px;
`;

export const AccountCardSubHeading = styled(Typography)`
  color: '#B5B5C3';
  font-family: 'Inter';
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 10px;
`;

export const SaveButton = styled(Button)(({ theme }) => ({
  textDecoration: 'none',
  textTransform: 'none',
  borderRadius: '6px',
  background: theme.palette.primary.main,
  color: '#fff',
  display: 'flex',
  padding: '4px 8px',
  '&:hover': {
    background: theme.palette.primary.main,
  },
  '&:disabled': {
    background: 'grey',
    color: 'white',
    cursor: 'not-allowed',
  }
}));

export const CancelButton = styled(Button)`
  text-decoration: none;
  text-transform: none;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.05);
  color: black;
  display: flex;
  padding: 4px 8px;

  &:hover {
    background: rgba(0, 0, 0, 0.03);
  }
`;

export const DaniaButton = styled(Button)`
  text-decoration: none;
  text-transform: none;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  background: #FFFFFF;
  color: #7F849B;
  display: flex;
  padding: 4px 16px;
`;
