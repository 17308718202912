import { RootStoreType } from '../../../../../redux/store.type';
import { useSelector } from 'react-redux';
import TotalResilienceScoreGasGauge from '../TotalResilienceScoreGasGauge';
import MostFrequentlyOfferedSolutions from '../MostFrequentlyOfferedSolutions';
import AssessmentScoringByCategory from '../ScoringByCategory/AssessmentScoringByCategory';
import HighestScoringStatements from '../ScoringStatements/HighestScoringStatements';
import LowestScoringStatements from '../ScoringStatements/LowestScoringStatements';
import { AssessmentDefinitionCategoryScores, AssessmentDefinitionResilienceScore, AssessmentDefinitionSolutions, AssessmentDefinitionStatementScores } from '../../../type';

const ComponentDataStoreMap = new Map<
  React.FC<any>,
  (state: RootStoreType) => any
>([
  [TotalResilienceScoreGasGauge, (state) => state.dashboardReducers.getAssessmentDefinitionResilienceScoreReducer.resilience_score as AssessmentDefinitionResilienceScore | null],
  [MostFrequentlyOfferedSolutions, (state) => state.dashboardReducers.getAssessmentDefinitionOfferedSolutionsReducer.offered_solutions as AssessmentDefinitionSolutions | null],
  [AssessmentScoringByCategory, (state) => state.dashboardReducers.getAssessmentDefinitionCategoryScoresReducer.category_scores as AssessmentDefinitionCategoryScores | null],
  [HighestScoringStatements, (state) => state.dashboardReducers.getAssessmentDefinitionQuestionScoresReducer.question_scores as AssessmentDefinitionStatementScores | null],
  [LowestScoringStatements, (state) => state.dashboardReducers.getAssessmentDefinitionQuestionScoresReducer.question_scores as AssessmentDefinitionStatementScores | null],
  // Add other components and their selectors as needed
]);

export default ComponentDataStoreMap;
