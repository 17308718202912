import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Container } from '@mui/material';

import { useBranding } from '../../Branding/reducers/getBranding';
import LegacyEvaluationList from '../../Evaluations/EvaluationList/components/v1/LegacyEvaluationList';
import AssessmentList from './components/AssessmentList';
import { FeatureFlags } from '../../Branding/type'

const AssessmentListContianer = () => {
  const branding = useBranding();
  const [loading, setLoading] = useState(true);
  const [useLegacyEndpoints, setUseLegacyEndpoints] = useState<boolean | null>(null);
  const [displayEmployerOfChoiceIntakeFormLink, setDisplayEmployerOfChoiceIntakeFormLink] = useState<boolean | null>(null);

  useEffect(() => {
    if (branding !== null) {
      // When branding data is fetched, update the loading state and the useLegacyEndpoints flag
      setUseLegacyEndpoints(branding?.active_feature_flags?.[FeatureFlags.LEGACY_V1_ENDPOINTS] === true);
      setDisplayEmployerOfChoiceIntakeFormLink(branding?.active_feature_flags?.[FeatureFlags.EOC_INTAKE_FORM_LINK] === true);
      setLoading(false);
    }
  }, [branding]);

  if (loading || !branding) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  // TODO: Get this to work with V2
  return useLegacyEndpoints ? (
    <LegacyEvaluationList />
  ) : (
    <AssessmentList
      branding={branding}
      displayEmployerOfChoiceIntakeFormLink={displayEmployerOfChoiceIntakeFormLink}
    />
  );
};

export default AssessmentListContianer;
