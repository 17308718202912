import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import axiosInstance from "../../../config/axios";
import { EndPoints } from "../../../constants/apiUrls";
import { RequestStatus } from "../../../types/request";

const initialState = {
    status: RequestStatus.Init
}

export const deleteDashboardConfigurationThunk = createAsyncThunk(`deleteDashboardConfiguration`,
    async (assessmentDefinitionId: string, { rejectWithValue }) => {
        try {
            const result: AxiosResponse<string> = await axiosInstance.delete(
                `${EndPoints.DASHBOARD_CONFIGURATION_ASSESSMENT_DEFINITIONS}/${assessmentDefinitionId}`);
            return result.data;

        } catch (error) {
            return rejectWithValue(error)
        }
    })

const deleteDashboardConfigurationSlice = createSlice({
    name: `dashboard/deleteDashboardConfiguration`,
    initialState: initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(deleteDashboardConfigurationThunk.pending, state => {
            state.status = RequestStatus.Loading
        });
        builder.addCase(deleteDashboardConfigurationThunk.fulfilled, state => {
            state.status = RequestStatus.Done;
        });
        builder.addCase(deleteDashboardConfigurationThunk.rejected, state => {
            state.status = RequestStatus.Error
        });
    },
})

export const deleteDashboardConfigurationReducer = deleteDashboardConfigurationSlice.reducer;