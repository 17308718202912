import React from 'react';

import QuestionDataComponentMapper from './QuestionDataComponentMapper';
import TotalResilienceScoreGasGauge from '../TotalResilienceScoreGasGauge';
import MostFrequentlyOfferedSolutions from '../MostFrequentlyOfferedSolutions';
import AssessmentScoringByCategory from '../ScoringByCategory/AssessmentScoringByCategory';
import HighestScoringStatements from '../ScoringStatements/HighestScoringStatements';
import LowestScoringStatements from '../ScoringStatements/LowestScoringStatements';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { AssessmentDefinitionResponse, AssessmentQuestionResponseDetails, MultipleChoiceType, QuestionType } from '../../../../Assessments/type';
import { CustomAccountBranding } from '../../../../Branding/type';
import { NavigateFunction } from 'react-router-dom';
import MultipleChoiceResponseOptionsChart from '../QuestionComponents/5ResponseOptionsChart';
import BinaryResponseChart from '../QuestionComponents/BinaryResponseChart';
import FillInNumberResponseChart from '../QuestionComponents/FillInNumberResponseChart';
import ResponseDistributionChart from '../QuestionComponents/ResponseDistributionChart';

const QuestionDataComponent = ({ title, description, branding, navigate, data, selectedAssessmentDefinition }: {
    title: string;
    description: string;
    branding: CustomAccountBranding;
    navigate: NavigateFunction;
    data: AssessmentQuestionResponseDetails;
    selectedAssessmentDefinition: AssessmentDefinitionResponse;
}) => {
    const UnknownQuestionComponent: React.FC = () => <p>Unknown Question Type</p>;

    const questionComponentsMapper = (questionDetails: AssessmentQuestionResponseDetails): React.FC<any> => {
        if (
            questionDetails.question_type === QuestionType.MULTIPLE_CHOICE ||
            questionDetails.question_type === QuestionType.MULTIPLE_SELECT
        ) {
            if (questionDetails.multiple_choice_type === MultipleChoiceType.REGULAR_MC) {
                return questionDetails.response_unit_of_measure
                    ? MultipleChoiceResponseOptionsChart
                    : ResponseDistributionChart;
            }

            if (questionDetails.multiple_choice_type === MultipleChoiceType.LIKERT) {
                return MultipleChoiceResponseOptionsChart;
            }

            if (questionDetails.multiple_choice_type === MultipleChoiceType.BINARY) {
                return BinaryResponseChart;
            }
        }

        if (
            questionDetails.question_type === QuestionType.NUMERIC_RANGE ||
            questionDetails.question_type === QuestionType.NUMERIC
        ) {
            return FillInNumberResponseChart;
        }

        return UnknownQuestionComponent;
    };

    const QuestionComponent = questionComponentsMapper(data);
    return <QuestionComponent title={title} description={description} branding={branding} navigate={navigate} data={data} selectedAssessmentDefinition={selectedAssessmentDefinition} />;
};

const ComponentMap: Record<string, React.FC<any>> = {
    total_resilience_score_gas_gauge: TotalResilienceScoreGasGauge,
    most_frequently_offered_solutions: MostFrequentlyOfferedSolutions,
    assessment_scoring_by_category: AssessmentScoringByCategory,
    highest_scoring_statements: HighestScoringStatements,
    lowest_scoring_statements: LowestScoringStatements,
    question_data_component: QuestionDataComponent
};

export default ComponentMap;
