import React from 'react';
import { Box, Grid, MenuItem, Select, SelectChangeEvent, useTheme } from '@mui/material';
import { AccountSettingsUser } from '../../../../Accounts/AccountSettings/AccountSettings';
import { LiteAssessmentOverviewDetails as LiteAssessmentOverviewDetails, AssessmentStatus, AccountInfo } from '../../../type';
import GeneralAssessmentInfoCard from './GeneralAssessmentInfoCard';
import AssessmentResponsesGraphCard from './AssessmentResponsesGraphCard';
import AssessmentDistributionCard from './AssessmentDistributionCard';
import AssessmentReport from './AssessmentReport';
import AssessmentDirectRecipientCard from './AssessmentDirectRecipientCard';
import { SaveButton } from '../../../../Accounts/CreateClient/CreateClient.styles';
import { resetState } from '../../../../Accounts/reducers/getAccountSetting';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

const AssessmentDetailsView = (prop: {
  liteAssessmentDetail: LiteAssessmentOverviewDetails; // Update prop
  accountInformation: AccountInfo;
  accountExecutiveNameTranslation: string;
  accountUserData: AccountSettingsUser[];
  updateStatus: (status: AssessmentStatus) => void;
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { liteAssessmentDetail, accountInformation, accountExecutiveNameTranslation, accountUserData = [] } = prop;

  const [status, setStatus] = React.useState<string | undefined>(
    liteAssessmentDetail.child_assessment?.status,
  );

  const draftStateMenus = [
    { value: AssessmentStatus.DRAFT, label: 'Draft' },
    { value: AssessmentStatus.IN_PROGRESS, label: 'Publish' },
    { value: AssessmentStatus.DELETE, label: 'Delete' },
  ];

  const progressStateMenus = [
    { value: AssessmentStatus.IN_PROGRESS, label: 'In Progress' },
    { value: AssessmentStatus.DRAFT, label: 'Un-publish' },
    { value: AssessmentStatus.RESULTS_REVIEW, label: 'Review' },
  ];

  const reviewStateMenus = [
    { value: AssessmentStatus.RESULTS_REVIEW, label: 'Results Review' },
    { value: AssessmentStatus.IN_PROGRESS, label: 'Re-Open' },
    { value: AssessmentStatus.CLOSED, label: 'Close' },
  ];

  const closedStateMenus = [
    { value: AssessmentStatus.CLOSED, label: 'Closed' },
    { value: AssessmentStatus.IN_PROGRESS, label: 'Re-Open' },
    { value: AssessmentStatus.RESULTS_REVIEW, label: 'Review' },
    { value: AssessmentStatus.DELETE, label: 'Delete' },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        {liteAssessmentDetail && (
          <Grid container spacing={4} sx={{ display: 'flex', justifyContent: "center" }} >

            <Grid item xs={12} md={12}>
              <GeneralAssessmentInfoCard
                accountInfo={accountInformation && accountInformation}
                lightInfo={liteAssessmentDetail} // Update prop
                accountUserData={accountUserData}
                content={'start'}
                accountExecutiveNameTranslation={accountExecutiveNameTranslation}
              />
            </Grid>

            <Grid item xs={12} md={6} 
            sx={{ display: 'flex', justifyContent: 'center', height: '100%', flexDirection: 'column' }}>
              <Grid container spacing={0} sx={{
                backgroundColor: '#FFF',
                border: '1px solid #E7E9F9',
                borderRadius: '16px',
                padding: '1em',
              }}>

                <Grid item sm={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginLeft: '2em',
                    marginBottom: '1em',
                  }}
                >
                  < Box
                    sx={{
                      fontSize: '18px',
                      fontWeight: 600,
                      fontFamily: 'Inter'
                      // marginLeft: '2em',
                      // marginBottom: '0em',
                      // paddingBottom: '0em'
                    }}
                  >
                    {liteAssessmentDetail.assessment_definition.assessment_type.name}
                  </Box>
                  {liteAssessmentDetail.child_assessment && (
                    <SaveButton
                      onClick={() => {
                        dispatch(resetState());
                        navigate(`/edit-evaluation-assessment/${liteAssessmentDetail.uuid}`);
                      }}
                    >
                      Edit
                    </SaveButton>
                  )}
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Box
                    sx={{
                      marginLeft: '10px',
                      marginBottom: '10px',
                      backgroundColor: '#f9fafb',
                      padding: '20px 20px',
                      borderRadius: '16px',
                    }}
                  >
                    <AssessmentResponsesGraphCard lightInfo={liteAssessmentDetail} />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12}>
                  {(liteAssessmentDetail.status !== AssessmentStatus.RESULTS_REVIEW &&
                    liteAssessmentDetail.status !== AssessmentStatus.CLOSED) && (
                      <Box
                        sx={{
                          marginLeft: '10px',
                          backgroundColor: '#f9fafb',
                          padding: '30px 20px',
                          borderRadius: '16px',
                        }}
                      >
                        <AssessmentDistributionCard lightInfo={liteAssessmentDetail} />
                        <AssessmentDirectRecipientCard lightInfo={liteAssessmentDetail} />
                      </Box>
                    )}
                </Grid>

                {liteAssessmentDetail &&
                  (liteAssessmentDetail.status == AssessmentStatus.RESULTS_REVIEW ||
                    liteAssessmentDetail.status == AssessmentStatus.CLOSED) && (
                    <Grid item xs={12} sm={12}>
                      <Box
                        sx={{
                          marginLeft: '10px',
                          backgroundColor: '#f9fafb',
                          padding: '30px 20px',
                          borderRadius: '16px',
                        }}
                      >
                        <AssessmentReport lightInfo={liteAssessmentDetail} />
                      </Box>
                    </Grid>
                  )}

              </Grid>
            </Grid>

            {liteAssessmentDetail.child_assessment && (
              <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', height: '100%', flexDirection: 'column' }}>
                <Grid container spacing={0} sx={{
                  backgroundColor: '#FFF',
                  border: '1px solid #E7E9F9',
                  borderRadius: '16px',
                  padding: '1em',
                }}>
                  <Grid item sm={12}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginLeft: '2em',
                      marginBottom: '1em',
                    }}
                  >
                    < Box
                      sx={{
                        fontSize: '18px',
                        fontWeight: 600,
                        fontFamily: 'Inter',
                      }}
                    >
                      {liteAssessmentDetail.child_assessment.assessment_definition.assessment_type.name}
                    </Box>

                    <SaveButton
                      onClick={() => {
                        dispatch(resetState());
                        navigate(`/edit-evaluation-assessment/${liteAssessmentDetail.child_assessment?.uuid as string}`);
                      }}
                    >
                      Edit
                    </SaveButton>
                    {prop.updateStatus &&
                      status && (
                        <Select
                          displayEmpty
                          value={status}
                          size="small"
                          inputProps={{ 'aria-label': 'Without label' }}
                          onChange={(event: SelectChangeEvent) => {
                            setStatus(event.target.value);
                            prop.updateStatus(event.target.value as AssessmentStatus);
                          }}
                          sx={{
                            backgroundColor: theme.palette.primary.main,
                            color: '#fff',
                            height: '32.5px',
                            borderRadius: '6px',
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                            '& .MuiSelect-select': {
                              fontSize: '14px',
                              padding: '4px 8px',
                            },
                            '& .MuiMenuItem-root': {
                              fontSize: '14px',
                            },
                            '& .MuiSelect-iconOutlined': {
                              color: '#fff',
                            },
                            '&:hover': {
                              '&& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                              },
                            },
                          }}
                        >
                          {liteAssessmentDetail.child_assessment.status === AssessmentStatus.DRAFT &&
                            draftStateMenus.map((item, index) => (
                              <MenuItem
                                key={index}
                                sx={{
                                  display: index === 0 ? 'none' : 'block',
                                  fontSize: '14px'
                                }}
                                value={item.value}
                              >
                                {item.label}
                              </MenuItem>
                            ))}

                          {liteAssessmentDetail.child_assessment.status ===
                            AssessmentStatus.IN_PROGRESS &&
                            progressStateMenus.map((item, index) => (
                              <MenuItem
                                key={index}
                                sx={{
                                  display: index === 0 ? 'none' : 'block',
                                  fontSize: '14px'
                                }}
                                value={item.value}
                              >
                                {item.label}
                              </MenuItem>
                            ))}

                          {liteAssessmentDetail.child_assessment.status ===
                            AssessmentStatus.RESULTS_REVIEW &&
                            reviewStateMenus.map((item, index) => (
                              <MenuItem
                                key={index}
                                sx={{
                                  display: index === 0 ? 'none' : 'block',
                                  fontSize: '14px'
                                }}
                                value={item.value}
                              >
                                {item.label}
                              </MenuItem>
                            ))}

                          {liteAssessmentDetail.child_assessment.status === AssessmentStatus.CLOSED &&
                            closedStateMenus.map((item, index) => (
                              <MenuItem
                                key={index}
                                sx={{
                                  display: index === 0 ? 'none' : 'block',
                                  fontSize: '14px'
                                }}
                                value={item.value}
                              >
                                {item.label}
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Box
                      sx={{
                        marginLeft: '10px',
                        marginBottom: '10px',
                        backgroundColor: '#f9fafb',
                        padding: '20px 20px',
                        borderRadius: '16px',
                      }}
                    >
                      <AssessmentResponsesGraphCard lightInfo={liteAssessmentDetail.child_assessment} />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    {(liteAssessmentDetail.child_assessment.status !== AssessmentStatus.RESULTS_REVIEW &&
                      liteAssessmentDetail.child_assessment.status !== AssessmentStatus.CLOSED) && (
                        <Box
                          sx={{
                            marginLeft: '10px',
                            backgroundColor: '#f9fafb',
                            padding: '30px 20px',
                            borderRadius: '16px',
                          }}
                        >
                          <AssessmentDistributionCard lightInfo={liteAssessmentDetail.child_assessment} />
                          <AssessmentDirectRecipientCard lightInfo={liteAssessmentDetail.child_assessment} />
                        </Box>
                      )}
                  </Grid>

                  {/* {liteAssessmentDetail.child_assessment &&
                    (liteAssessmentDetail.child_assessment.status == AssessmentStatus.RESULTS_REVIEW ||
                      liteAssessmentDetail.child_assessment.status == AssessmentStatus.CLOSED) && (
                      <Grid item xs={12} sm={12}>
                        <Box
                          sx={{
                            marginLeft: '10px',
                            backgroundColor: '#f9fafb',
                            padding: '30px 20px',
                            borderRadius: '16px',
                          }}
                        >
                          <AssessmentReport lightInfo={liteAssessmentDetail.child_assessment} />
                        </Box>
                      </Grid>
                    )} */}

                </Grid>
              </Grid>












            )}
          </Grid>
        )}
      </Grid>
    </Grid >
  );
};

export default AssessmentDetailsView;
