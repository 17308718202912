const industryTypes = [
  { id: 0, definition: 'None - N/A' },
  { id: 131, definition: '311111 - Dog and Cat Food Manufacturing' },
  { id: 132, definition: '311119 - Other Animal Food Manufacturing' },
  { id: 133, definition: '311211 - Flour Milling' },
  { id: 134, definition: '311212 - Rice Milling' },
  { id: 135, definition: '311213 - Malt Manufacturing' },
  { id: 136, definition: '311221 - Wet Corn Milling and Starch Manufacturing' },
  { id: 137, definition: '311224 - Soybean and Other Oilseed Processing' },
  { id: 138, definition: '311225 - Fats and Oils Refining and Blending' },
  { id: 139, definition: '311230 - Breakfast Cereal Manufacturing' },
  { id: 140, definition: '311313 - Beet Sugar Manufacturing' },
  { id: 141, definition: '311314 - Cane Sugar Manufacturing' },
  { id: 142, definition: '311340 - Nonchocolate Confectionery Manufacturing' },
  { id: 143, definition: '311351 - Chocolate and Confectionery Manufacturing from Cacao Beans' },
  { id: 144, definition: '311352 - Confectionery Manufacturing from Purchased Chocolate' },
  { id: 145, definition: '311411 - Frozen Fruit, Juice, and Vegetable Manufacturing' },
  { id: 146, definition: '311412 - Frozen Specialty Food Manufacturing' },
  { id: 147, definition: '311421 - Fruit and Vegetable Canning' },
  { id: 148, definition: '311422 - Specialty Canning' },
  { id: 149, definition: '311423 - Dried and Dehydrated Food Manufacturing' },
  { id: 150, definition: '311511 - Fluid Milk Manufacturing' },
  { id: 151, definition: '311512 - Creamery Butter Manufacturing' },
  { id: 152, definition: '311513 - Cheese Manufacturing' },
  { id: 153, definition: '311514 - Dry, Condensed, and Evaporated Dairy Product Manufacturing' },
  { id: 154, definition: '311520 - Ice Cream and Frozen Dessert Manufacturing' },
  { id: 155, definition: '311611 - Animal (except Poultry) Slaughtering' },
  { id: 156, definition: '311612 - Meat Processed from Carcasses' },
  { id: 157, definition: '311613 - Rendering and Meat Byproduct Processing' },
  { id: 158, definition: '311615 - Poultry Processing' },
  { id: 159, definition: '311710 - Seafood Product Preparation and Packaging' },
  { id: 160, definition: '311811 - Retail Bakeries' },
  { id: 161, definition: '311812 - Commercial Bakeries' },
  { id: 162, definition: '311813 - Frozen Cakes, Pies, and Other Pastries Manufacturing' },
  { id: 163, definition: '311821 - Cookie and Cracker Manufacturing' },
  { id: 164, definition: '311824 - Dry Pasta, Dough, and Flour Mixes Manufacturing from Purchased Flour' },
  { id: 165, definition: '311830 - Tortilla Manufacturing' },
  { id: 166, definition: '311911 - Roasted Nuts and Peanut Butter Manufacturing' },
  { id: 167, definition: '311919 - Other Snack Food Manufacturing' },
  { id: 168, definition: '311920 - Coffee and Tea Manufacturing' },
  { id: 169, definition: '311930 - Flavoring Syrup and Concentrate Manufacturing' },
  { id: 170, definition: '311941 - Mayonnaise, Dressing, and Other Prepared Sauce Manufacturing' },
  { id: 171, definition: '311942 - Spice and Extract Manufacturing' },
  { id: 172, definition: '311991 - Perishable Prepared Food Manufacturing' },
  { id: 173, definition: '311999 - All Other Miscellaneous Food Manufacturing' },
  { id: 174, definition: '312111 - Soft Drink Manufacturing' },
  { id: 175, definition: '312112 - Bottled Water Manufacturing' },
  { id: 176, definition: '312113 - Ice Manufacturing' },
  { id: 177, definition: '312120 - Breweries' },
  { id: 178, definition: '312130 - Wineries' },
  { id: 179, definition: '312140 - Distilleries' },
  { id: 180, definition: '312230 - Tobacco Manufacturing' },
  { id: 181, definition: '313110 - Fiber, Yarn, and Thread Mills' },
  { id: 182, definition: '313210 - Broadwoven Fabric Mills' },
  { id: 183, definition: '313220 - Narrow Fabric Mills and Schiffli Machine Embroidery' },
  { id: 184, definition: '313230 - Nonwoven Fabric Mills' },
  { id: 185, definition: '313240 - Knit Fabric Mills' },
  { id: 186, definition: '313310 - Textile and Fabric Finishing Mills' },
  { id: 187, definition: '313320 - Fabric Coating Mills' },
  { id: 188, definition: '314110 - Carpet and Rug Mills' },
  { id: 189, definition: '314120 - Curtain and Linen Mills' },
  { id: 190, definition: '314910 - Textile Bag and Canvas Mills' },
  { id: 191, definition: '314994 - Rope, Cordage, Twine, Tire Cord, and Tire Fabric Mills' },
  { id: 192, definition: '314999 - All Other Miscellaneous Textile Product Mills' },
  { id: 193, definition: '315120 - Apparel Knitting Mills' },
  { id: 194, definition: '315210 - Cut and Sew Apparel Contractors' },
  { id: 195, definition: '315250 - Cut and Sew Apparel Manufacturing (except Contractors)' },
  { id: 196, definition: '315990 - Apparel Accessories and Other Apparel Manufacturing' },
  { id: 197, definition: '316110 - Leather and Hide Tanning and Finishing' },
  { id: 198, definition: '316210 - Footwear Manufacturing' },
  { id: 199, definition: '316990 - Other Leather and Allied Product Manufacturing' },
  { id: 200, definition: '321113 - Sawmills' },
  { id: 201, definition: '321114 - Wood Preservation' },
  { id: 202, definition: '321211 - Hardwood Veneer and Plywood Manufacturing' },
  { id: 203, definition: '321212 - Softwood Veneer and Plywood Manufacturing' },
  { id: 204, definition: '321215 - Engineered Wood Member Manufacturing' },
  { id: 205, definition: '321219 - Reconstituted Wood Product Manufacturing' },
  { id: 206, definition: '321911 - Wood Window and Door Manufacturing' },
  { id: 207, definition: '321912 - Cut Stock, Resawing Lumber, and Planing' },
  { id: 208, definition: '321918 - Other Millwork (including Flooring)' },
  { id: 209, definition: '321920 - Wood Container and Pallet Manufacturing' },
  { id: 210, definition: '321991 - Manufactured Home (Mobile Home) Manufacturing' },
  { id: 211, definition: '321992 - Prefabricated Wood Building Manufacturing' },
  { id: 212, definition: '321999 - All Other Miscellaneous Wood Product Manufacturing' },
  { id: 213, definition: '322110 - Pulp Mills' },
  { id: 214, definition: '322120 - Paper Mills' },
  { id: 215, definition: '322130 - Paperboard Mills' },
  { id: 216, definition: '322211 - Corrugated and Solid Fiber Box Manufacturing' },
  { id: 217, definition: '322212 - Folding Paperboard Box Manufacturing' },
  { id: 218, definition: '322219 - Other Paperboard Container Manufacturing' },
  { id: 219, definition: '322220 - Paper Bag and Coated and Treated Paper Manufacturing' },
  { id: 220, definition: '322230 - Stationery Product Manufacturing' },
  { id: 221, definition: '322291 - Sanitary Paper Product Manufacturing' },
  { id: 222, definition: '322299 - All Other Converted Paper Product Manufacturing' },
  { id: 223, definition: '323111 - Commercial Printing (except Screen and Books)' },
  { id: 224, definition: '323113 - Commercial Screen Printing' },
  { id: 225, definition: '323117 - Books Printing' },
  { id: 226, definition: '323120 - Support Activities for Printing' },
  { id: 227, definition: '324110 - Petroleum Refineries' },
  { id: 228, definition: '324121 - Asphalt Paving Mixture and Block Manufacturing' },
  { id: 229, definition: '324122 - Asphalt Shingle and Coating Materials Manufacturing' },
  { id: 230, definition: '324191 - Petroleum Lubricating Oil and Grease Manufacturing' },
  { id: 231, definition: '324199 - All Other Petroleum and Coal Products Manufacturing' },
  { id: 232, definition: '325110 - Petrochemical Manufacturing' },
  { id: 233, definition: '325120 - Industrial Gas Manufacturing' },
  { id: 234, definition: '325130 - Synthetic Dye and Pigment Manufacturing' },
  { id: 235, definition: '325180 - Other Basic Inorganic Chemical Manufacturing' },
  { id: 236, definition: '325193 - Ethyl Alcohol Manufacturing' },
  { id: 237, definition: '325194 - Cyclic Crude, Intermediate, and Gum and Wood Chemical Manufacturing' },
  { id: 238, definition: '325199 - All Other Basic Organic Chemical Manufacturing' },
  { id: 239, definition: '325211 - Plastics Material and Resin Manufacturing' },
  { id: 240, definition: '325212 - Synthetic Rubber Manufacturing' },
  { id: 241, definition: '325220 - Artificial and Synthetic Fibers and Filaments Manufacturing' },
  { id: 242, definition: '325311 - Nitrogenous Fertilizer Manufacturing' },
  { id: 243, definition: '325312 - Phosphatic Fertilizer Manufacturing' },
  { id: 244, definition: '325314 - Fertilizer (Mixing Only) Manufacturing' },
  { id: 245, definition: '325315 - Compost Manufacturing' },
  { id: 246, definition: '325320 - Pesticide and Other Agricultural Chemical Manufacturing' },
  { id: 247, definition: '325411 - Medicinal and Botanical Manufacturing' },
  { id: 248, definition: '325412 - Pharmaceutical Preparation Manufacturing' },
  { id: 249, definition: '325413 - In-Vitro Diagnostic Substance Manufacturing' },
  { id: 250, definition: '325414 - Biological Product (except Diagnostic) Manufacturing' },
  { id: 251, definition: '325510 - Paint and Coating Manufacturing' },
  { id: 252, definition: '325520 - Adhesive Manufacturing' },
  { id: 253, definition: '325611 - Soap and Other Detergent Manufacturing' },
  { id: 254, definition: '325612 - Polish and Other Sanitation Good Manufacturing' },
  { id: 255, definition: '325613 - Surface Active Agent Manufacturing' },
  { id: 256, definition: '325620 - Toilet Preparation Manufacturing' },
  { id: 257, definition: '325910 - Printing Ink Manufacturing' },
  { id: 258, definition: '325920 - Explosives Manufacturing' },
  { id: 259, definition: '325991 - Custom Compounding of Purchased Resins' },
  { id: 260, definition: '325992 - Photographic Film, Paper, Plate, Chemical, and Copy Toner Manufacturing' },
  { id: 261, definition: '325998 - All Other Miscellaneous Chemical Product and Preparation Manufacturing' },
  { id: 262, definition: '326111 - Plastics Bag and Pouch Manufacturing' },
  { id: 263, definition: '326112 - Plastics Packaging Film and Sheet (including Laminated) Manufacturing' },
  { id: 264, definition: '326113 - Unlaminated Plastics Film and Sheet (except Packaging) Manufacturing' },
  { id: 265, definition: '326121 - Unlaminated Plastics Profile Shape Manufacturing' },
  { id: 266, definition: '326122 - Plastics Pipe and Pipe Fitting Manufacturing' },
  { id: 267, definition: '326130 - Laminated Plastics Plate, Sheet (except Packaging), and Shape Manufacturing' },
  { id: 268, definition: '326140 - Polystyrene Foam Product Manufacturing' },
  { id: 269, definition: '326150 - Urethane and Other Foam Product (except Polystyrene) Manufacturing' },
  { id: 270, definition: '326160 - Plastics Bottle Manufacturing' },
  { id: 271, definition: '326191 - Plastics Plumbing Fixture Manufacturing' },
  { id: 272, definition: '326199 - All Other Plastics Product Manufacturing' },
  { id: 273, definition: '326211 - Tire Manufacturing (except Retreading)' },
  { id: 274, definition: '326212 - Tire Retreading' },
  { id: 275, definition: '326220 - Rubber and Plastics Hoses and Belting Manufacturing' },
  { id: 276, definition: '326291 - Rubber Product Manufacturing for Mechanical Use' },
  { id: 277, definition: '326299 - All Other Rubber Product Manufacturing' },
  { id: 278, definition: '327110 - Pottery, Ceramics, and Plumbing Fixture Manufacturing' },
  { id: 279, definition: '327120 - Clay Building Material and Refractories Manufacturing' },
  { id: 280, definition: '327211 - Flat Glass Manufacturing' },
  { id: 281, definition: '327212 - Other Pressed and Blown Glass and Glassware Manufacturing' },
  { id: 282, definition: '327213 - Glass Container Manufacturing' },
  { id: 283, definition: '327215 - Glass Product Manufacturing Made of Purchased Glass' },
  { id: 284, definition: '327310 - Cement Manufacturing' },
  { id: 285, definition: '327320 - Ready-Mix Concrete Manufacturing' },
  { id: 286, definition: '327331 - Concrete Block and Brick Manufacturing' },
  { id: 287, definition: '327332 - Concrete Pipe Manufacturing' },
  { id: 288, definition: '327390 - Other Concrete Product Manufacturing' },
  { id: 289, definition: '327410 - Lime Manufacturing' },
  { id: 290, definition: '327420 - Gypsum Product Manufacturing' },
  { id: 291, definition: '327910 - Abrasive Product Manufacturing' },
  { id: 292, definition: '327991 - Cut Stone and Stone Product Manufacturing' },
  { id: 293, definition: '327992 - Ground or Treated Mineral and Earth Manufacturing' },
  { id: 294, definition: '327993 - Mineral Wool Manufacturing' },
  { id: 295, definition: '327999 - All Other Miscellaneous Nonmetallic Mineral Product Manufacturing' },
  { id: 296, definition: '331110 - Iron and Steel Mills and Ferroalloy Manufacturing' },
  { id: 297, definition: '331210 - Iron and Steel Pipe and Tube Manufacturing from Purchased Steel' },
  { id: 298, definition: '331221 - Rolled Steel Shape Manufacturing' },
  { id: 299, definition: '331222 - Steel Wire Drawing' },
  { id: 300, definition: '331313 - Alumina Refining and Primary Aluminum Production' },
  { id: 301, definition: '331314 - Secondary Smelting and Alloying of Aluminum' },
  { id: 302, definition: '331315 - Aluminum Sheet, Plate, and Foil Manufacturing' },
  { id: 303, definition: '331318 - Other Aluminum Rolling, Drawing, and Extruding' },
  { id: 304, definition: '331410 - Nonferrous Metal (except Aluminum) Smelting and Refining' },
  { id: 305, definition: '331420 - Copper Rolling, Drawing, Extruding, and Alloying' },
  { id: 306, definition: '331491 - Nonferrous Metal (except Copper and Aluminum) Rolling, Drawing, and Extruding' },
  { id: 307, definition: '331492 - Secondary Smelting, Refining, and Alloying of Nonferrous Metal (except Copper and Aluminum)' },
  { id: 308, definition: '331511 - Iron Foundries' },
  { id: 309, definition: '331512 - Steel Investment Foundries' },
  { id: 310, definition: '331513 - Steel Foundries (except Investment)' },
  { id: 311, definition: '331523 - Nonferrous Metal Die-Casting Foundries' },
  { id: 312, definition: '331524 - Aluminum Foundries (except Die-Casting)' },
  { id: 313, definition: '331529 - Other Nonferrous Metal Foundries (except Die-Casting)' },
  { id: 314, definition: '332111 - Iron and Steel Forging' },
  { id: 315, definition: '332112 - Nonferrous Forging' },
  { id: 316, definition: '332114 - Custom Roll Forming' },
  { id: 317, definition: '332117 - Powder Metallurgy Part Manufacturing' },
  { id: 318, definition: '332119 - Metal Crown, Closure, and Other Metal Stamping (except Automotive)' },
  { id: 319, definition: '332215 - Metal Kitchen Cookware, Utensil, Cutlery, and Flatware (except Precious) Manufacturing' },
  { id: 320, definition: '332216 - Saw Blade and Handtool Manufacturing' },
  { id: 321, definition: '332311 - Prefabricated Metal Building and Component Manufacturing' },
  { id: 322, definition: '332312 - Fabricated Structural Metal Manufacturing' },
  { id: 323, definition: '332313 - Plate Work Manufacturing' },
  { id: 324, definition: '332321 - Metal Window and Door Manufacturing' },
  { id: 325, definition: '332322 - Sheet Metal Work Manufacturing' },
  { id: 326, definition: '332323 - Ornamental and Architectural Metal Work Manufacturing' },
  { id: 327, definition: '332410 - Power Boiler and Heat Exchanger Manufacturing' },
  { id: 328, definition: '332420 - Metal Tank (Heavy Gauge) Manufacturing' },
  { id: 329, definition: '332431 - Metal Can Manufacturing' },
  { id: 330, definition: '332439 - Other Metal Container Manufacturing' },
  { id: 331, definition: '332510 - Hardware Manufacturing' },
  { id: 332, definition: '332613 - Spring Manufacturing' },
  { id: 333, definition: '332618 - Other Fabricated Wire Product Manufacturing' },
  { id: 334, definition: '332710 - Machine Shops' },
  { id: 335, definition: '332721 - Precision Turned Product Manufacturing' },
  { id: 336, definition: '332722 - Bolt, Nut, Screw, Rivet, and Washer Manufacturing' },
  { id: 337, definition: '332811 - Metal Heat Treating' },
  { id: 338, definition: '332812 - Metal Coating, Engraving (except Jewelry and Silverware), and Allied Services to Manufacturers' },
  { id: 339, definition: '332813 - Electroplating, Plating, Polishing, Anodizing, and Coloring' },
  { id: 340, definition: '332911 - Industrial Valve Manufacturing' },
  { id: 341, definition: '332912 - Fluid Power Valve and Hose Fitting Manufacturing' },
  { id: 342, definition: '332913 - Plumbing Fixture Fitting and Trim Manufacturing' },
  { id: 343, definition: '332919 - Other Metal Valve and Pipe Fitting Manufacturing' },
  { id: 344, definition: '332991 - Ball and Roller Bearing Manufacturing' },
  { id: 345, definition: '332992 - Small Arms Ammunition Manufacturing' },
  { id: 346, definition: '332993 - Ammunition (except Small Arms) Manufacturing' },
  { id: 347, definition: '332994 - Small Arms, Ordnance, and Ordnance Accessories Manufacturing' },
  { id: 348, definition: '332996 - Fabricated Pipe and Pipe Fitting Manufacturing' },
  { id: 349, definition: '332999 - All Other Miscellaneous Fabricated Metal Product Manufacturing' },
  { id: 350, definition: '333111 - Farm Machinery and Equipment Manufacturing' },
  { id: 351, definition: '333112 - Lawn and Garden Tractor and Home Lawn and Garden Equipment Manufacturing' },
  { id: 352, definition: '333120 - Construction Machinery Manufacturing' },
  { id: 353, definition: '333131 - Mining Machinery and Equipment Manufacturing' },
  { id: 354, definition: '333132 - Oil and Gas Field Machinery and Equipment Manufacturing' },
  { id: 355, definition: '333241 - Food Product Machinery Manufacturing' },
  { id: 356, definition: '333242 - Semiconductor Machinery Manufacturing' },
  { id: 357, definition: '333243 - Sawmill, Woodworking, and Paper Machinery Manufacturing' },
  { id: 358, definition: '333248 - All Other Industrial Machinery Manufacturing' },
  { id: 359, definition: '333310 - Commercial and Service Industry Machinery Manufacturing' },
  { id: 360, definition: '333413 - Industrial and Commercial Fan and Blower and Air Purification Equipment Manufacturing' },
  { id: 361, definition: '333414 - Heating Equipment (except Warm Air Furnaces) Manufacturing' },
  { id: 362, definition: '333415 - Air-Conditioning and Warm Air Heating Equipment and Commercial and Industrial Refrigeration Equipment Manufacturing' },
  { id: 363, definition: '333511 - Industrial Mold Manufacturing' },
  { id: 364, definition: '333514 - Special Die and Tool, Die Set, Jig, and Fixture Manufacturing' },
  { id: 365, definition: '333515 - Cutting Tool and Machine Tool Accessory Manufacturing' },
  { id: 366, definition: '333517 - Machine Tool Manufacturing' },
  { id: 367, definition: '333519 - Rolling Mill and Other Metalworking Machinery Manufacturing' },
  { id: 368, definition: '333611 - Turbine and Turbine Generator Set Units Manufacturing' },
  { id: 369, definition: '333612 - Speed Changer, Industrial High-Speed Drive, and Gear Manufacturing' },
  { id: 370, definition: '333613 - Mechanical Power Transmission Equipment Manufacturing' },
  { id: 371, definition: '333618 - Other Engine Equipment Manufacturing' },
  { id: 372, definition: '333912 - Air and Gas Compressor Manufacturing' },
  { id: 373, definition: '333914 - Measuring, Dispensing, and Other Pumping Equipment Manufacturing' },
  { id: 374, definition: '333921 - Elevator and Moving Stairway Manufacturing' },
  { id: 375, definition: '333922 - Conveyor and Conveying Equipment Manufacturing' },
  { id: 376, definition: '333923 - Overhead Traveling Crane, Hoist, and Monorail System Manufacturing' },
  { id: 377, definition: '333924 - Industrial Truck, Tractor, Trailer, and Stacker Machinery Manufacturing' },
  { id: 378, definition: '333991 - Power-Driven Handtool Manufacturing' },
  { id: 379, definition: '333992 - Welding and Soldering Equipment Manufacturing' },
  { id: 380, definition: '333993 - Packaging Machinery Manufacturing' },
  { id: 381, definition: '333994 - Industrial Process Furnace and Oven Manufacturing' },
  { id: 382, definition: '333995 - Fluid Power Cylinder and Actuator Manufacturing' },
  { id: 383, definition: '333996 - Fluid Power Pump and Motor Manufacturing' },
  { id: 384, definition: '333998 - All Other Miscellaneous General Purpose Machinery Manufacturing' },
  { id: 385, definition: '334111 - Electronic Computer Manufacturing' },
  { id: 386, definition: '334112 - Computer Storage Device Manufacturing' },
  { id: 387, definition: '334118 - Computer Terminal and Other Computer Peripheral Equipment Manufacturing' },
  { id: 388, definition: '334210 - Telephone Apparatus Manufacturing' },
  { id: 389, definition: '334220 - Radio and Television Broadcasting and Wireless Communications Equipment Manufacturing' },
  { id: 390, definition: '334290 - Other Communications Equipment Manufacturing' },
  { id: 391, definition: '334310 - Audio and Video Equipment Manufacturing' },
  { id: 392, definition: '334412 - Bare Printed Circuit Board Manufacturing' },
  { id: 393, definition: '334413 - Semiconductor and Related Device Manufacturing' },
  { id: 394, definition: '334416 - Capacitor, Resistor, Coil, Transformer, and Other Inductor Manufacturing' },
  { id: 395, definition: '334417 - Electronic Connector Manufacturing' },
  { id: 396, definition: '334418 - Printed Circuit Assembly (Electronic Assembly) Manufacturing' },
  { id: 397, definition: '334419 - Other Electronic Component Manufacturing' },
  { id: 398, definition: '334510 - Electromedical and Electrotherapeutic Apparatus Manufacturing' },
  { id: 399, definition: '334511 - Search, Detection, Navigation, Guidance, Aeronautical, and Nautical System and Instrument Manufacturing' },
  { id: 400, definition: '334512 - Automatic Environmental Control Manufacturing for Residential, Commercial, and Appliance Use' },
  { id: 401, definition: '334513 - Instruments and Related Products Manufacturing for Measuring, Displaying, and Controlling Industrial Process Variables' },
  { id: 402, definition: '334514 - Totalizing Fluid Meter and Counting Device Manufacturing' },
  { id: 403, definition: '334515 - Instrument Manufacturing for Measuring and Testing Electricity and Electrical Signals' },
  { id: 404, definition: '334516 - Analytical Laboratory Instrument Manufacturing' },
  { id: 405, definition: '334517 - Irradiation Apparatus Manufacturing' },
  { id: 406, definition: '334519 - Other Measuring and Controlling Device Manufacturing' },
  { id: 407, definition: '334610 - Manufacturing and Reproducing Magnetic and Optical Media' },
  { id: 408, definition: '335131 - Residential Electric Lighting Fixture Manufacturing' },
  { id: 409, definition: '335132 - Commercial, Industrial, and Institutional Electric Lighting Fixture Manufacturing' },
  { id: 410, definition: '335139 - Electric Lamp Bulb and Other Lighting Equipment Manufacturing' },
  { id: 411, definition: '335210 - Small Electrical Appliance Manufacturing' },
  { id: 412, definition: '335220 - Major Household Appliance Manufacturing' },
  { id: 413, definition: '335311 - Power, Distribution, and Specialty Transformer Manufacturing' },
  { id: 414, definition: '335312 - Motor and Generator Manufacturing' },
  { id: 415, definition: '335313 - Switchgear and Switchboard Apparatus Manufacturing' },
  { id: 416, definition: '335314 - Relay and Industrial Control Manufacturing' },
  { id: 417, definition: '335910 - Battery Manufacturing' },
  { id: 418, definition: '335921 - Fiber Optic Cable Manufacturing' },
  { id: 419, definition: '335929 - Other Communication and Energy Wire Manufacturing' },
  { id: 420, definition: '335931 - Current-Carrying Wiring Device Manufacturing' },
  { id: 421, definition: '335932 - Noncurrent-Carrying Wiring Device Manufacturing' },
  { id: 422, definition: '335991 - Carbon and Graphite Product Manufacturing' },
  { id: 423, definition: '335999 - All Other Miscellaneous Electrical Equipment and Component Manufacturing' },
  { id: 424, definition: '336110 - Automobile and Light Duty Motor Vehicle Manufacturing' },
  { id: 425, definition: '336120 - Heavy Duty Truck Manufacturing' },
  { id: 426, definition: '336211 - Motor Vehicle Body Manufacturing' },
  { id: 427, definition: '336212 - Truck Trailer Manufacturing' },
  { id: 428, definition: '336213 - Motor Home Manufacturing' },
  { id: 429, definition: '336214 - Travel Trailer and Camper Manufacturing' },
  { id: 430, definition: '336310 - Motor Vehicle Gasoline Engine and Engine Parts Manufacturing' },
  { id: 431, definition: '336320 - Motor Vehicle Electrical and Electronic Equipment Manufacturing' },
  { id: 432, definition: '336330 - Motor Vehicle Steering and Suspension Components (except Spring) Manufacturing' },
  { id: 433, definition: '336340 - Motor Vehicle Brake System Manufacturing' },
  { id: 434, definition: '336350 - Motor Vehicle Transmission and Power Train Parts Manufacturing' },
  { id: 435, definition: '336360 - Motor Vehicle Seating and Interior Trim Manufacturing' },
  { id: 436, definition: '336370 - Motor Vehicle Metal Stamping' },
  { id: 437, definition: '336390 - Other Motor Vehicle Parts Manufacturing' },
  { id: 438, definition: '336411 - Aircraft Manufacturing' },
  { id: 439, definition: '336412 - Aircraft Engine and Engine Parts Manufacturing' },
  { id: 440, definition: '336413 - Other Aircraft Parts and Auxiliary Equipment Manufacturing' },
  { id: 441, definition: '336414 - Guided Missile and Space Vehicle Manufacturing' },
  { id: 442, definition: '336415 - Guided Missile and Space Vehicle Propulsion Unit and Propulsion Unit Parts Manufacturing' },
  { id: 443, definition: '336419 - Other Guided Missile and Space Vehicle Parts and Auxiliary Equipment Manufacturing' },
  { id: 444, definition: '336510 - Railroad Rolling Stock Manufacturing' },
  { id: 445, definition: '336611 - Ship Building and Repairing' },
  { id: 446, definition: '336612 - Boat Building' },
  { id: 447, definition: '336991 - Motorcycle, Bicycle, and Parts Manufacturing' },
  { id: 448, definition: '336992 - Military Armored Vehicle, Tank, and Tank Component Manufacturing' },
  { id: 449, definition: '336999 - All Other Transportation Equipment Manufacturing' },
  { id: 450, definition: '337110 - Wood Kitchen Cabinet and Countertop Manufacturing' },
  { id: 451, definition: '337121 - Upholstered Household Furniture Manufacturing' },
  { id: 452, definition: '337122 - Nonupholstered Wood Household Furniture Manufacturing' },
  { id: 453, definition: '337126 - Household Furniture (except Wood and Upholstered) Manufacturing' },
  { id: 454, definition: '337127 - Institutional Furniture Manufacturing' },
  { id: 455, definition: '337211 - Wood Office Furniture Manufacturing' },
  { id: 456, definition: '337212 - Custom Architectural Woodwork and Millwork Manufacturing' },
  { id: 457, definition: '337214 - Office Furniture (except Wood) Manufacturing' },
  { id: 458, definition: '337215 - Showcase, Partition, Shelving, and Locker Manufacturing' },
  { id: 459, definition: '337910 - Mattress Manufacturing' },
  { id: 460, definition: '337920 - Blind and Shade Manufacturing' },
  { id: 461, definition: '339112 - Surgical and Medical Instrument Manufacturing' },
  { id: 462, definition: '339113 - Surgical Appliance and Supplies Manufacturing' },
  { id: 463, definition: '339114 - Dental Equipment and Supplies Manufacturing' },
  { id: 464, definition: '339115 - Ophthalmic Goods Manufacturing' },
  { id: 465, definition: '339116 - Dental Laboratories' },
  { id: 466, definition: '339910 - Jewelry and Silverware Manufacturing' },
  { id: 467, definition: '339920 - Sporting and Athletic Goods Manufacturing' },
  { id: 468, definition: '339930 - Doll, Toy, and Game Manufacturing' },
  { id: 469, definition: '339940 - Office Supplies (except Paper) Manufacturing' },
  { id: 470, definition: '339950 - Sign Manufacturing' },
  { id: 471, definition: '339991 - Gasket, Packing, and Sealing Device Manufacturing' },
  { id: 472, definition: '339992 - Musical Instrument Manufacturing' },
  { id: 473, definition: '339993 - Fastener, Button, Needle, and Pin Manufacturing' },
  { id: 474, definition: '339994 - Broom, Brush, and Mop Manufacturing' },
  { id: 475, definition: '339995 - Burial Casket Manufacturing' },
  { id: 476, definition: '339999 - All Other Miscellaneous Manufacturing' },

  { id: 494, definition: '423510 - Metal Service Centers and Other Metal Merchant Wholesalers' },

  { id: 615, definition: '4841 - General Freight Trucking' },
  { id: 616, definition: '484110 - General Freight Trucking, Local' },
  { id: 617, definition: '484121 - General Freight Trucking, Long-Distance, Truckload' },
  { id: 618, definition: '484122 - General Freight Trucking, Long-Distance, Less Than Truckload' },
  { id: 619, definition: '484210 - Used Household and Office Goods Moving' },

  { id: 620, definition: '4842 - Specialized Freight Trucking' },
  { id: 621, definition: '484220 - Specialized Freight (except Used Goods) Trucking, Local' },
  { id: 622, definition: '484230 - Specialized Freight (except Used Goods) Trucking, Long-Distance' },
  
  { id: 650, definition: '488510 - Freight Transportation Arrangement' },
  
  { id: 656, definition: '493110 - General Warehousing and Storage' },
  { id: 657, definition: '493120 - Refrigerated Warehousing and Storage' },
  { id: 658, definition: '493130 - Farm Product Warehousing and Storage' },
  { id: 659, definition: '493190 - Other Warehousing and Storage' },
  
  { id: 758, definition: '541330 - Engineering Services' },
  
  { id: 763, definition: '541380 - Testing Laboratories and Services' },
  
  { id: 765, definition: '541420 - Industrial Design Services' },

  { id: 768, definition: '541511 - Custom Computer Programming Services' },
  { id: 769, definition: '541512 - Computer Systems Design Services' },
  
  { id: 779, definition: '541713 - Research and Development in Nanotechnology' },
  { id: 780, definition: '541714 - Research and Development in Biotechnology (except Nanobiotechnology)' },
  { id: 781, definition: '541715 - Research and Development in the Physical, Engineering, and Life Sciences (except Nanotechnology and Biotechnology)' },
  
  
  { id: 830, definition: '561910 - Packaging and Labeling Services' },
  
  { id: 948, definition: '811310 - Commercial and Industrial Machinery and Equipment (except Automotive and Electronic) Repair and Maintenance' },
];


export default industryTypes;
