import React from 'react';

interface UploadIconProps {
    width?: string;
    height?: string;
    color?: string;
}

function UploadIcon({
    width = '13',
    height = '21',
    color = 'none',
}: UploadIconProps) {
    return (
        <svg width={width} height={height} viewBox="0 0 13 21" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M0.863523 20.1587L12.8635 20.1587L12.8635 9.24881L10.3635 9.24881L10.3635 11.0671C10.3635 11.9111 9.99478 12.7204 9.3384 13.3172C8.68202 13.9139 7.79178 14.2492 6.86352 14.2492C5.93527 14.2492 5.04503 13.9139 4.38865 13.3172C3.73227 12.7204 3.36352 11.9111 3.36352 11.0671L3.36352 9.24881L0.863524 9.24881L0.863523 20.1587ZM10.3675 6.17586L8.36353 4.55756L8.36353 11.0671C8.36353 11.4288 8.20549 11.7757 7.92419 12.0314C7.64288 12.2872 7.26135 12.4309 6.86353 12.4309C6.4657 12.4309 6.08417 12.2872 5.80287 12.0314C5.52156 11.7757 5.36353 11.4288 5.36353 11.0671L5.36353 4.55756L3.35952 6.17586C3.05995 6.39925 2.6769 6.50859 2.29064 6.48097C1.90437 6.45335 1.54485 6.29091 1.28737 6.02768C1.02989 5.76444 0.894434 5.42084 0.909377 5.06884C0.924319 4.71684 1.0885 4.38375 1.36753 4.13935L5.86753 0.502721C6.14203 0.281097 6.49641 0.158693 6.86353 0.158693C7.23065 0.158693 7.58502 0.281097 7.85953 0.502721L12.3595 4.13935C12.6386 4.38376 12.8027 4.71684 12.8177 5.06884C12.8326 5.42084 12.6972 5.76444 12.4397 6.02768C12.1822 6.29091 11.8227 6.45335 11.4364 6.48097C11.0501 6.50859 10.6671 6.39925 10.3675 6.17586Z" fill="#00314B" />
        </svg>

    );
}

export default UploadIcon;
