import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #6A6A6A',
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

interface AssessmentConfirmationModalProps {
  open: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  copySurveyLink: string;
}

const AssessmentConfirmationModal: React.FC<AssessmentConfirmationModalProps> = ({
  open,
  handleClose,
  handleConfirm,
  copySurveyLink,
}) => {
  const [copyButtonText, setCopyButtonText] = React.useState('Copy Link');

  const copyLink = () => {
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
        setCopyButtonText('Copied!');
        setTimeout(() => {
          setCopyButtonText('Copy Link');
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
      });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{ textAlign: "start" }}
    >
      <Fade in={open}>
        <Box sx={{
          background: 'white',
          padding: '2rem',
          borderRadius: '8px',
          maxWidth: '800px',
          width: '50%',
          // position: 'relative',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          // width: 400,
          bgcolor: 'background.paper',
          border: '1px solid #6A6A6A',
          boxShadow: 24,
          p: 4,
        }}>
          <Button sx={{
            position: 'absolute',
            right: '0rem',
            top: '0rem',
            border: 'none',
            background: 'none',
            fontSize: '1.5rem',
            cursor: 'pointer',
            color: '#66666',
          }} onClick={handleClose}>&times;</Button>

          <Box>
            <Typography sx={{
              fontSize: '1.5rem',
              marginBottom: '1rem',
              color: '#333',
              fontWeight: 600,
            }}>
              Confirm Your Submission
            </Typography>
            <Typography sx={{
              color: '#66666',
              lineHeight: 1.5,
              marginBottom: '1.5rem',
              fontWeight: 400,
            }}>
              Are you sure you want to submit your answers? Once you finalize your
              submission, you won&apos;t be able to change any responses.
            </Typography>
            <Typography sx={{
              color: '#66666',
              lineHeight: 1.5,
              marginBottom: '1.5rem',
              fontWeight: 400,
            }}>
              It&apos;s best to carefully review your entries one more time. If you&apos;re
              not ready, feel free to save your progress and return later.
            </Typography>
            <Button sx={{
              background: '#053d58',
              color: 'white',
              padding: '0.75rem',
              borderRadius: '6px',
              border: 'none',
              width: '100%',
              fontSize: '1rem',
              cursor: 'pointer',
              marginBottom: '1rem',
              fontWeight: 500,
              transition: 'background-color 0.2s ease',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#034a6d',
              },
            }} onClick={handleConfirm}>
              Yes, Submit Now
            </Button>
            <Button sx={{
              background: 'white',
              color: '#666666',
              padding: '0.75rem',
              borderRadius: '6px',
              border: '1px solid #ddd',
              width: '100%',
              fontSize: '1rem',
              cursor: 'pointer',
              marginBottom: '1rem',
              fontWeight: 500,
              transition: 'background-color 0.2s ease',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#f5f5f5',
              },
            }}
              onClick={handleClose}>
              Review Responses
            </Button>
            <Box sx={{
              background: '#f5f5f5',
              padding: '0.75rem',
              borderRadius: '6px',
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              marginTop: '2rem',
            }}>
              <span style={{
                flex: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                color: '#666666',
                fontSize: '0.9rem',
              }} id="saveLink">
                {copySurveyLink}
              </span>
              <Button sx={{
                background: '#053d58',
                color: 'white',
                padding: '0.5rem 1rem',
                borderRadius: '6px',
                border: 'none',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
                fontWeight: 500,
                transition: 'background-color 0.2s ease',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: '#034a6d',
                },
              }} onClick={copyLink}>
                {copyButtonText}
              </Button>
            </Box>
          </Box>
        </Box >
      </Fade>
    </Modal >
  );

};

export default AssessmentConfirmationModal;
