import React from 'react';
import { Modal, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Button } from '@mui/material';
import { NavigateFunction } from 'react-router-dom';
import { QuestionResponse } from '../../../type';
import { AssessmentDefinitionResponse } from '../../../../Assessments/type';

interface ResultsTableProps {
  open: boolean;
  onClose: () => void;
  navigate: NavigateFunction;
  data: QuestionResponse[];
  selectedAssessmentDefinition: AssessmentDefinitionResponse;
}

const ResultsTable: React.FC<ResultsTableProps> = ({ open, onClose, navigate, data, selectedAssessmentDefinition }) => {

  // Function to handle redirection to account detail page
  const handleDetailsClick = (accountId: string) => {
    onClose();
    navigate(`/account-detail-view/${accountId}`);
  };

  const toTitleCase = (str: string | undefined | null): string => {
    if (!str) return '';

    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  if (!data) {
    return (
      <Modal open={open} onClose={onClose}>
        <Box>No Modal Data</Box>
      </Modal>
    );
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '900px',
        height: '660px',
        backgroundColor: '#fff',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        padding: '16px',
        borderRadius: '8px',
      }}>
        {/* Header section */}
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          marginBottom: '10px',
          // gap: '8px',
        }}>

          <Typography sx={{
            fontFamily: 'Inter',
            color: '#00314B',
            fontSize: '18px',
            fontWeight: '600',
            lineHeight: '22px',
            marginLeft: '8px',
            marginRight: '8px',
          }}>
            Manufacturing Client Insights
          </Typography>

          {/* Question Mark Icon */}
          <Tooltip
            title={`Responses by each company who has completed the ${selectedAssessmentDefinition.name}`}
            arrow
          >
            <span
              style={{
                display: 'inline-block',
              }}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.5" clipPath="url(#clip0_138_27)">
                  <circle cx="9.99935" cy="10.0001" r="8.33333" stroke="#1E293B" strokeWidth="1.5" />
                  <path d="M8.4375 7.39575C8.4375 6.53281 9.13706 5.83325 10 5.83325C10.8629 5.83325 11.5625 6.53281 11.5625 7.39575C11.5625 7.96862 11.2542 8.46949 10.7945 8.74148C10.3984 8.97583 10 9.33135 10 9.79159V10.8333" stroke="#1E293B" strokeWidth="1.5" strokeLinecap="round" />
                  <circle cx="9.99935" cy="13.3333" r="0.833333" fill="#1E293B" />
                </g>
                <defs>
                  <clipPath id="clip0_138_27">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </span>
          </Tooltip>
        </Box>

        {/* Table for displaying data */}
        <TableContainer
          sx={{
            maxHeight: '95.5%',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              width: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#A9A9A9',
              borderRadius: '10px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#F5F5F5',
              borderRadius: '10px',
            }
          }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontFamily: 'Inter !important',
                    fontWeight: '600 !important',
                    fontSize: '11px !important',
                    color: '#2E3645 !important',
                    lineHeight: '12px !important',
                    borderBottom: '1px solid #E9EDF0 !important',
                    textAlign: 'center',
                  }}>
                  No.
                </TableCell>
                <TableCell
                  sx={{
                    fontFamily: 'Inter !important',
                    fontWeight: '600 !important',
                    fontSize: '11px !important',
                    color: '#2E3645 !important',
                    lineHeight: '12px !important',
                    borderBottom: '1px solid #E9EDF0 !important',
                    width: '155px',  // Set column width
                  }}>
                  Company Name
                </TableCell>
                <TableCell
                  sx={{
                    fontFamily: 'Inter !important',
                    fontWeight: '600 !important',
                    fontSize: '11px !important',
                    color: '#2E3645 !important',
                    lineHeight: '12px !important',
                    borderBottom: '1px solid #E9EDF0 !important',
                    width: '155px',  // Set column width
                  }}>
                  Company Size
                </TableCell>
                <TableCell
                  sx={{
                    fontFamily: 'Inter !important',
                    fontWeight: '600 !important',
                    fontSize: '11px !important',
                    color: '#2E3645 !important',
                    lineHeight: '12px !important',
                    borderBottom: '1px solid #E9EDF0 !important',
                  }}>
                  Answer
                </TableCell>
                <TableCell align="center"
                  sx={{
                    fontFamily: 'Inter !important',
                    fontWeight: '600 !important',
                    fontSize: '11px !important',
                    color: '#2E3645 !important',
                    lineHeight: '12px !important',
                    borderBottom: '1px solid #E9EDF0 !important',
                    width: '150px'
                  }}>
                  View Account
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((answer: { account_id: string; company_name: string; company_size?: string; answer: string | number }, index: number) => (
                <TableRow key={answer.account_id}
                  sx={{
                    padding: '16px',
                    color: '#2E3645',
                  }}>
                  <TableCell
                    sx={{
                      fontFamily: 'Inter !important',
                      fontWeight: '400 !important',
                      fontSize: '11px !important',
                      color: '#2E3645 !important',
                      lineHeight: '13px !important',
                      textAlign: 'center',
                      width: '55px'
                      // borderBottom: '1px solid #E9EDF0 !important',
                    }}>
                    {index + 1}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: 'Inter !important',
                      fontWeight: '400 !important',
                      fontSize: '11px !important',
                      color: '#2E3645 !important',
                      lineHeight: '13px !important',
                      // borderBottom: '1px solid #E9EDF0 !important',
                    }}>
                    {answer.company_name}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: 'Inter !important',
                      fontWeight: '400 !important',
                      fontSize: '11px !important',
                      color: '#2E3645 !important',
                      lineHeight: '13px !important',
                      // borderBottom: '1px solid #E9EDF0 !important',
                    }}>
                    {toTitleCase(answer.company_size)}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: 'Inter !important',
                      fontWeight: '600 !important',
                      fontSize: '10px !important',
                      color: '#2E3645 !important',
                      lineHeight: '12px !important',
                      // borderBottom: '1px solid #E9EDF0 !important',
                    }}>
                    {answer.answer.toString()}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        width: '125px !important',
                        height: '24px !important',
                        borderRadius: '5px !important',
                        border: '1px solid #D9E1E7 !important',
                        fontFamilty: 'Inter !important',
                        fontSize: '12px !important',
                        fontWeight: '600 !important',
                        color: '#3D54A3 !important',
                        lineHeight: '14px !important',
                        textTransform: 'none !important',
                      }}
                      onClick={() => handleDetailsClick(answer.account_id)}
                    >
                      Analyze Account
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

export default ResultsTable;
