import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LiteAssessmentOverviewDetails, LiteAssessmentDetailsReducer as LiteAssessmentDetailsReducer } from '../type';
import { RequestStatus } from '../../../types/request';
import { axiosV2Instance } from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';

const initialState: LiteAssessmentDetailsReducer = {
    lite_assessment_details: null,
    status: RequestStatus.Init,
};

export const getLiteAssessmentDetails = createAsyncThunk(
    'assessment/getLiteAssessmentDetails',
    async (assessmentId: string, { rejectWithValue }) => {
        try {
            const response = await axiosV2Instance.get<LiteAssessmentOverviewDetails>(
                `${EndPoints.ASSESSMENT}/${assessmentId}/lite`,
            );
            return response.data as LiteAssessmentOverviewDetails;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || error.message);
        }
    },
);

const getLiteAssessmentDetailsSlice = createSlice({
    name: 'getLiteAssessmentDetails',
    initialState,
    reducers: {
        clearLiteAssessmentDetails: (state) => {
            state.lite_assessment_details = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLiteAssessmentDetails.pending, (state) => {
                state.status = RequestStatus.Loading;
            })
            .addCase(getLiteAssessmentDetails.fulfilled, (state, action) => {
                state.status = RequestStatus.Done;
                state.lite_assessment_details = action.payload;
            })
            .addCase(getLiteAssessmentDetails.rejected, (state) => {
                state.status = RequestStatus.Error;
            });
    },
});

export const { clearLiteAssessmentDetails } = getLiteAssessmentDetailsSlice.actions;

export default getLiteAssessmentDetailsSlice.reducer;
