import React from 'react';

interface SortLowToHighIconProps {
    width?: string;
    height?: string;
    color?: string;
}

function SortLowToHighIcon({
    width = '24px',
    height = '24px',
    color = 'none',
}: SortLowToHighIconProps) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.4674 3C11.0782 3 10.705 3.15804 10.4298 3.43934C10.1546 3.72065 10 4.10218 10 4.5C10 4.89783 10.1546 5.27936 10.4298 5.56066C10.705 5.84197 11.0782 6 11.4674 6L13.75 6C14.1392 6 14.5124 5.84197 14.7876 5.56066C15.0628 5.27936 15.2174 4.89783 15.2174 4.5C15.2174 4.10218 15.0628 3.72065 14.7876 3.43934C14.5124 3.15804 14.1392 3 13.75 3L11.4674 3ZM8.46739 9.5C8.07821 9.5 7.70498 9.65803 7.42979 9.93934C7.1546 10.2206 7 10.6022 7 11C7 11.3978 7.1546 11.7794 7.42979 12.0607C7.70498 12.342 8.07821 12.5 8.46739 12.5L16.2717 12.5C16.6609 12.5 17.0342 12.342 17.3093 12.0607C17.5845 11.7794 17.7391 11.3978 17.7391 11C17.7391 10.6022 17.5845 10.2206 17.3093 9.93934C17.0342 9.65804 16.6609 9.5 16.2717 9.5L8.46739 9.5ZM4 17.5C4 17.1022 4.1546 16.7206 4.42979 16.4393C4.70498 16.158 5.07821 16 5.46739 16L18.5326 16C18.9218 16 19.295 16.158 19.5702 16.4393C19.8454 16.7206 20 17.1022 20 17.5C20 17.8978 19.8454 18.2794 19.5702 18.5607C19.295 18.842 18.9218 19 18.5326 19L5.46739 19C5.07821 19 4.70498 18.842 4.42979 18.5607C4.1546 18.2794 4 17.8978 4 17.5Z" fill="#00314B" />
        </svg>
    );
}

export default SortLowToHighIcon;
