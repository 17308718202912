import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AssessmentDefinitionCategoryScores } from '../type';
import { RequestStatus } from '../../../types/request';
import { axiosV2Instance } from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';

export interface AssessmentDefinitionCategoryScoresReducer {
    category_scores: AssessmentDefinitionCategoryScores | null;
    status: RequestStatus;
}

const initialState: AssessmentDefinitionCategoryScoresReducer = {
    category_scores: null,
    status: RequestStatus.Init,
};

export const getAssessmentDefinitionCategoryScores = createAsyncThunk(
    'assessment-definition/getAssessmentDefinitionCategoryScores',
    async (assessmentDefinitionId: string, { rejectWithValue }) => {
        try {
            const response = await axiosV2Instance.get<AssessmentDefinitionCategoryScores>(
                `${EndPoints.ASSESSMENT_DEFINITIONS}/${assessmentDefinitionId}/${EndPoints.CATEGORY_SCORES}`
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || error.message);
        }
    },
);

const getAssessmentDefinitionCategoryScoresSlice = createSlice({
    name: 'getAssessmentDefinitionCategoryScores',
    initialState,
    reducers: {
        clearAssessmentDefinitionCategoryScores: (state) => {
            state.category_scores = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAssessmentDefinitionCategoryScores.pending, (state) => {
                state.status = RequestStatus.Loading;
            })
            .addCase(getAssessmentDefinitionCategoryScores.fulfilled, (state, action) => {
                state.status = RequestStatus.Done;
                state.category_scores = action.payload;
            })
            .addCase(getAssessmentDefinitionCategoryScores.rejected, (state, action) => {
                state.status = RequestStatus.Error;
            });
    },
});

export const { clearAssessmentDefinitionCategoryScores } = getAssessmentDefinitionCategoryScoresSlice.actions;

export default getAssessmentDefinitionCategoryScoresSlice.reducer;
