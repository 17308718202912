import { useBranding } from '../modules/Branding/reducers/getBranding';
import { Insights, Handshake, LocalShipping } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { RootStoreType } from '../redux/store.type';
import { CustomAccountBranding, FeatureFlags } from '../modules/Branding/type';

export interface Route {
  name: string;
  url: string;
  icon?: string | React.ComponentType;
  submenus?: SubMenu[];
}

interface SubMenu {
  name: string;
  url: string;
}

export const useRoutes = (): Route[] => {
  const branding = useBranding();
  const scoinCustomer = branding?.active_feature_flags?.[FeatureFlags.SCOIN] === true;
  const dashboardAccess = branding?.active_feature_flags?.[FeatureFlags.DASHBOARD] === true;
  const loggedInUserType = useSelector((state: RootStoreType) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return state.authReducers.user.currentUser?.user_type;
  });

  function getEvaluationTranslation(branding: CustomAccountBranding | null, defaultText = 'Evaluations'): string {
    if (!branding) {
      return defaultText;
    }

    return branding.evaluation_property_name_translation as string || defaultText;
  }

  const evaluationNameTranslation = getEvaluationTranslation(branding);

  let Routes: Route[] = [];

  if (loggedInUserType?.includes('admin') ||
    loggedInUserType?.includes('account_executive')) {
    Routes = [
      {
        name: 'Accounts',
        url: 'account-list',
        icon: require('../assets/accounts.png'),
        submenus: [
          { name: 'Accounts List', url: 'account-list' },
          { name: 'Account Overview', url: 'account-detail-view' },
          { name: 'Create account', url: 'create-account' },
        ],
      },
      {
        name: `${evaluationNameTranslation}`,
        url: 'evaluation-assessment-list',
        icon: require('../assets/evaluation.png'),
        submenus: [
          { name: `${evaluationNameTranslation} List`, url: 'evaluation-assessment-list' },
          { name: `${evaluationNameTranslation} Overview`, url: `evaluation-assessment-detail` },
          {
            name: `${evaluationNameTranslation} Report Builder`,
            url: 'evaluation-assessment-analysis-score',
          },
          { name: `Create ${evaluationNameTranslation}`, url: 'create-evaluation-assessment' },
        ],
      },
      // {
      //   name: 'Settings',
      //   url: 'settings',
      //   icon: require('../assets/settings.png'),
      // },
    ];
  }

  if (scoinCustomer && (loggedInUserType?.includes('admin') ||
    loggedInUserType?.includes('account_executive'))) {
    Routes = [
      ...Routes,
      {
        name: 'Ecosystem',
        url: 'scoin',
        icon: LocalShipping,
      }
    ];
  }

  // if (dashboardAccess) 
  if (dashboardAccess) {
    Routes = [
      {
        name: 'Dashboard',
        url: 'dashboard',
        icon: require('../assets/dashboard.png')
      },
      ...Routes
    ];
  }

  return Routes;
};
