import React, { useState } from 'react';
import { Box, CircularProgress, Grid } from '@mui/material';
import { CustomAccountBranding } from '../../../../Branding/type';
import UploadIcon from '../../../../../assets/svg-components/UploadIcon';
import { AssessmentDefinitionCategoryScores } from '../../../type';
import CategoryScoresModal from './CategoryScoresModal';
import { NavigateFunction } from 'react-router-dom';

interface AssessmentScoringByCategoryProps {
    title: string;
    branding: CustomAccountBranding;
    navigate: NavigateFunction;
    data: AssessmentDefinitionCategoryScores | null;
}

const AssessmentScoringByCategory: React.FC<AssessmentScoringByCategoryProps> = ({ title, branding, navigate, data }) => {
    const pluralEvaluationPropertyName = branding.evaluation_property_name_translation || "Assessments";
    const singularEvaluationPropertyName = pluralEvaluationPropertyName.endsWith("s")
        ? pluralEvaluationPropertyName.slice(0, -1) // Remove the last character
        : pluralEvaluationPropertyName;
    const titleCaseSingularEvaluationPropertyName = singularEvaluationPropertyName
        ? singularEvaluationPropertyName[0].toUpperCase() + singularEvaluationPropertyName.slice(1).toLowerCase()
        : '';

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);

    const handleCloseModal = () => setIsModalOpen(false);

    const handleRowClick = (categoryId: number) => {
        setSelectedCategoryId(categoryId);
        setIsModalOpen(true);
    };

    if (!data) {
        return <Box display="flex" justifyContent="center" alignItems="center" minHeight="100%">
            <CircularProgress />
        </Box>
    }

    return (
        <>
            {/* Grid Container */}
            <Grid container
                sx={{
                    minHeight: '100%',
                    width: '100%',
                    overflow: 'auto',
                    padding: "24px",
                }}>
                <Grid item md={12}
                    sx={{
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                            alignItems: 'center',
                        }}>
                        <UploadIcon color={'#00314B'} />
                        <Box
                            sx={{
                                fontFamily: "Inter, sans-serif",
                                color: "#00314B",
                                fontWeight: 600,
                                fontSize: '20px',
                                lineHeight: '28px',
                            }}
                        >
                            {titleCaseSingularEvaluationPropertyName} Scoring by Category
                        </Box >
                    </Box>
                </Grid>

                {/* Row Description */}
                <Grid item md={12}
                    sx={{
                        paddingTop: "10px",
                        // paddingBottom: "10px",
                        // paddingLeft: "24px",
                        fontFamily: "Inter, sans-serif",
                        color: "#2E3645",
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '20px',
                    }}
                >
                    Click on each row to review the average performance in each {singularEvaluationPropertyName.toLowerCase()} category
                </Grid >



                {/* Render Rows */}
                {data.question_categories.map((category) => {
                    // Calculate the width of the green bar dynamically
                    const barWidthPercentage = parseFloat(
                        (
                            ((category.average_score as number - category.min_possible_score) /
                                (category.max_possible_score - category.min_possible_score)) * 100
                        ).toFixed(2)
                    );

                    // Determine bar color based on average score
                    const barColor =
                        category.average_score as number < 2.5
                            ? '#E43E3E' // Red for low scores
                            : category.average_score as number < 4.0
                                ? '#FFD50F' // Yellow for medium scores
                                : '#16B364'; // Green for high scores

                    return (
                        <Grid
                            key={category.id}
                            container
                            alignItems="center"
                            onClick={() => handleRowClick(category.id)}
                            sx={{
                                paddingY: "5px",
                                paddingX: '24px',
                                border: '1px solid #E9EDF0',
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: '#E7E9F9',
                                },
                            }}
                        >
                            {/* Category Name */}
                            <Grid item xs={6}>
                                <Box
                                    sx={{
                                        // paddingLeft: "24px",
                                        fontFamily: "Inter, sans-serif",
                                        color: "#00314B",
                                        fontWeight: 500,
                                        fontSize: '18px',
                                        lineHeight: '25px',
                                    }}>
                                    {category.name}
                                </Box>
                            </Grid>

                            {/* Average Score */}
                            <Grid item xs={1}>
                                <Box
                                    sx={{
                                        fontFamily: "Inter, sans-serif",
                                        color: '#3D56AD',
                                        fontWeight: '600',
                                        fontSize: '20px',
                                        lineHeight: '28px',
                                        display: 'flex',
                                        // alignItems: 'center',
                                        justifyContent: 'end',
                                        marginRight: '30px'
                                    }}>
                                    {Number(category.average_score).toFixed(2)}
                                </Box>
                            </Grid>

                            {/* Dynamic Score Bar */}
                            <Grid item xs={5}>
                                <Box
                                    sx={{
                                        height: '20px',
                                        backgroundColor: '#E9EDF0', // Light gray background bar
                                        // borderRadius: '2px',
                                        overflow: 'hidden',
                                        position: 'relative',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: `${barWidthPercentage}%`,
                                            height: '100%',
                                            backgroundColor: barColor, // Green color
                                            transition: 'width 0.3s ease-in-out',
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>

            {/* Modal */}
            <CategoryScoresModal
                open={isModalOpen}
                onClose={handleCloseModal}
                data={data.question_categories}
                selectedCategoryId={selectedCategoryId}
                titleEvaluationPropertyName={titleCaseSingularEvaluationPropertyName}
                navigate={navigate}
            />
        </>
    );
};

export default AssessmentScoringByCategory;
