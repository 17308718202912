import React, { ReactNode, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { store } from './redux/store';
import { router } from './router';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import ResetPasswordComponent from './modules/Auth/ResetPassword/ResetPassword';
import InquiryForm from './modules/InquiryForm/InquiryForm';
import LegacyEnquiryForm from './components/EnquiryForm/LegacyEnquiryForm';
import Dashboard from './modules/Dashboard/Dashboard';
import ClientList from './modules/Accounts/ClientList/ClientList';
import AssessmentListContianer from './modules/Assessments/AssessmentList/AssessmentList';
import AccountDetailView from './modules/Accounts/AccountDetailView/AccountDetailView';
import AssessmentDetailContainer from './modules/Assessments/AssessmentDetails/AssessmentDetailContainer';
import { RootStoreType } from './redux/store.type';
import AppRouter from './AppRouter';
import CreateClient from './modules/Accounts/CreateClient/CreateClient';
import CreateAssessmentContainer from './modules/Assessments/CreateAssessment/CreateAssessmentContainer';
import Settings from './modules/Settings/Settings';
import { SuccessPage } from './modules/InquiryForm/SuccessPage';
import EvaluationScore from './modules/Evaluations/EvaluationScore/EvaluationScore';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginComponent from './modules/Auth/Login/Login';
import PageNotFound from './modules/PageNotFound';
import EditAssessmentContainer from './modules/Assessments/EditAssessment/EditAssessmentContainer';
import EditAccount from './modules/Accounts/EditAccount/EditAccount';
import EvaluationScoreAnalysisContainer from './modules/Assessments/CategoryAnalysis/AssessmentAnalysisScoreContainer';
import ForgetPasswordComponent from './modules/Auth/ForgetPassword/ForgetPassword';
import { BrandingProvider, useBranding } from './modules/Branding/reducers/getBranding';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import LegacyLoginComponent from './modules/Auth/Login/LegacyLogin';
import Scoin from './modules/Scoin/Scoin';
import IntakeFormMain from './components/IntakeForm/IntakeFormMain';
import { FeatureFlags } from './modules/Branding/type';
import useLogout from './modules/Auth/hooks/useLogout';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const AppInitializer = () => {
  const branding = useBranding();
  const legacyEndpoints = branding?.active_feature_flags?.[FeatureFlags.LEGACY_V1_ENDPOINTS] === true;

  if (legacyEndpoints) {
    document.body.style.backgroundColor = "#FFF";
  }

  useEffect(() => {
    const setFavicon = (faviconPath: string) => {
      let link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = faviconPath;
    };

    const setAppleTouchIcon = (iconPath: string) => {
      let link: HTMLLinkElement | null = document.querySelector("link[rel~='apple-touch-icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'apple-touch-icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = iconPath;
    };

    document.title = branding?.browser_tab_title || 'Smart Assessments';
    setFavicon(branding?.favicon || '../../assets/atomLogo192.png');
    setAppleTouchIcon(branding?.favicon || '../../assets/atomLogo192.png');
  }, [branding]);

  const theme = createTheme({
    palette: {
      primary: {
        main: branding?.primary_color || '#1e73ae',
      },
      secondary: {
        main: branding?.secondary_color || '#00314b',
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <React.StrictMode>
        <ToastContainer hideProgressBar theme="colored" autoClose={2000} />
        <Provider store={store}>
          <RouterProvider router={router}></RouterProvider>
        </Provider>
      </React.StrictMode>
    </ThemeProvider>
  );
};

root.render(
  <BrandingProvider>
    <AppInitializer />
  </BrandingProvider>
);
