import React from 'react';
import {
  styled,
  Slider,
  SliderThumb,
  IconButton,
  Box,
  Typography,
  Grid,
} from '@mui/material';
import { CategoryScoreQuestionDetails } from '../../../type';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function ScaleThumb(props: any) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      {/* <IconButton size="large">
        <ArrowDropDownIcon
          style={{
            width: '40px',
            height: '40px',
            position: 'relative',
            top: '-10px',
            left: '2px',
            color: '#000',
          }}
        />
      </IconButton> */}
    </SliderThumb>
  );
}

const ScaleSlider = styled(Slider)(({ theme }) => ({
  color: '#3a8589',
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: '10px', // Height of the line representing the thumb
    width: '1px', // Width of the line, making it thin
    borderRadius: '0', // Remove rounded corners
    backgroundColor: '#707172', // Color of the line
    marginTop: '0px', // Adjust this value to center the line on the track
    marginLeft: '-1px', // Adjust to align the center of the line with the tick marks if needed
    '&:before': {
      display: 'none', // Hide pseudo-elements that might interfere
    },
    '&:after': {
      display: 'none', // Hide pseudo-elements that might interfere
    },
  },
  '& .MuiSlider-track': {
    height: 3,
  },
  '& .MuiSlider-rail': {
    color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
    background: '#000',
    height: 1,
  },
  '& .MuiSlider-mark': {
    backgroundColor: '#000',
    width: '5px',
    height: '5px',
    borderRadius: '50%',
  },
  '& .MuiSlider-valueLabel': {
    top: 'calc(150%)', // Adjust vertical position as needed
    //top: 'calc(50% - 10px)', // Adjust vertical position as needed
    //left: 'calc(-50% + 4px)', // Adjust this value to align the label above the thumb
    transform: 'translateX(-50%) scale(1)', // Add scale(1) to ensure transform is applied
    padding: '1px 4px', // Smaller padding reduces the size of the box
    fontSize: '0.75rem', // Smaller font size reduces the size of the box
    fontWeight: 'bold', // Optional: makes the number stand out
    backgroundColor: 'grey', // Optional: changes the background color of the box
    color: 'white', // Optional: changes the text color
    '&::before': {
      display: 'none', // Removes the arrow below the label
    },
  },
}));

function convertToNewScale(questionDetails: CategoryScoreQuestionDetails): {
  scaledLowest: number;
  scaledHighest: number;
  scaledAverage: number;
} {
  const {
    min_possible_score: oldMin,
    max_possible_score: oldMax,
    average_score: oldAverage,
  } = questionDetails;

  const newMin = 1;
  const newMax = 5;

  // Helper function to convert a score
  const scale = (oldValue: number) => {
    return (
      newMin + ((oldValue - oldMin) / (oldMax - oldMin)) * (newMax - newMin)
    );
  };

  // Scale the lowest, highest, and average scores
  const scaledLowest = scale(oldMin) as number;
  const scaledHighest = scale(oldMax) as number;
  const scaledAverage = Math.round(scale(oldAverage) * 10) / 10; // Round to 1 decimal place

  return {
    scaledLowest,
    scaledHighest,
    scaledAverage,
  };
}

function dontScale (questionDetails: CategoryScoreQuestionDetails): {
  scaledLowest: number;
  scaledHighest: number;
  scaledAverage: number;
} {
  return {
    scaledLowest: questionDetails.min_possible_score,
    scaledHighest: questionDetails.max_possible_score,
    scaledAverage: questionDetails.average_score
  }
}


interface MultipleChoiceScoreLineChartProps {
  questionDetails: CategoryScoreQuestionDetails;
}

const LikertScaleResponseLineChart = ({
  questionDetails,
}: MultipleChoiceScoreLineChartProps) => {
  //const scaledScores = convertToNewScale(questionDetails);
  const scaledScores = dontScale(questionDetails);

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFF',
        border: '1px solid #E7E9F9',
        padding: '4px 0px',
        borderRadius: '4px',
      }}
    >
      <Grid
        item
        sm={11}
        md={4}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: '#9F7A38',
            fontFamily: 'Inter',
            fontSize: '11px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
            letterSpacing: '-0.11px',
          }}
        >
          Self-Score average:
        </Typography>
      </Grid>

      <Grid
        item
        md={1}
        sx={{
          display: {
            xs: 'none',
            sm: 'none',
            md: 'block',
            lg: 'none',
            xl: 'block',
          },
        }}
      />

      <Grid
        item
        sm={10}
        md={6}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '100%',
        }}
      >
        {questionDetails.total_response_count === 0 ? (
          <Typography variant="body1" color="textSecondary">
            N/A
          </Typography>
        ) : (
          <ScaleSlider
            value={scaledScores.scaledAverage}
            marks={[
              { value: scaledScores.scaledLowest, label: '' },
              { value: scaledScores.scaledHighest, label: '' },
            ]}
            step={scaledScores.scaledHighest - scaledScores.scaledLowest}
            min={scaledScores.scaledLowest}
            max={scaledScores.scaledHighest}
            slots={{ thumb: ScaleThumb }}
            disabled
            valueLabelDisplay="on"
            valueLabelFormat={(value) => value != null ? Number(value).toFixed(1) : '-'}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default LikertScaleResponseLineChart;
