import React, { useState } from 'react';
import { Modal, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Button, TextField, IconButton, MenuItem, Menu } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AssessmentQuestion, QuestionCategory } from '../../../type';
import { FilterList } from '@mui/icons-material';
import FilterIcon from '../../../../../assets/svg-components/FilterIcon';
import SearchIcon from '@mui/icons-material/Search';

interface StatementScoresModalProps {
    open: boolean;
    onClose: () => void;
    data: AssessmentQuestion[];
    sortHighToLow: boolean;
}

const StatementScoresModal: React.FC<StatementScoresModalProps> = ({ open, onClose, data, sortHighToLow }) => {
    const [isHighToLow, setIsHighToLow] = useState(sortHighToLow);
    const [searchText, setSearchText] = useState('');
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const uniqueCategories = Array.from(
        new Set(data.map((question: AssessmentQuestion) => question.question_category.name))
    ).sort((a, b) => {
        const categoryA = data.find((q) => q.question_category.name === a)?.question_category.display_order || 0;
        const categoryB = data.find((q) => q.question_category.name === b)?.question_category.display_order || 0;
        return categoryA - categoryB;
    });

    // Function to toggle sorting order
    const toggleSortOrder = () => setIsHighToLow((prev) => !prev);

    // Sorted data based on the current sorting order
    const sortedData = [...data].sort((a, b) => {
        const scoreA = typeof a.average_score === 'number' ? a.average_score : -Infinity;
        const scoreB = typeof b.average_score === 'number' ? b.average_score : -Infinity;
        return isHighToLow ? scoreB - scoreA : scoreA - scoreB;
    });

    const filteredData = sortedData.filter(
        (question) =>
            (!selectedCategory || question.question_category.name === selectedCategory) &&
            question.text.toLowerCase().includes(searchText.toLowerCase())
    );

    // Handle dropdown menu open and close
    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleCategorySelect = (category: string | null) => {
        setSelectedCategory(category);
        handleMenuClose();
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '900px',
                height: '660px',
                backgroundColor: '#fff',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                padding: '16px',
                borderRadius: '8px',
            }}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    marginBottom: '10px',
                    // gap: '8px',
                }}>
                    <Typography sx={{
                        fontFamily: 'Inter',
                        color: '#00314B',
                        fontSize: '18px',
                        fontWeight: '600',
                        lineHeight: '22px',
                        marginLeft: '8px',
                        marginRight: '8px',
                    }}>
                        Statement Scores
                    </Typography>
                    <Box sx={{ marginLeft: 'auto' }}>
                        <TextField
                            placeholder="Search for statement text"
                            variant="outlined"
                            fullWidth
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '0px' }}>
                                        <SearchIcon sx={{ color: '#828282', fontSize: '15px', marginRight: '-8px' }} />
                                    </Box>
                                ),
                            }}
                            sx={{
                                width: '255.15px',
                                height: '31.89px',
                                backgroundColor: '#FFFFFF',
                                '& .MuiInputLabel-root': {
                                    left: '0%', // Shift label to center
                                    top: '-20%', // Shift label vertically to center
                                },
                                '& .MuiInputLabel-shrink': {
                                    transform: 'translate(0, -50%) scale(0.75)', // Keep the standard shrinking behavior
                                    left: '0',
                                    top: '0',
                                },
                                '& .MuiOutlinedInput-root': {
                                    height: '32px', // Match the set height
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: '1px solid #D9D9D9',  // Custom border color
                                },
                                '& .MuiInputBase-input': {
                                    padding: '9.57px 12.76px',  // Custom padding for the input text
                                    fontFamily: 'Inter',
                                    color: '#828282',
                                    fontSize: '11px',
                                    fontWeight: '400',
                                },
                            }}
                            InputLabelProps={{
                                shrink: false, // Prevents the label from shrinking on focus or with input
                            }}
                        />
                    </Box>
                </Box>

                {/* Table for displaying data */}
                <TableContainer
                    sx={{
                        maxHeight: '95.5%',
                        overflow: 'auto',
                        '&::-webkit-scrollbar': {
                            width: '10px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#A9A9A9',
                            borderRadius: '10px',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: '#F5F5F5',
                            borderRadius: '10px',
                        }
                    }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        fontFamily: 'Inter !important',
                                        fontWeight: '600 !important',
                                        fontSize: '11px !important',
                                        color: '#2E3645 !important',
                                        lineHeight: '12px !important',
                                        borderBottom: '1px solid #E9EDF0 !important',
                                        textAlign: 'center',
                                    }}>
                                    Rank
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontFamily: 'Inter !important',
                                        fontWeight: '600 !important',
                                        fontSize: '11px !important',
                                        color: '#2E3645 !important',
                                        lineHeight: '12px !important',
                                        borderBottom: '1px solid #E9EDF0 !important',
                                        width: '155px',  // Set column width
                                    }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
                                        Category Name
                                        <IconButton onClick={handleMenuOpen} size="small">
                                            <FilterIcon />
                                        </IconButton>
                                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                                            <MenuItem onClick={() => handleCategorySelect(null)}
                                                sx={{
                                                    fontFamily: 'Inter !important',
                                                    fontWeight: '500 !important',
                                                    fontSize: '11px !important',
                                                    color: '#2E3645 !important',
                                                    lineHeight: '12px !important',
                                                    borderBottom: '1px solid #E9EDF0 !important',
                                                }}
                                            >
                                                -----------------
                                            </MenuItem>
                                            {uniqueCategories.map((category) => (
                                                <MenuItem key={category} onClick={() => handleCategorySelect(category)}
                                                    sx={{
                                                        fontFamily: 'Inter !important',
                                                        fontWeight: '500 !important',
                                                        fontSize: '11px !important',
                                                        color: '#2E3645 !important',
                                                        lineHeight: '12px !important',
                                                        borderBottom: '1px solid #E9EDF0 !important',
                                                    }}
                                                >
                                                    {category}
                                                </MenuItem>
                                            ))}
                                        </Menu>
                                    </Box>
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontFamily: 'Inter !important',
                                        fontWeight: '600 !important',
                                        fontSize: '11px !important',
                                        color: '#2E3645 !important',
                                        lineHeight: '12px !important',
                                        borderBottom: '1px solid #E9EDF0 !important',
                                    }}>
                                    Statement
                                </TableCell>
                                <TableCell
                                    onClick={toggleSortOrder}  // Toggle sorting when header is clicked
                                    sx={{
                                        fontFamily: 'Inter !important',
                                        fontWeight: '600 !important',
                                        fontSize: '11px !important',
                                        color: '#2E3645 !important',
                                        lineHeight: '13px !important',
                                        borderBottom: '1px solid #E9EDF0 !important',
                                        width: '135px',  // Set column width
                                        textAlign: 'center',  // Center the header text
                                        cursor: 'pointer',
                                    }}>
                                    {isHighToLow ? '↓' : '↑'} Statement Score
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredData.map((
                                question: {
                                    id: number;
                                    question_category: QuestionCategory;
                                    text: string;
                                    display_order: number;
                                    average_score: number | string | null;
                                },
                                index: number) => {
                                const rowNumber = isHighToLow ? index + 1 : filteredData.length - index;
                                return (
                                    <TableRow key={question.id}
                                        sx={{
                                            padding: '16px',
                                            color: '#2E3645',
                                        }}>
                                        <TableCell
                                            sx={{
                                                fontFamily: 'Inter !important',
                                                fontWeight: '400 !important',
                                                fontSize: '11px !important',
                                                color: '#2E3645 !important',
                                                lineHeight: '13px !important',
                                                textAlign: 'center',
                                                width: '55px'
                                                // borderBottom: '1px solid #E9EDF0 !important',
                                            }}>
                                            {rowNumber}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                fontFamily: 'Inter !important',
                                                fontWeight: '400 !important',
                                                fontSize: '11px !important',
                                                color: '#2E3645 !important',
                                                lineHeight: '13px !important',
                                                // borderBottom: '1px solid #E9EDF0 !important',
                                            }}>
                                            {question.question_category.name}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                fontFamily: 'Inter !important',
                                                fontWeight: '600 !important',
                                                fontSize: '10px !important',
                                                color: '#2E3645 !important',
                                                lineHeight: '12px !important',
                                                // borderBottom: '1px solid #E9EDF0 !important',
                                            }}>
                                            {`${question.display_order}. ${question.text}`}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                fontFamily: 'Inter !important',
                                                fontWeight: '400 !important',
                                                fontSize: '11px !important',
                                                color: '#2E3645 !important',
                                                lineHeight: '13px !important',
                                                // borderBottom: '1px solid #E9EDF0 !important',
                                                width: '135px',  // Set column width
                                                textAlign: 'center',  // Center the content
                                            }}>
                                            {question.average_score?.toString() || "N/A"}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {filteredData.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={4} sx={{ fontFamily: 'inter', textAlign: 'center', fontStyle: 'italic', color: '#2E3645' }}>
                                        No matching results
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Modal >
    );
};

export default StatementScoresModal;
