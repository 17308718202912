import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Button, TextField, IconButton, MenuItem, Menu, Select } from '@mui/material';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Assessment, AssessmentQuestion, QuestionCategory, QuestionCategoryScore, AssessmentQuestionCategoryScore } from '../../../type';
import { FilterList } from '@mui/icons-material';
import FilterIcon from '../../../../../assets/svg-components/FilterIcon';
import SearchIcon from '@mui/icons-material/Search';
import { AssessmentStatus } from '../../../../Assessments/type';

interface CategoryScoresModalProps {
    open: boolean;
    onClose: () => void;
    data: QuestionCategoryScore[];
    selectedCategoryId: number | null;
    titleEvaluationPropertyName: string;
    navigate: NavigateFunction;
}

const CategoryScoresModal: React.FC<CategoryScoresModalProps> = ({ open, onClose, data, selectedCategoryId: initialSelectedCategoryId, titleEvaluationPropertyName, navigate }) => {
    const [isHighToLow, setIsHighToLow] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(initialSelectedCategoryId);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    useEffect(() => {
        setSelectedCategoryId(initialSelectedCategoryId);
    }, [initialSelectedCategoryId]);

    // Get unique categories sorted by display_order
    const uniqueCategories = Array.from(new Set(data)).sort((a, b) => a.display_order - b.display_order);

    // Function to toggle sorting order
    const toggleSortOrder = () => setIsHighToLow((prev) => !prev);

    // **Step 1:** Find the selected category object
    const selectedCategory = data.find((category) => category.id === selectedCategoryId);

    // **Step 2:** If a category is selected, filter its assessment_question_category_scores based on searchText
    const filteredAssessments = selectedCategory
        ? selectedCategory.assessment_question_category_scores.filter(
            (assessmentScore) => assessmentScore.assessment.name.toLowerCase().includes(searchText.toLowerCase()) as boolean
        )
        : [];

    // **Step 3:** Sort the filtered assessments based on `average_question_category_score`
    const sortedAssessments = [...filteredAssessments].sort((a, b) => {
        const scoreA = typeof a.average_question_category_score === 'number' ? a.average_question_category_score : -Infinity;
        const scoreB = typeof b.average_question_category_score === 'number' ? b.average_question_category_score : -Infinity;
        return isHighToLow ? scoreB - scoreA : scoreA - scoreB;
    });

    // Handle dropdown menu open and close
    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleCategorySelect = (categoryId: number | null) => {
        setSelectedCategoryId(categoryId);
        handleMenuClose();
    };

    const handleDetailsClick = (assessmentId: string) => {
        onClose();
        navigate(`/evaluation-assessment-detail/${assessmentId}`);
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '900px',
                height: '660px',
                backgroundColor: '#fff',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                padding: '16px',
                borderRadius: '8px',
            }}>
                {/* Header section with category dropdown */}
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    marginBottom: '10px',
                    // gap: '8px',
                }}>
                    <Typography sx={{
                        fontFamily: 'Inter',
                        color: '#00314B',
                        fontSize: '18px',
                        fontWeight: '600',
                        lineHeight: '22px',
                        marginLeft: '8px',
                        marginRight: '8px',
                    }}>
                        {titleEvaluationPropertyName} Scoring by Catetgory
                    </Typography>

                    {/* Search input */}
                    <Box sx={{ marginLeft: 'auto' }}>
                        <TextField
                            placeholder={`Search by ${titleEvaluationPropertyName.toLowerCase()} name`}
                            variant="outlined"
                            fullWidth
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '0px' }}>
                                        <SearchIcon sx={{ color: '#828282', fontSize: '15px', marginRight: '-8px' }} />
                                    </Box>
                                ),
                            }}
                            sx={{
                                width: '255.15px',
                                height: '31.89px',
                                backgroundColor: '#FFFFFF',
                                '& .MuiInputLabel-root': {
                                    left: '0%', // Shift label to center
                                    top: '-20%', // Shift label vertically to center
                                },
                                '& .MuiInputLabel-shrink': {
                                    transform: 'translate(0, -50%) scale(0.75)', // Keep the standard shrinking behavior
                                    left: '0',
                                    top: '0',
                                },
                                '& .MuiOutlinedInput-root': {
                                    height: '32px', // Match the set height
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: '1px solid #D9D9D9',  // Custom border color
                                },
                                '& .MuiInputBase-input': {
                                    padding: '9.57px 12.76px',  // Custom padding for the input text
                                    fontFamily: 'Inter',
                                    color: '#828282',
                                    fontSize: '11px',
                                    fontWeight: '400',
                                },
                            }}
                            InputLabelProps={{
                                shrink: false, // Prevents the label from shrinking on focus or with input
                            }}
                        />
                    </Box>
                </Box>

                {/* Category Dropdown */}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Select
                        value={selectedCategoryId ?? ''}
                        onChange={(e) => handleCategorySelect(e.target.value as number)}
                        displayEmpty
                        sx={{
                            width: 'auto',
                            height: '40px',
                            fontFamily: 'Inter',
                            fontSize: '20px',
                            lineHeight: '30px',
                            fontWeight: 500,
                            color: '#2E3645',
                            backgroundColor: '#fff',
                            borderRadius: '4px',
                        }}
                    >
                        <MenuItem value="" disabled>
                            Select Category
                        </MenuItem>
                        {uniqueCategories.map((category) => (
                            <MenuItem key={category.id} value={category.id}>
                                {category.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>

                {/* Table for displaying data */}
                <TableContainer
                    sx={{
                        maxHeight: '95.5%',
                        overflow: 'auto',
                        '&::-webkit-scrollbar': {
                            width: '10px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#A9A9A9',
                            borderRadius: '10px',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: '#F5F5F5',
                            borderRadius: '10px',
                        }
                    }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    sx={{
                                        fontFamily: 'Inter !important',
                                        fontWeight: '600 !important',
                                        fontSize: '11px !important',
                                        color: '#2E3645 !important',
                                        lineHeight: '12px !important',
                                        borderBottom: '1px solid #E9EDF0 !important',
                                        textAlign: 'center',
                                    }}>
                                    Rank
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontFamily: 'Inter !important',
                                        fontWeight: '600 !important',
                                        fontSize: '11px !important',
                                        color: '#2E3645 !important',
                                        lineHeight: '12px !important',
                                        borderBottom: '1px solid #E9EDF0 !important',
                                    }}>
                                    {titleEvaluationPropertyName} Name
                                </TableCell>
                                <TableCell
                                    sx={{
                                        fontFamily: 'Inter !important',
                                        fontWeight: '600 !important',
                                        fontSize: '11px !important',
                                        color: '#2E3645 !important',
                                        lineHeight: '12px !important',
                                        borderBottom: '1px solid #E9EDF0 !important',
                                        textAlign: 'center',
                                    }}>
                                    {titleEvaluationPropertyName} Status
                                </TableCell>
                                <TableCell
                                    onClick={toggleSortOrder}  // Toggle sorting when header is clicked
                                    sx={{
                                        fontFamily: 'Inter !important',
                                        fontWeight: '600 !important',
                                        fontSize: '11px !important',
                                        color: '#2E3645 !important',
                                        lineHeight: '13px !important',
                                        borderBottom: '1px solid #E9EDF0 !important',
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                    }}>
                                    {isHighToLow ? '↓' : '↑'} Category Score
                                </TableCell>
                                <TableCell align="center"
                                    sx={{
                                        fontFamily: 'Inter !important',
                                        fontWeight: '600 !important',
                                        fontSize: '11px !important',
                                        color: '#2E3645 !important',
                                        lineHeight: '12px !important',
                                        borderBottom: '1px solid #E9EDF0 !important',
                                        width: '150px'
                                    }}>
                                    View {titleEvaluationPropertyName}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedAssessments.map((
                                assessmentQuestionCategoryScore: {
                                    assessment: Assessment;
                                    average_question_category_score: number | string;
                                },
                                index: number) => {
                                const rowNumber = isHighToLow ? index + 1 : sortedAssessments.length - index;
                                return (
                                    <TableRow key={assessmentQuestionCategoryScore.assessment.id}
                                        sx={{
                                            padding: '16px',
                                            color: '#2E3645',
                                        }}>
                                        <TableCell
                                            sx={{
                                                fontFamily: 'Inter !important',
                                                fontWeight: '400 !important',
                                                fontSize: '11px !important',
                                                color: '#2E3645 !important',
                                                lineHeight: '13px !important',
                                                textAlign: 'center',
                                                width: '55px',
                                            }}>
                                            {rowNumber}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                fontFamily: 'Inter !important',
                                                fontWeight: '400 !important',
                                                fontSize: '11px !important',
                                                color: '#2E3645 !important',
                                                lineHeight: '13px !important',
                                            }}>
                                            {assessmentQuestionCategoryScore.assessment.name}
                                        </TableCell>
                                        <TableCell sx={{
                                            width: '135px',
                                            textAlign: 'center',
                                        }}>
                                            {assessmentQuestionCategoryScore.assessment.status === AssessmentStatus.RESULTS_REVIEW ? (
                                                <Typography
                                                    sx={{
                                                        display: 'inline-block',
                                                        fontSize: '12px',
                                                        lineHeight: '15px',
                                                        color: '#67580E',
                                                        backgroundColor: '#FFF9DB',
                                                        padding: '4px 9px',
                                                        borderRadius: '4px',
                                                        fontWeight: 600,
                                                        border: '1px solid #E7E9F9'
                                                    }}
                                                >
                                                    In Review
                                                </Typography>
                                            ) : assessmentQuestionCategoryScore.assessment.status === AssessmentStatus.CLOSED ? (
                                                <Typography
                                                    sx={{
                                                        display: 'inline-block',
                                                        fontSize: '12px',
                                                        lineHeight: '15px',
                                                        color: '#165A0E',
                                                        backgroundColor: '#E6FFE3',
                                                        padding: '4px 9px',
                                                        borderRadius: '4px',
                                                        fontWeight: 600,
                                                        border: '1px solid #E7E9F9'
                                                    }}
                                                >
                                                    Closed
                                                </Typography>
                                            ) : null}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                fontFamily: 'Inter !important',
                                                fontWeight: '400 !important',
                                                fontSize: '11px !important',
                                                color: '#2E3645 !important',
                                                lineHeight: '13px !important',
                                                width: '135px',
                                                textAlign: 'center',
                                            }}>
                                            {assessmentQuestionCategoryScore.average_question_category_score?.toString() || "N/A"}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                fontFamily: 'Inter !important',
                                                fontWeight: '400 !important',
                                                fontSize: '11px !important',
                                                color: '#2E3645 !important',
                                                lineHeight: '13px !important',
                                                width: '150px',
                                                textAlign: 'center',
                                            }}>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => handleDetailsClick(assessmentQuestionCategoryScore.assessment.uuid as string)}
                                                sx={{
                                                    width: '150px !important',
                                                    height: '24px !important',
                                                    borderRadius: '5px !important',
                                                    border: '1px solid #D9E1E7 !important',
                                                    fontFamilty: 'Inter !important',
                                                    fontSize: '12px !important',
                                                    fontWeight: '600 !important',
                                                    color: '#3D54A3 !important',
                                                    lineHeight: '14px !important',
                                                    textTransform: 'none !important',
                                                }}
                                            >
                                                Analyze {titleEvaluationPropertyName.toLowerCase()}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {sortedAssessments.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={4} sx={{ fontFamily: 'inter', textAlign: 'center', fontStyle: 'italic', color: '#2E3645' }}>
                                        No matching results
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Modal >
    );
};

export default CategoryScoresModal;
