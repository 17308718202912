import React from 'react';

interface CopyIconProps {
    width?: string;
    height?: string;
    color?: string;
}

function CopyIcon({
    width = '15',
    height = '17',
    color = 'none',
}: CopyIconProps) {
    return (
        <svg width={width} height={height} viewBox="0 0 15 17" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4.37441 1.12451C4.82762 0.671299 5.44231 0.416687 6.08325 0.416687H9.40179C10.0332 0.416766 10.6397 0.663982 11.0912 1.10544L13.4392 3.40173C13.6694 3.62677 13.8523 3.89555 13.9773 4.19228C14.1022 4.48901 14.1665 4.8077 14.1666 5.12965V11.3334C14.1666 11.9743 13.912 12.589 13.4588 13.0422C13.0055 13.4954 12.3909 13.75 11.7499 13.75H11.3333V14.1667C11.3333 14.8076 11.0786 15.4223 10.6254 15.8755C10.1722 16.3287 9.55753 16.5834 8.91658 16.5834H3.24992C2.60898 16.5834 1.99429 16.3287 1.54108 15.8755C1.08786 15.4223 0.833252 14.8076 0.833252 14.1667V6.37502C0.833252 5.73408 1.08786 5.11939 1.54108 4.66618C1.99429 4.21297 2.60898 3.95835 3.24992 3.95835H3.66659V2.83335C3.66659 2.19241 3.9212 1.57772 4.37441 1.12451ZM3.66659 5.95835H3.24992C3.13941 5.95835 3.03343 6.00225 2.95529 6.08039C2.87715 6.15853 2.83325 6.26451 2.83325 6.37502V14.1667C2.83325 14.2772 2.87715 14.3832 2.95529 14.4613C3.03343 14.5395 3.13941 14.5834 3.24992 14.5834H8.91658C9.02709 14.5834 9.13307 14.5395 9.21121 14.4613C9.28935 14.3832 9.33325 14.2772 9.33325 14.1667V13.75H6.08325C5.44231 13.75 4.82762 13.4954 4.37441 13.0422C3.9212 12.589 3.66659 11.9743 3.66659 11.3334V5.95835ZM6.08325 11.75C5.97275 11.75 5.86677 11.7061 5.78862 11.628C5.71048 11.5498 5.66659 11.4439 5.66659 11.3334V2.83335C5.66659 2.72285 5.71048 2.61687 5.78862 2.53873C5.86677 2.46059 5.97275 2.41669 6.08325 2.41669H9.40167C9.40163 2.41669 9.40171 2.41669 9.40167 2.41669C9.51046 2.41673 9.61505 2.45933 9.69285 2.53537C9.69283 2.53535 9.69287 2.53539 9.69285 2.53537L12.041 4.83179C12.0807 4.87059 12.1124 4.91712 12.1339 4.96828C12.1555 5.0194 12.1666 5.0743 12.1666 5.12977C12.1666 5.12973 12.1666 5.12981 12.1666 5.12977V11.3334C12.1666 11.4439 12.1227 11.5498 12.0445 11.628C11.9664 11.7061 11.8604 11.75 11.7499 11.75H6.08325Z" fill="#5E6C84" />
        </svg>
    );
}

export default CopyIcon;
