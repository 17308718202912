import { combineReducers } from '@reduxjs/toolkit';
import getDashboardConfigurationReducer, { DashboardConfigurationReducer } from '../../Dashboard/reducers/getDashboardConfiguration';
import getAssessmentDefinitionResilienceScoreReducer, { AssessmentDefinitionResilienceScoreReducer } from '../../Dashboard/reducers/getAssessmentDefinitionResilienceScore';
import getAssessmentDefinitionOfferedSolutionsReducer, { AssessmentDefinitionOfferedSolutionsReducer } from './getAssessmentDefinitionOfferedSolutions';
import getAssessmentDefinitionCategoryScoresReducer, { AssessmentDefinitionCategoryScoresReducer } from './getAssessmentDefinitionCategoryScores';
import getAssessmentDefinitionQuestionScoresReducer, { AssessmentDefinitionQuestionScoresReducer } from './getAssessmentDefinitionQuestionScores';

export default combineReducers<{
    getDashboardConfigurationReducer: DashboardConfigurationReducer;
    getAssessmentDefinitionResilienceScoreReducer: AssessmentDefinitionResilienceScoreReducer;
    getAssessmentDefinitionOfferedSolutionsReducer: AssessmentDefinitionOfferedSolutionsReducer;
    getAssessmentDefinitionCategoryScoresReducer: AssessmentDefinitionCategoryScoresReducer;
    getAssessmentDefinitionQuestionScoresReducer: AssessmentDefinitionQuestionScoresReducer;
}>({
    getDashboardConfigurationReducer,
    getAssessmentDefinitionResilienceScoreReducer,
    getAssessmentDefinitionOfferedSolutionsReducer,
    getAssessmentDefinitionCategoryScoresReducer,
    getAssessmentDefinitionQuestionScoresReducer,
});
