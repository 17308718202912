import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import { DashboardConfigurationItem } from '../type';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AxiosResponse } from 'axios';

const initialState = {
    status: RequestStatus.Init,
};

const putDashboardComponentConfigSlice = createSlice({
    name: 'dashboard/updateDashboardConfigurationItems',
    initialState: initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(putDashboardComponentConfigThunk.pending, (state) => {
            state.status = RequestStatus.Loading;
        });
        builder.addCase(putDashboardComponentConfigThunk.fulfilled, (state) => {
            state.status = RequestStatus.Done;
        });
        builder.addCase(putDashboardComponentConfigThunk.rejected, (state) => {
            state.status = RequestStatus.Error;
        });
    },
});

export const putDashboardComponentConfigThunk = createAsyncThunk<
    string,
    DashboardConfigurationItem[]
>(
    'updateDashboardConfigurationItems',
    async (dashboardConfig: DashboardConfigurationItem[], { rejectWithValue }) => {
        try {
            const result: AxiosResponse<string> = await axiosInstance.put(
                EndPoints.DASHBOARD_CONFIGURATION,
                dashboardConfig,
            );
            return result.data;
        } catch (err) {
            const serializedError = {
                message: err instanceof Error ? err.message : 'An error occurred',
                status: (err as any)?.response?.status || 500,
            };
            return rejectWithValue(serializedError);
        }
    },
);

export default putDashboardComponentConfigSlice.reducer;
