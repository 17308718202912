import React, { useState } from 'react';
import { Box } from '@mui/material';
import { CustomAccountBranding } from '../../../../Branding/type';
import UploadIcon from '../../../../../assets/svg-components/UploadIcon';
import { AssessmentDefinitionStatementScores } from '../../../type';
import GaugeChart from '../../../../Evaluations/EvaluationAnalysis/EvaluationAnalysisContainer/GaugeChart';
import StatementScoresModal from './StatementScoresModal';
import SortLowToHighIcon from '../../../../../assets/svg-components/SortLowToHighIcon';

interface LowestScoringStatementsProps {
    title: string;
    branding: CustomAccountBranding;
    data: AssessmentDefinitionStatementScores | null;
}

const LowestScoringStatements: React.FC<LowestScoringStatementsProps> = ({ title, branding, data }) => {
    const pluralEvaluationPropertyName = branding.evaluation_property_name_translation || "Assessments";
    const singularEvaluationPropertyName = pluralEvaluationPropertyName.endsWith("s")
        ? pluralEvaluationPropertyName.slice(0, -1) // Remove the last character
        : pluralEvaluationPropertyName;
    const titleCaseSingularEvaluationPropertyName = singularEvaluationPropertyName
        ? singularEvaluationPropertyName[0].toUpperCase() + singularEvaluationPropertyName.slice(1).toLowerCase()
        : '';

    const sortedQuestions = (data?.assessment_questions ?? [])
        .slice()
        .sort((a, b) => {
            // Convert average_score strings to numbers, and treat null as -Infinity
            const scoreA =
                typeof a.average_score === 'number'
                    ? a.average_score
                    : typeof a.average_score === 'string'
                        ? parseFloat(a.average_score)
                        : -Infinity;

            const scoreB =
                typeof b.average_score === 'number'
                    ? b.average_score
                    : typeof b.average_score === 'string'
                        ? parseFloat(b.average_score)
                        : -Infinity;

            // Sort in ascending order (lowest score first)
            return scoreA - scoreB;
        });

    // Modal state
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Handlers for opening and closing modal
    const handleClick = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    return (
        <>
            {/* Grid Container */}
            <Box
                sx={{
                    minHeight: '100%',
                    width: '100%',
                    padding: "24px",
                    paddingBottom: "0px",
                }}>
                <Box
                    sx={{
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 0.5,
                            alignItems: 'center',
                        }}>
                        <SortLowToHighIcon color={'#00314B'} />
                        <Box
                            sx={{
                                fontFamily: "Inter, sans-serif",
                                color: "#00314B",
                                fontWeight: 600,
                                fontSize: '20px',
                                lineHeight: '28px',
                            }}
                        >
                            Lowest Scoring Statements
                        </Box >
                        <Box
                            sx={{
                                marginLeft: 'auto',
                                fontFamily: "Inter, sans-serif",
                                color: "#00314B",
                                fontWeight: 600,
                                fontSize: '16px',
                                lineHeight: '20px',
                                textDecoration: 'underline',
                                textUnderlineOffset: '3px',
                                cursor: 'pointer',  // Add pointer cursor on hover
                                '&:hover': {
                                    color: '#005A8D',  // Optional: Change color on hover
                                }
                            }}
                            onClick={handleClick}
                        >
                            View all
                        </Box>
                    </Box>
                </Box>

                {/* Row Description */}
                <Box
                    sx={{
                        paddingTop: "10px",
                        // paddingBottom: "10px",
                        // paddingLeft: "24px",
                        fontFamily: "Inter, sans-serif",
                        color: "#2E3645",
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '20px',
                    }}
                >
                    Statements needing attention in all categories.
                </Box >



                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        overflowX: 'auto',
                        gap: 1,
                        paddingBottom: '10px',
                        '&::-webkit-scrollbar': {
                            height: '8px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#A9A9A9',
                            borderRadius: '4px',
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: '#F5F5F5',
                        }
                    }}
                >
                    {/* Render First 5 Cards */}
                    {sortedQuestions.slice(0, 5).map((question) => (
                        <Box
                            key={question.id}
                            sx={{
                                width: '389px',
                                height: '260px',
                                backgroundColor: '#FAFCFC',
                                border: '1px solid #D3D7DA',
                                borderRadius: '4px',
                                flex: '0 0 auto',
                            }}>
                            <Box
                                sx={{
                                    paddingTop: "16px",
                                    paddingLeft: "19px",
                                    display: 'flex',
                                    gap: 1,
                                    alignItems: 'center',
                                }}>
                                <Box
                                    sx={{
                                        fontFamily: "Inter, sans-serif",
                                        color: "#2E3645",
                                        fontWeight: 700,
                                        fontSize: '14px',
                                        lineHeight: '10px',
                                    }}
                                >
                                    {question.question_category.name}
                                </Box >
                                {/* <Box
                                        sx={{
                                            border: 'none',
                                            borderTop: '1px solid white',
                                            width: '100%',
                                            // margin: '10px 0'
                                        }}
                                    /> */}
                            </Box>
                            <Box sx={{
                                height: "72px",
                                maxHeight: "75px",
                                paddingTop: "10px",
                                paddingLeft: "19px",
                                fontFamily: "Inter, sans-serif",
                                color: "#2E3645",
                                fontWeight: 500,
                                fontSize: '16px',
                                lineHeight: '20px',
                                display: 'flex',
                                gap: 1,
                            }}
                            >
                                <Box sx={{ flexShrink: 0 }}>{question.display_order}.</Box>
                                <Box
                                    sx={{
                                        display: "-webkit-box",  // Enables the line clamping mechanism
                                        WebkitBoxOrient: "vertical",  // Defines the box orientation
                                        WebkitLineClamp: 3,  // Limits text to 3 lines
                                        overflow: "hidden",  // Hides overflowing content
                                        textOverflow: "ellipsis",  // Adds the "..." on the 3rd line
                                    }}
                                >
                                    {question.text}
                                </Box>
                            </Box >
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1em', paddingBottom: '10px' }}>
                                <GaugeChart
                                    score={question.average_score}
                                    forceRender={true}
                                    minScore={question.min_possible_score}
                                    maxScore={question.max_possible_score}
                                    useCard={false}
                                    redLegendText='At Risk'
                                    yellowLegendText='Need Improvement'
                                    greenLegendText='Optimal'
                                    gaugeHeight={150}
                                    gaugeWidth={220}
                                    gaugeBoxMargin={"0px 0px"}
                                    ringWidth={40}
                                    needleColor="#3D54A3"
                                    displayNeedleScore={true}
                                    valueTextFontSize='15px'
                                    valuePaddingVertical={0}
                                    legendWidth='10px'
                                    legendHeight='10px'
                                    legendMarginTop='5px'
                                    legendMarginBottom='0em'
                                    legendFontSize='12px'
                                    legendFontWeight={600}
                                    legendMaxWidth='100%'
                                />
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>

            {/* Modal */}
            <StatementScoresModal
                open={isModalOpen}
                onClose={handleCloseModal}
                data={sortedQuestions}
                sortHighToLow={false} />
        </>
    );
};

export default LowestScoringStatements;
