import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { useCallback, useState } from 'react';
import { getAssessmentDefinitionCategoryScores } from '../reducers/getAssessmentDefinitionCategoryScores';

interface assessmentDefinitionCategoryScoresProp {
    assessmentDefinitionCategoryScoresLoading: boolean;
    assessmentDefinitionCategoryScoresError: unknown;
    getCategoryScores: (assessmenDefinitionId: string) => Promise<void>;
}

const useGetAssessmentDefinitionCategoryScores = (): assessmentDefinitionCategoryScoresProp => {
    const dispatch = useDispatch<AppDispatch>();
    const [assessmentDefinitionCategoryScoresLoading, setLoading] = useState(true);
    const [assessmentDefinitionCategoryScoresError, setError] = useState<unknown>();

    const getCategoryScores = useCallback(async (assessmenDefinitionId: string): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            const resultAction = await dispatch(getAssessmentDefinitionCategoryScores(assessmenDefinitionId));
            if (getAssessmentDefinitionCategoryScores.fulfilled.match(resultAction)) {
                setLoading(false);
                return;
            }
            throw new Error('Failed to fetch category scores');
        } catch (error) {
            setError(error);
            throw error;
        } finally {
            setLoading(false)
        }
    }, [dispatch]);

    return { assessmentDefinitionCategoryScoresLoading, assessmentDefinitionCategoryScoresError, getCategoryScores };
};

export default useGetAssessmentDefinitionCategoryScores;
