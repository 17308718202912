import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { NavigateFunction } from 'react-router-dom';
import { AssessmentDefinitionResponse, AssessmentQuestionResponseDetails } from '../../../../Assessments/type';
import ResultsTable from './ResultsTable';
import QuestionComponentBadge from '../../../../../assets/svg-components/QuestionComponentBadge';

interface BinaryResponseChartProps {
  title: string;
  description: string;
  navigate: NavigateFunction;
  data: AssessmentQuestionResponseDetails;
  selectedAssessmentDefinition: AssessmentDefinitionResponse;
}

const BinaryResponseChart: React.FC<BinaryResponseChartProps> = ({ title, description, navigate, data, selectedAssessmentDefinition }) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [margin, setMargin] = useState<number>(0);

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to handle modal open
  const handleOpenModal = () => setIsModalOpen(true);

  // Function to handle modal close
  const handleCloseModal = () => setIsModalOpen(false);

  const [lowerScoreAnswer, higherScoreAnswer] =
    data.multiple_choice_answer_distribution[0].answer_score >
      data.multiple_choice_answer_distribution[1].answer_score
      ? [data.multiple_choice_answer_distribution[1], data.multiple_choice_answer_distribution[0]]
      : [data.multiple_choice_answer_distribution[0], data.multiple_choice_answer_distribution[1]];

  // Calculate percentages
  const lowerScoreAnswerPercentage = (lowerScoreAnswer.answer_response_count / data.total_response_count) * 100;
  const higherScoreAnswerPercentage = (higherScoreAnswer.answer_response_count / data.total_response_count) * 100;

  useEffect(() => {
    if (componentRef.current) {
      // Calculate dynamic margin based on the available space or other logic
      const parentHeight = componentRef.current.parentElement?.offsetHeight || 0;
      const componentHeight = componentRef.current.offsetHeight;
      const availableMargin = (parentHeight - componentHeight) / 2;
      setMargin(availableMargin);
    }
  }, []);

  if (!data) {
    return <Box>No Data</Box>
  }

  return (
    <>
      {/* Grid Container */}
      <Grid container
        sx={{
          minHeight: '100%',
          width: '100%',
          overflow: 'auto',
          padding: "24px",
        }}>
        <Grid item md={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            // marginBottom: '1em',
          }}>
          {/* Question Image */}
          <QuestionComponentBadge />
          {/* Question Mark Image */}
          <Tooltip
            title="Click to View Individual Responses"
            arrow
          >
            <span
              role="button"
              tabIndex={0}
              onClick={handleOpenModal}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleOpenModal();
                }
              }}
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                fontWeight: 600,
                color: "#2E3645",
                fontSize: "16px",
                fontStyle: "normal",
                lineHeight: "150%",
              }}
            >
              View all
            </span>
          </Tooltip>
        </Grid>
        <Typography sx={{
          color: '#2E3645',
          fontSize: '1em',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: 'normal',
          // marginBottom: '2em',
        }}>
          {data.question_text}
        </Typography>

        <Grid item md={12} sx={{

        }}>
          <Box sx={{
            fontsize: '0.9em',
            fontstyle: 'normal',
            fontweight: 600,
            lineheight: 'normal',
          }}>
            Total
          </Box>
          <Box sx={{
            fontSize: '0.88em',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
            // marginBottom: '1em',
          }}>
            <span style={{
              color: '#2E3645',
              textAlign: 'center',
              fontSize: '1.3em',
              fontStyle: 'normal',
              fontWeight: 600,
            }}>
              {data.total_response_count}
            </span> Responses
          </Box >
        </Grid>

        {/* Horizontal bar representing the responses */}
        <Grid item md={12}
          sx={{
            display: 'flex',
            height: '0.75em',
            // marginTop: '0.95em',
            // marginBottom: '0.95em',
            borderRadius: '1em',
            overflow: 'hidden',
          }}
          role="button"
          tabIndex={0}
          onClick={handleOpenModal}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              handleOpenModal();
            }
          }}
          style={{
            cursor: 'pointer',
            color: '#2FA9E3',
            textDecoration: 'underline',
            fontSize: '14px',
            fontWeight: 600,
          }}
        >
          <Box sx={{
            backgroundColor: '#2FA9E3',
          }}
            style={{ width: `${higherScoreAnswerPercentage}%` }}></Box>
          <Box sx={{
            backgroundColor: '#D82C2C',
          }}
            style={{ width: `${lowerScoreAnswerPercentage}%` }}></Box>
        </Grid>

        {/* Display counts of companies */}
        <Grid item md={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
          }}>
            <span style={{
              marginRight: '0.3em',
            }}>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M6 12C6.78793 12 7.56815 11.8448 8.2961 11.5433C9.02405 11.2417 9.68549 10.7998 10.2426 10.2426C10.7998 9.68549 11.2417 9.02405 11.5433 8.2961C11.8448 7.56815 12 6.78793 12 6C12 5.21207 11.8448 4.43185 11.5433 3.7039C11.2417 2.97595 10.7998 2.31451 10.2426 1.75736C9.68549 1.20021 9.02405 0.758251 8.2961 0.456723C7.56815 0.155195 6.78793 -1.17411e-08 6 0C4.4087 2.37122e-08 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12ZM5.84533 8.42667L9.17867 4.42667L8.15467 3.57333L5.288 7.01267L3.80467 5.52867L2.862 6.47133L4.862 8.47133L5.378 8.98733L5.84533 8.42667Z" fill="#2FA9E3" />
              </svg>
            </span>
            <span style={{
              fontSize: '0.87em',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '1.2em',
            }}>
              {higherScoreAnswer.answer_text.toUpperCase()}
            </span>
            <span style={{
              borderRadius: '4px',
              backgroundColor: '#EEF1FF',
              marginLeft: '0.6em',
              display: 'flex',
              padding: '0.4em 0.7em',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              fontSize: '0.75em',
              fontWeight: 600,
            }}>
              <span style={{
                color: '#197DAD',
                textAlign: 'center',
                fontSize: '1.2em',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
              }}>
                {higherScoreAnswer.answer_response_count}
              </span>&nbsp;Companies
            </span>
          </Box>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
          }}>
            <span style={{
              marginRight: '0.3em',
            }}>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H11C11.2652 12 11.5196 11.8946 11.7071 11.7071C11.8946 11.5196 12 11.2652 12 11V1C12 0.734784 11.8946 0.48043 11.7071 0.292893C11.5196 0.105357 11.2652 0 11 0ZM9.35375 8.64625C9.40021 8.69271 9.43705 8.74786 9.4622 8.80855C9.48734 8.86925 9.50028 8.9343 9.50028 9C9.50028 9.0657 9.48734 9.13075 9.4622 9.19145C9.43705 9.25214 9.40021 9.30729 9.35375 9.35375C9.30729 9.40021 9.25214 9.43705 9.19145 9.4622C9.13075 9.48734 9.0657 9.50028 9 9.50028C8.9343 9.50028 8.86925 9.48734 8.80855 9.4622C8.74786 9.43705 8.69271 9.40021 8.64625 9.35375L6 6.70687L3.35375 9.35375C3.25993 9.44757 3.13268 9.50028 3 9.50028C2.86732 9.50028 2.74007 9.44757 2.64625 9.35375C2.55243 9.25993 2.49972 9.13268 2.49972 9C2.49972 8.86732 2.55243 8.74007 2.64625 8.64625L5.29313 6L2.64625 3.35375C2.55243 3.25993 2.49972 3.13268 2.49972 3C2.49972 2.86732 2.55243 2.74007 2.64625 2.64625C2.74007 2.55243 2.86732 2.49972 3 2.49972C3.13268 2.49972 3.25993 2.55243 3.35375 2.64625L6 5.29313L8.64625 2.64625C8.74007 2.55243 8.86732 2.49972 9 2.49972C9.13268 2.49972 9.25993 2.55243 9.35375 2.64625C9.44757 2.74007 9.50028 2.86732 9.50028 3C9.50028 3.13268 9.44757 3.25993 9.35375 3.35375L6.70687 6L9.35375 8.64625Z" fill="#D43333" />
              </svg>
            </span>
            <span style={{
              fontSize: '0.87em',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '1.2em',
            }}>
              {lowerScoreAnswer.answer_text.toUpperCase()}
            </span>
            <span style={{
              borderRadius: '4px',
              backgroundColor: '#FFEBEB',
              marginLeft: '0.6em',
              display: 'flex',
              padding: '0.4em 0.7em',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              fontSize: '0.75em',
              fontWeight: 600,
            }}>
              <span style={{
                color: '#D43333',
                textAlign: 'center',
                fontSize: '1.2em',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
              }}>
                {lowerScoreAnswer.answer_response_count}
              </span>&nbsp;Companies
            </span>
          </Box>
        </Grid>
      </Grid >

      <ResultsTable
        open={isModalOpen}
        onClose={handleCloseModal}
        navigate={navigate}
        data={data.answers}
        selectedAssessmentDefinition={selectedAssessmentDefinition}
      />
    </>
  );
};

export default BinaryResponseChart;
