import React, { useState } from 'react';
import { Modal, Box, Typography, Tooltip as MuiTooltip, Grid } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';
import { MultipleChoiceResponseOptionsChartData, QuestionResponse } from '../../../type';
import { AssessmentDefinitionResponse, AssessmentQuestionResponseDetails } from '../../../../Assessments/type';
import { NavigateFunction } from 'react-router-dom';
import ResultsTable from './ResultsTable';
import QuestionComponentBadge from '../../../../../assets/svg-components/QuestionComponentBadge';

type MultipleChoiceResponseOptionsChartProps = {
  title: string;
  description: string;
  navigate: NavigateFunction;
  data: AssessmentQuestionResponseDetails;
  selectedAssessmentDefinition: AssessmentDefinitionResponse;
};

const CustomBarShape = (props: any) => {
  const { x, y, width, height, fill } = props;

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fill}
        rx={2}
        ry={2}
      />
    </g>
  );
};

const CustomYAxisTick: React.FC<any> = ({ x, y, payload }) => {
  const maxCharsPerLine = 10;

  // Function to split text into multiple lines
  const wrapText = (text: string, maxChars: number): string[] => {
    const words = text.split(' ');
    const lines: string[] = [];
    let currentLine = '';

    words.forEach((word) => {
      if ((currentLine + word).length <= maxChars) {
        currentLine += `${word} `;
      } else {
        lines.push(currentLine.trim());
        currentLine = `${word} `;
      }
    });

    lines.push(currentLine.trim());
    return lines;
  };

  // Get the wrapped text lines
  const wrappedLines = wrapText(payload.value as string, maxCharsPerLine);

  return (
    <g>
      {wrappedLines.map((line, index) => (
        <text
          key={index}
          x={x}
          y={(y as number) + index * 12}
          textAnchor="end"
          fill="#2E3645"
          fontFamily="Inter"
          fontSize={10}
          fontStyle="normal"
          fontWeight={600}
        >
          {line}
        </text>
      ))}
    </g>
  );
};

const CustomXAxisTick: React.FC<any> = ({ x, y, payload }) => {
  const adjustedY = (y as number) + 10;

  return (
    <text
      x={x}
      y={adjustedY}
      textAnchor="middle"
      fill="#2E3645"
      fontFamily="Inter"
      fontSize={12}
      fontStyle="normal"
      fontWeight={600}
    >
      {payload.value}
    </text>
  );
};

const MultipleChoiceResponseOptionsChart: React.FC<MultipleChoiceResponseOptionsChartProps> = ({ title, description, navigate, data, selectedAssessmentDefinition }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const { answers, multiple_choice_answer_distribution } = data;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const combinedAnswers = Object.values(answers).flat() as QuestionResponse[];

  // Chart data transformation
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const chartData = multiple_choice_answer_distribution.map((answerOption) => {

    return {
      answer: answerOption.answer_text,
      count: answerOption.answer_response_count,
    };
  });

  // Define the colors for each bar
  const colors = ['#AB8204', '#C49A19', '#DAB134', '#ECC54C', '#FED969'];

  const reversedChartData = [...chartData].reverse();

  if (!data) {
    return <Box>No Data</Box>
  }

  return (
    <>
      {/* Grid Container */}
      <Grid container
        sx={{
          minHeight: '100%',
          width: '100%',
          overflow: 'auto',
          padding: "24px",
        }}>

        {/* Bar Graph Icon */}
        <Grid item md={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            // marginBottom: '1em',
          }}>
          <QuestionComponentBadge />
          <MuiTooltip
            title="Click to View Individual Responses"
            arrow
          >
            <span
              role="button"
              tabIndex={0}
              onClick={handleOpenModal}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleOpenModal();
                }
              }}
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                fontWeight: 600,
                color: "#2E3645",
                fontSize: "16px",
                fontStyle: "normal",
                lineHeight: "150%",
              }}
            >
              View all
            </span>
          </MuiTooltip>
        </Grid>
        <Grid item md={12} sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          gap: '0.4em',
        }}>
          <Typography sx={{
            fontSize: '1.25em !important',
            fontStyle: 'normal !important',
            fontWeight: '600 !important',
            lineHeight: '1.4em !important',
            letterSpacing: '-0.01em !important',
          }}>
            {title}
          </Typography>
        </Grid>
        <span style={{
          color: '#606060',
          fontFamily: 'Inter',
          fontSize: '0.88em',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: 'normal',
        }}>
          {description}
        </span>
        <Grid item md={12} >
          {/* <Box sx={{
          marginTop: '2.5em',
          marginBottom: '0em',
          fontSize: '10px !important',
          fontWeight: '700 !important',
          color: '#2E2E2E',
          display: 'inline-flex',
          padding: '4px 8px',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '4px',
          border: '2px solid #2E3645',
        }}>
          % of Employees Retained
        </Box> */}
          <ResponsiveContainer width="100%" height={275}>
            <BarChart
              data={reversedChartData}
              layout="vertical"
              margin={{ top: 10, right: 10, left: 20, bottom: 10 }}
              barSize={24}
            >
              <CartesianGrid strokeDasharray="2 2" horizontal={false} vertical={true} />
              <XAxis
                type="number"
                axisLine={false}
                tickLine={false}
                tick={<CustomXAxisTick />}
              />
              <YAxis
                type="category"
                dataKey="answer"
                axisLine={false}
                tickLine={false}
                tick={<CustomYAxisTick />}
              />
              <Tooltip />
              <Bar dataKey="count" shape={<CustomBarShape />}>
                {reversedChartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            width: '90%',
            color: '#2E3645',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
          }}>
            Number of Companies
          </Box>
        </Grid>
      </Grid >

      <ResultsTable
        open={isModalOpen}
        onClose={handleCloseModal}
        navigate={navigate}
        data={answers}
        selectedAssessmentDefinition={selectedAssessmentDefinition}
      />
    </>
  );
};

export default MultipleChoiceResponseOptionsChart;
