import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { useCallback, useState } from 'react';
import { getDashboardConfiguration } from '../reducers/getDashboardConfiguration';

interface dashboardConfigurationProp {
    dashboardConfigurationLoading: boolean;
    dashboardConfigurationError: unknown;
    getDashboardComponents: () => Promise<void>;
}

const useGetDashboardConfiguration = (): dashboardConfigurationProp => {
    const dispatch = useDispatch<AppDispatch>();
    const [dashboardConfigurationLoading, setLoading] = useState(true);
    const [dashboardConfigurationError, setError] = useState<unknown>();

    const getDashboardComponents = useCallback(async (): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            const resultAction = await dispatch(getDashboardConfiguration());
            if (getDashboardConfiguration.fulfilled.match(resultAction)) {
                setLoading(false);
                return;
            }
            throw new Error('Failed to fetch dashboard configuration');
        } catch (error) {
            setError(error);
            throw error;
        } finally {
            setLoading(false)
        }
    }, [dispatch]);

    return { dashboardConfigurationLoading, dashboardConfigurationError, getDashboardComponents };
};

export default useGetDashboardConfiguration;
