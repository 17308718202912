/* eslint-disable indent */
import React, { useState } from 'react';
import { Modal, Box, Typography, Button, Tooltip as MuiTooltip, Grid } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell, LabelList } from 'recharts';
import { NavigateFunction } from 'react-router-dom';
import { AssessmentDefinitionResponse, AssessmentQuestionResponseDetails, UnitOfMeasureType } from '../../../../Assessments/type';
import { CompanyAnswersBySize } from '../../../type';
import ResultsTable from './ResultsTable';
import QuestionComponentBadge from '../../../../../assets/svg-components/QuestionComponentBadge';
import { COMPANY_SIZE_RANGES, getCompanySizeLabel } from '../../../../../utils/companySize';

type FillInNumberResponseChartProps = {
  title: string;
  description: string;
  navigate: NavigateFunction;
  data: AssessmentQuestionResponseDetails;
  selectedAssessmentDefinition: AssessmentDefinitionResponse;
};

const CustomBarShape = (props: any) => {
  const { x, y, width, height, fill } = props;

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fill}
        rx={2}
        ry={2}
      />
    </g>
  );
};

const CustomTooltip = ({ active, payload, label, formatLabel }: {
  active?: boolean,
  payload?: any[],
  label?: string,
  formatLabel: (value: number) => string
}) => {
  if (active && payload && payload.length && label) {
    const trimmedLabel = label.replace(" Enterprise", "").trim().toLowerCase();

    const companySizeRange = Object.values(COMPANY_SIZE_RANGES).find(
      (size) => size.label.toLowerCase().includes(trimmedLabel)
    );
    let employeeRange = 'Unknown';

    if (companySizeRange) {
      if ('max' in companySizeRange && 'min' in companySizeRange) {
        employeeRange = `${companySizeRange.min}-${companySizeRange.max} Employees`;
      } else if ('max' in companySizeRange) {
        employeeRange = `0-${companySizeRange.max} Employees`;
      } else if ('min' in companySizeRange) {
        employeeRange = `${companySizeRange.min}+ Employees`;
      }
    }

    return (
      <Box sx={{
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        padding: '5px',
        borderRadius: '4px',
        boxShadow: '0 0 8px rgba(0, 0, 0, 0.1)',
      }}>
        <Box>
          {formatLabel(payload[0].value as number)}
        </Box>
        <p className="label">{`${label as string}: ${employeeRange}`}</p>
      </Box >
    );
  }

  return null;
};

const FillInNumberResponseChart: React.FC<FillInNumberResponseChartProps> = ({ title, description, navigate, data, selectedAssessmentDefinition }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const { response_unit_of_measure, average_numeric_response, answers } = data;

  const answer_averages_by_company_size: CompanyAnswersBySize = (() => {
    // Initialize sums and counts for each company size
    const sums = { small: 0, medium: 0, large: 0 };
    const counts = { small: 0, medium: 0, large: 0 };

    // Loop through responses and categorize valid numeric answers
    answers.forEach(({ company_size, answer }) => {
      const businessCategory = getCompanySizeLabel(company_size)
        .replace(" Manufacturer", "")
        .toLowerCase();

      const parsedAnswer = typeof answer === "string" ? parseFloat(answer) : answer;

      if (
        ["small", "medium", "large"].includes(businessCategory) &&
        typeof parsedAnswer === "number" && !isNaN(parsedAnswer) // Ensure answer is a valid number
      ) {
        sums[businessCategory] += parsedAnswer;
        counts[businessCategory] += 1;
      }
    });

    // Compute rounded averages, avoiding division by zero
    return {
      small: counts.small > 0 ? Math.round(sums.small / counts.small) : 0,
      medium: counts.medium > 0 ? Math.round(sums.medium / counts.medium) : 0,
      large: counts.large > 0 ? Math.round(sums.large / counts.large) : 0,
    };
  })();

  // Prepare data for the chart
  const chartData = [
    { name: 'Small Enterprise', value: answer_averages_by_company_size.small, color: "#63C394" },
    { name: 'Medium Enterprise', value: answer_averages_by_company_size.medium, color: "#EBB63B" },
    { name: 'Large Enterprise', value: answer_averages_by_company_size.large, color: "#6293CF" },
  ];

  const formatLabel = (value: number) => {
    return response_unit_of_measure === UnitOfMeasureType.DOLLAR ? `$${value.toLocaleString()}` : `${value}%`;
  };

  const formatTick = (value: number) => {
    return response_unit_of_measure === UnitOfMeasureType.DOLLAR ? `${value / 1000}k` : `${value}%`;
  };

  return (
    <>
      {/* Grid Container */}
      <Grid container
        sx={{
          minHeight: '100%',
          width: '100%',
          overflow: 'auto',
          padding: "24px",
        }}>
        <Grid item md={12}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            maxWidth: '100%',
          }}>
          {/* Question Image */}
          <QuestionComponentBadge />
          <MuiTooltip
            title="Click to View Individual Responses"
            arrow
          >
            <span
              role="button"
              tabIndex={0}
              onClick={handleOpenModal}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleOpenModal();
                }
              }}
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                fontWeight: 600,
                color: "#2E3645",
                fontSize: "16px",
                fontStyle: "normal",
                lineHeight: "150%",
              }}
            >
              View all
            </span>
          </MuiTooltip>
        </Grid>

        <Grid md={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'start',
          }}>
          <Box sx={{
            fontSize: '1.25em !important',
            fontStyle: 'normal !important',
            fontFamily: 'Inter',
            fontWeight: '600 !important',
            letterSpacing: '-0.01em !important',
          }}>
            {title}
          </Box>
        </Grid>

        <Grid md={12}
          sx={{
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'start',
          }}>
          <Box sx={{
            color: '#606060',
            fontFamily: 'Inter',
            fontSize: '0.88em',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: 'normal',
          }}>
            {description}
          </Box>
        </Grid>

        {
          average_numeric_response && (
            <Grid item md={12}>
              <Typography sx={{
                marginTop: '0.3em',
                color: '#2E3645',
                fontFamily: 'Inter',
                fontSize: '1.5em',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: 'normal',
              }}>
                {formatLabel(average_numeric_response)}
              </Typography>
              <Box sx={{
                // marginTop: '1em',
                marginBottom: '0em',
                fontSize: '1em !important',
                fontWeight: '600 !important',
              }}>
                Averages by Company Size:
              </Box>
            </Grid>
          )
        }
        <ResponsiveContainer width="100%" height={270}>
          <BarChart
            layout="vertical"
            data={chartData}
            margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
            barSize={24}
          >
            <CartesianGrid strokeDasharray="2 2" horizontal={false} vertical={true} />
            <XAxis
              type="number"
              axisLine={false}
              tickLine={false}
              tickFormatter={formatTick}
            />
            <YAxis type="category" dataKey="name" hide={true} />
            <Tooltip content={<CustomTooltip formatLabel={formatLabel} />} cursor={{ fill: 'transparent' }} />
            <Bar dataKey="value" shape={<CustomBarShape />}>
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
              <LabelList dataKey="name" position="top" content={(props) => {
                const { x, y, width, value } = props;
                return (
                  <text
                    x={1}
                    y={(y as number) - 10}
                    fill="#606060"
                    textAnchor="start"
                    dominantBaseline="middle"
                    fontFamily="Inter"
                    fontSize="14px"
                    fontStyle="normal"
                    fontWeight="500"
                    height="14px"
                  >
                    {value}
                  </text>
                );
              }} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        <Grid item md={12} sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          maxWidth: '100%',
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '1rem',
          }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '0.5rem',
              color: '#2E3645',
              fontFamily: 'Inter',
              fontSize: '1em',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '1.2em',
            }}>
              <span style={{
                backgroundColor: "#63C394",
                width: '0.6em',
                height: '0.6em',
                borderRadius: '50%',
                marginRight: '0.5rem',
              }}></span>
              <span>{`Small`}</span>
            </Box>
            <Box sx={{
              color: '#2E3645',
              fontFamily: 'Inter',
              fontSize: '1.52em',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '1.6em',
            }}>
              {response_unit_of_measure === UnitOfMeasureType.DOLLAR
                ? `$ ${answer_averages_by_company_size.small.toLocaleString()}`
                : `${answer_averages_by_company_size.small.toLocaleString()}%`}
            </Box>
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '1rem',
          }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '0.5rem',
              color: '#2E3645',
              fontFamily: 'Inter',
              fontSize: '1em',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '1.2em',
            }}>
              <span style={{
                backgroundColor: "#EBB63B",
                width: '0.6em',
                height: '0.6em',
                borderRadius: '50%',
                marginRight: '0.5rem',
              }}></span>
              <span>{`Medium`}</span>
            </Box>
            <Box sx={{
              color: '#2E3645',
              fontFamily: 'Inter',
              fontSize: '1.52em',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '1.6em',
            }}>
              {response_unit_of_measure === UnitOfMeasureType.DOLLAR
                ? `$ ${answer_averages_by_company_size.medium.toLocaleString()}`
                : `${answer_averages_by_company_size.medium.toLocaleString()}%`}
            </Box>
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '1rem',
          }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '0.5rem',
              color: '#2E3645',
              fontFamily: 'Inter',
              fontSize: '1em',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '1.2em',
            }}>
              <span style={{
                backgroundColor: "#6293CF",
                width: '0.6em',
                height: '0.6em',
                borderRadius: '50%',
                marginRight: '0.5rem',
              }}></span>
              <span>{`Large`}</span>
            </Box>
            <Box sx={{
              color: '#2E3645',
              fontFamily: 'Inter',
              fontSize: '1.52em',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '1.6em',
            }}>
              {response_unit_of_measure === UnitOfMeasureType.DOLLAR
                ? `$ ${answer_averages_by_company_size.large.toLocaleString()}`
                : `${answer_averages_by_company_size.large.toLocaleString()}%`}
            </Box>
          </Box>
        </Grid>
      </Grid >

      {/* Modal */}
      <ResultsTable
        open={isModalOpen}
        onClose={handleCloseModal}
        navigate={navigate}
        data={data.answers}
        selectedAssessmentDefinition={selectedAssessmentDefinition}
      />
    </>
  );
};

export default FillInNumberResponseChart;
