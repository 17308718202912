import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AssessmentDefinitionStatementScores } from '../type';
import { RequestStatus } from '../../../types/request';
import { axiosV2Instance } from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';

export interface AssessmentDefinitionQuestionScoresReducer {
    question_scores: AssessmentDefinitionStatementScores | null;
    status: RequestStatus;
}

const initialState: AssessmentDefinitionQuestionScoresReducer = {
    question_scores: null,
    status: RequestStatus.Init,
};

export const getAssessmentDefinitionQuestionScores = createAsyncThunk(
    'assessment-definition/getAssessmentDefinitionQuestionScores',
    async (assessmentDefinitionId: string, { rejectWithValue }) => {
        try {
            const response = await axiosV2Instance.get<AssessmentDefinitionStatementScores>(
                `${EndPoints.ASSESSMENT_DEFINITIONS}/${assessmentDefinitionId}/${EndPoints.QUESTION_SCORES}`
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || error.message);
        }
    },
);

const getAssessmentDefinitionQuestionScoresSlice = createSlice({
    name: 'getAssessmentDefinitionQuestionScores',
    initialState,
    reducers: {
        clearAssessmentDefinitionQuestionScores: (state) => {
            state.question_scores = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAssessmentDefinitionQuestionScores.pending, (state) => {
                state.status = RequestStatus.Loading;
            })
            .addCase(getAssessmentDefinitionQuestionScores.fulfilled, (state, action) => {
                state.status = RequestStatus.Done;
                state.question_scores = action.payload;
            })
            .addCase(getAssessmentDefinitionQuestionScores.rejected, (state, action) => {
                state.status = RequestStatus.Error;
            });
    },
});

export const { clearAssessmentDefinitionQuestionScores } = getAssessmentDefinitionQuestionScoresSlice.actions;

export default getAssessmentDefinitionQuestionScoresSlice.reducer;
