import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssessmentQuestionResponseDetails } from '../type';
import { RequestStatus } from '../../../types/request';
import { axiosV2Instance } from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';

export interface AssessmentQuestionResponseDetailsReducer {
    question_response_details: Record<number, AssessmentQuestionResponseDetails>;
    status: Record<number, RequestStatus>;
}

const initialState: AssessmentQuestionResponseDetailsReducer = {
    question_response_details: {},
    status: {},
};

export const getAssessmentQuestionResponseDetails = createAsyncThunk<
    { questionId: number; data: AssessmentQuestionResponseDetails }, // Success return type
    { assessmentDefinitionId: string; questionId: number },
    { rejectValue: { questionId: number; error: string } } // Reject type
>(
    'assessment-question/getAssessmentQuestionResponseDetails',
    async ({ assessmentDefinitionId, questionId }, { rejectWithValue }) => {
        try {
            const response = await axiosV2Instance.get<AssessmentQuestionResponseDetails>(
                `${EndPoints.ASSESSMENT_DEFINITIONS}/${assessmentDefinitionId}/${EndPoints.ASSESSMENT_QUESTIONS}/${questionId}/${EndPoints.RESPONSE_DETAILS}`
            );
            return { questionId, data: response.data };
        } catch (error: any) {
            return rejectWithValue({
                questionId,
                error: error.response?.data || error.message || 'Unknown error',
            });
        }
    }
);

const getAssessmentQuestionResponseDetailsSlice = createSlice({
    name: 'getAssessmentQuestionResponseDetails',
    initialState,
    reducers: {
        clearAssessmentQuestionResponseDetails: (state, action: PayloadAction<number>) => {
            delete state.question_response_details[action.payload];
            delete state.status[action.payload];
        },
        resetAllQuestionResponseDetails: (state) => {
            state.question_response_details = {};
            state.status = {};
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAssessmentQuestionResponseDetails.pending, (state, action) => {
                state.status[action.meta.arg.questionId] = RequestStatus.Loading;
            })
            .addCase(getAssessmentQuestionResponseDetails.fulfilled, (state, action) => {
                state.status[action.payload.questionId] = RequestStatus.Done;
                state.question_response_details[action.payload.questionId] = action.payload.data;
            })
            .addCase(getAssessmentQuestionResponseDetails.rejected, (state, action) => {
                if (action.payload) {
                    state.status[action.payload.questionId] = RequestStatus.Error;
                }
            });
    },
});

export const { clearAssessmentQuestionResponseDetails, resetAllQuestionResponseDetails } = getAssessmentQuestionResponseDetailsSlice.actions;

export default getAssessmentQuestionResponseDetailsSlice.reducer;
