import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { CategoryScoreQuestionDetails, UnitOfMeasureType } from '../../../type';
import LikertScaleResponseLineChart from '../QuestionResultDisplays/LikertScaleResponseLineChart';

interface SurveySummaryProps {
    questionDetails: CategoryScoreQuestionDetails;
}

const NumericAndNumericRangeResponseDisplay: React.FC<SurveySummaryProps> = ({
    questionDetails
}) => {
    const { average_numeric_response, response_unit_of_measure } = questionDetails;
    interface FormatAverageAnswerWithUnitProps {
        answer: number;
        response_unit_of_measure: UnitOfMeasureType | null
    }

    const formatAverageAnswerWithUnit = ({ answer, response_unit_of_measure }: FormatAverageAnswerWithUnitProps) => {
        switch (response_unit_of_measure) {
            case UnitOfMeasureType.PERCENT:
                return `${answer}%`;
            case UnitOfMeasureType.DOLLAR:
                return `$${answer}`;
            case UnitOfMeasureType.MINUTE:
                return `${answer} Minutes`;
            case UnitOfMeasureType.HOUR:
                return `${answer} Hours`;
            case UnitOfMeasureType.DAY:
                return `${answer} Days`;
            case UnitOfMeasureType.WEEK:
                return `${answer} Weeks`;
            case UnitOfMeasureType.MONTH:
                return `${answer} Months`;
            case UnitOfMeasureType.YEAR:
                return `${answer} Years`;
            case UnitOfMeasureType.EMPLOYEES:
                return `${answer} Employees`;
            case UnitOfMeasureType.CUSTOMER:
                return `${answer} Customers`;
            default:
                return `${answer}`;
        }
    };


    return (
        <Box sx={{ margin: '20px 1.5em 0px 1.5em' }}>
            {/* Average Answer Section */}
            <Grid container
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    gap: '8px',
                    marginBottom: '12px',
                }}>
                <Grid item sm={12} md={6}>
                    <LikertScaleResponseLineChart questionDetails={questionDetails} />
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px', paddingLeft: '8px' }}>
                <Grid item sm={12} md={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        // justifyContent: 'flex-end',
                    }}>
                        
                    <Typography
                        sx={{
                            color: '#053D58',
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontStyle: 'italic',
                            fontWeight: 400,
                            lineHeight: '15px',
                            letterSpacing: '-0.36px',
                            display: 'flex',
                            justifyContent: 'end',
                            alignItems: 'center',
                            marginTop: '9px',
                        }}
                    >
                        Company Response:
                    </Typography>
                    <Typography
                        sx={{
                            padding: '4px 8px',
                            borderRadius: '4px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            color: '#053D58',
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontStyle: 'italic',
                            fontWeight: 600,
                            lineHeight: '15px',
                            letterSpacing: '-0.36px',
                        }}
                    >
                        {formatAverageAnswerWithUnit({ answer: average_numeric_response, response_unit_of_measure })}
                    </Typography>
                </Grid>
            </Box>
        </Box >
    );
};

export default NumericAndNumericRangeResponseDisplay;
