import React from 'react';
import { Typography } from '@mui/material';
import { getCompanySizeLabel, formatCompanySize } from '../utils/companySize';

interface CompanySizeDisplayProps {
  employeeCount: number | string | null | undefined;
  showLabel?: boolean;
  showCount?: boolean;
}

const CompanySizeDisplay: React.FC<CompanySizeDisplayProps> = ({ 
  employeeCount, 
  showLabel = true, 
  showCount = true 
}) => {
  if (employeeCount === null || employeeCount === undefined) {
    return <Typography variant="body1">Not specified</Typography>;
  }

  if (showLabel && showCount) {
    return <Typography variant="body1">{formatCompanySize(employeeCount)}</Typography>;
  } else if (showLabel) {
    return <Typography variant="body1">{getCompanySizeLabel(employeeCount)}</Typography>;
  } else if (showCount) {
    return <Typography variant="body1">{employeeCount} Employees</Typography>;
  }
  
  return null;
};

export default CompanySizeDisplay;
