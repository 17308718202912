import { useEffect } from 'react';
import TotalResilienceScoreGasGauge from '../TotalResilienceScoreGasGauge';
import MostFrequentlyOfferedSolutions from '../MostFrequentlyOfferedSolutions';
import AssessmentScoringByCategory from '../ScoringByCategory/AssessmentScoringByCategory';
import { AssessmentDefinitionResponse, CategoryScoreQuestionDetails } from '../../../../Assessments/type';
import { useAppDispatch } from '../../../../../redux/store';
import { resetDashboardConfigurationState } from '../../../reducers/getDashboardConfiguration';
import useGetAssessmentDefinitionResilienceScore from '../../../hooks/useGetAssessmentDefinitionResilienceScore';
import QuestionDataComponentMapper from './QuestionDataComponentMapper';

// Data-fetching functions
const fetchResilienceScoreData = async (
  selectedAssessmentDefinition: AssessmentDefinitionResponse,
  dispatch: any,
  questionId?: number | null,
  getResilienceScore?: any,
  getOfferedSolutions?: any,
  getCategoryScores?: any,
  getQuestionScores?: any,
  getQuestionResponseDetails?: any
) => {
  if (selectedAssessmentDefinition) {
    try {
      await getResilienceScore(selectedAssessmentDefinition.uuid);
    } catch (error) {
      console.error('Failed to fetch resilience score:', error);
    }
  }
};

const fetchOfferedSolutionsData = async (
  selectedAssessmentDefinition: AssessmentDefinitionResponse,
  dispatch: any,
  questionId?: number | null,
  getResilienceScore?: any,
  getOfferedSolutions?: any,
  getCategoryScores?: any,
  getQuestionScores?: any,
  getQuestionResponseDetails?: any
) => {
  if (selectedAssessmentDefinition) {
    try {
      await getOfferedSolutions(selectedAssessmentDefinition.uuid);
    } catch (error) {
      console.error('Failed to fetch offered solutions:', error);
    }
  }
};

const fetchCategoryScoresData = async (
  selectedAssessmentDefinition: AssessmentDefinitionResponse,
  dispatch: any,
  questionId?: number | null,
  getResilienceScore?: any,
  getOfferedSolutions?: any,
  getCategoryScores?: any,
  getQuestionScores?: any,
  getQuestionResponseDetails?: any
) => {
  if (selectedAssessmentDefinition) {
    try {
      await getCategoryScores(selectedAssessmentDefinition.uuid);
    } catch (error) {
      console.error('Failed to fetch category scores:', error);
    }
  }
};

const fetchQuestionScoresData = async (
  selectedAssessmentDefinition: AssessmentDefinitionResponse,
  dispatch: any,
  questionId?: number | null,
  getResilienceScore?: any,
  getOfferedSolutions?: any,
  getCategoryScores?: any,
  getQuestionScores?: any,
  getQuestionResponseDetails?: any
) => {
  if (selectedAssessmentDefinition) {
    try {
      await getQuestionScores(selectedAssessmentDefinition.uuid);
    } catch (error) {
      console.error('Failed to fetch question scores:', error);
    }
  }
};

const fetchDynamicQuestionData = async (
  selectedAssessmentDefinition: AssessmentDefinitionResponse,
  dispatch: any,
  questionId?: number | null,
  getResilienceScore?: any,
  getOfferedSolutions?: any,
  getCategoryScores?: any,
  getQuestionScores?: any,
  getQuestionResponseDetails?: any
) => {
  if (selectedAssessmentDefinition.uuid && questionId) {
    try {
      await getQuestionResponseDetails(selectedAssessmentDefinition.uuid, questionId);
    } catch (error) {
      console.error('Failed to fetch question component data:', error);
    }
  }
};

// Map of components to data-fetching functions
export const ComponentDataFunctionMap: Record<
  string,
  (selectedAssessmentDefinition: AssessmentDefinitionResponse,
    dispatch: any,
    questionId?: number | null,
    getResilienceScore?: any,
    getOfferedSolutions?: any,
    getCategoryScores?: any,
    getQuestionScores?: any,
    getQuestionResponseDetails?: any
  ) => Promise<void>
> = {
  total_resilience_score_gas_gauge: fetchResilienceScoreData,
  most_frequently_offered_solutions: fetchOfferedSolutionsData,
  assessment_scoring_by_category: fetchCategoryScoresData,
  highest_scoring_statements: fetchQuestionScoresData,
  lowest_scoring_statements: fetchQuestionScoresData,
  question_data_component: fetchDynamicQuestionData,
};
