import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AssessmentDefinitionResilienceScore } from '../type';
import { RequestStatus } from '../../../types/request';
import { axiosV2Instance } from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';

export interface AssessmentDefinitionResilienceScoreReducer {
    resilience_score: AssessmentDefinitionResilienceScore | null;
    status: RequestStatus;
}

const initialState: AssessmentDefinitionResilienceScoreReducer = {
    resilience_score: null,
    status: RequestStatus.Init,
};

export const getAssessmentDefinitionResilienceScore = createAsyncThunk(
    'assessment-definition/getAssessmentDefinitionResilienceScore',
    async (assessmentDefinitionId: string, { rejectWithValue }) => {
        try {
            const response = await axiosV2Instance.get<AssessmentDefinitionResilienceScore>(
                `${EndPoints.ASSESSMENT_DEFINITIONS}/${assessmentDefinitionId}/${EndPoints.ASSESSMENT_SCORE}`
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || error.message);
        }
    },
);

const getAssessmentDefinitionResilienceScoreSlice = createSlice({
    name: 'getAssessmentDefinitionResilienceScore',
    initialState,
    reducers: {
        clearAssessmentDefinitionResilienceScore: (state) => {
            state.resilience_score = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAssessmentDefinitionResilienceScore.pending, (state) => {
                state.status = RequestStatus.Loading;
            })
            .addCase(getAssessmentDefinitionResilienceScore.fulfilled, (state, action) => {
                state.status = RequestStatus.Done;
                state.resilience_score = action.payload;
            })
            .addCase(getAssessmentDefinitionResilienceScore.rejected, (state, action) => {
                state.status = RequestStatus.Error;
            });
    },
});

export const { clearAssessmentDefinitionResilienceScore } = getAssessmentDefinitionResilienceScoreSlice.actions;

export default getAssessmentDefinitionResilienceScoreSlice.reducer;
