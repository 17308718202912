import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AssessmentDefinitionSolutions } from '../type';
import { RequestStatus } from '../../../types/request';
import { axiosV2Instance } from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';

export interface AssessmentDefinitionOfferedSolutionsReducer {
    offered_solutions: AssessmentDefinitionSolutions | null;
    status: RequestStatus;
}

const initialState: AssessmentDefinitionOfferedSolutionsReducer = {
    offered_solutions: null,
    status: RequestStatus.Init,
};

export const getAssessmentDefinitionOfferedSolutions = createAsyncThunk(
    'assessment-definition/getAssessmentDefinitionOfferedSolutions',
    async (assessmentDefinitionId: string, { rejectWithValue }) => {
        try {
            const response = await axiosV2Instance.get<AssessmentDefinitionSolutions>(
                `${EndPoints.ASSESSMENT_DEFINITIONS}/${assessmentDefinitionId}/${EndPoints.SOLUTIONS}`
            );
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || error.message);
        }
    },
);

const getAssessmentDefinitionOfferedSolutionsSlice = createSlice({
    name: 'getAssessmentDefinitionOfferedSolutions',
    initialState,
    reducers: {
        clearAssessmentDefinitionOfferedSolutions: (state) => {
            state.offered_solutions = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAssessmentDefinitionOfferedSolutions.pending, (state) => {
                state.status = RequestStatus.Loading;
            })
            .addCase(getAssessmentDefinitionOfferedSolutions.fulfilled, (state, action) => {
                state.status = RequestStatus.Done;
                state.offered_solutions = action.payload;
            })
            .addCase(getAssessmentDefinitionOfferedSolutions.rejected, (state, action) => {
                state.status = RequestStatus.Error;
            });
    },
});

export const { clearAssessmentDefinitionOfferedSolutions } = getAssessmentDefinitionOfferedSolutionsSlice.actions;

export default getAssessmentDefinitionOfferedSolutionsSlice.reducer;
