import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { useCallback, useState } from 'react';
import { getAssessmentDefinitionOfferedSolutions } from '../reducers/getAssessmentDefinitionOfferedSolutions';

interface assessmentDefinitionOfferedSolutionsProp {
    assessmentDefinitionOfferedSolutionsLoading: boolean;
    assessmentDefinitionOfferedSolutionsError: unknown;
    getOfferedSolutions: (assessmenDefinitionId: string) => Promise<void>;
}

const useGetAssessmentDefinitionOfferedSolutions = (): assessmentDefinitionOfferedSolutionsProp => {
    const dispatch = useDispatch<AppDispatch>();
    const [assessmentDefinitionOfferedSolutionsLoading, setLoading] = useState(true);
    const [assessmentDefinitionOfferedSolutionsError, setError] = useState<unknown>();

    const getOfferedSolutions = useCallback(async (assessmenDefinitionId: string): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            const resultAction = await dispatch(getAssessmentDefinitionOfferedSolutions(assessmenDefinitionId));
            if (getAssessmentDefinitionOfferedSolutions.fulfilled.match(resultAction)) {
                setLoading(false);
                return;
            }
            throw new Error('Failed to fetch offered solutions');
        } catch (error) {
            setError(error);
            throw error;
        } finally {
            setLoading(false)
        }
    }, [dispatch]);

    return { assessmentDefinitionOfferedSolutionsLoading, assessmentDefinitionOfferedSolutionsError, getOfferedSolutions };
};

export default useGetAssessmentDefinitionOfferedSolutions;
