import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { useCallback, useState } from 'react';
import { getAssessmentDefinitionResilienceScore } from '../reducers/getAssessmentDefinitionResilienceScore';

interface assessmentDefinitionResilienceScoreProp {
    assessmentDefinitionResilienceScoreLoading: boolean;
    assessmentDefinitionResilienceScoreError: unknown;
    getResilienceScore: (assessmenDefinitionId: string) => Promise<void>;
}

const useGetAssessmentDefinitionResilienceScore = (): assessmentDefinitionResilienceScoreProp => {
    const dispatch = useDispatch<AppDispatch>();
    const [assessmentDefinitionResilienceScoreLoading, setLoading] = useState(true);
    const [assessmentDefinitionResilienceScoreError, setError] = useState<unknown>();

    const getResilienceScore = useCallback(async (assessmenDefinitionId: string): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            const resultAction = await dispatch(getAssessmentDefinitionResilienceScore(assessmenDefinitionId));
            if (getAssessmentDefinitionResilienceScore.fulfilled.match(resultAction)) {
                setLoading(false);
                return;
            }
            throw new Error('Failed to fetch resilience score');
        } catch (error) {
            setError(error);
            throw error;
        } finally {
            setLoading(false)
        }
    }, [dispatch]);

    return { assessmentDefinitionResilienceScoreLoading, assessmentDefinitionResilienceScoreError, getResilienceScore };
};

export default useGetAssessmentDefinitionResilienceScore;
