import { useAppDispatch } from '../../../redux/store';
import { putDashboardComponentConfigThunk } from '../reducers/putDashboardComponentConfig';
import { DashboardConfigurationItem } from '../type';
import { useState } from 'react';

type putDashboardComponentConfigProp = {
    loading: boolean;
    error: unknown;
    updateDashboardComponentConfig: (dashboardComponentConfigPayload: DashboardConfigurationItem[]) => void;
};

const usePutDashboardComponentConfig = (): putDashboardComponentConfigProp => {
    const dispatch = useAppDispatch();
    const [error, setError] = useState<unknown>();
    const [loading, setLoading] = useState<boolean>(false);

    const updateDashboardComponentConfig = async (dashboardComponentConfigPayload: DashboardConfigurationItem[]) => {
        try {
            setLoading(true);
            setError(null)
            const resultAction = await dispatch(
                putDashboardComponentConfigThunk(dashboardComponentConfigPayload),
            );
            if (putDashboardComponentConfigThunk.fulfilled.match(resultAction)) {
                setLoading(false);
                return resultAction;
            }
        } catch (error) {
            setError(error);
            throw error;
        } finally {
            setLoading(false)
        }
    };
    return { error, loading, updateDashboardComponentConfig };
};

export default usePutDashboardComponentConfig;
