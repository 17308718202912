import React, { useEffect, useState } from 'react';
import {
    Form,
    FieldContainer,
    SubTitleTypography,
    TitleTypography,
} from '../../../Assessments/CreateAssessment/CreateAssessment.style';
import {
    Autocomplete,
    Avatar,
    Box,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormLabel,
    Grid,
    InputAdornment,
    MenuItem,
    Select,
    TextField,
    ThemeProvider,
} from '@mui/material';
import translation from '../../../../locales/en/translation.json';
import { CancelButton } from '../../../Accounts/CreateClient/CreateClient.styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
    Controller,
    FieldValues,
    set,
    useFieldArray,
    useForm,
} from 'react-hook-form';
import moment from 'moment';
import CloseIcon from '../../../../assets/svg-components/CloseIcon';
import {
    CustomButton,
    CustomCheckBox,
    ErrorMessage,
    Typography12px400,
    Typography14px400,
    Typography14px500,
    Typography18px500,
} from '../../../../styles/Global.styles';
import { useSelector } from 'react-redux';
import { RootStoreType } from '../../../../redux/store.type';
import { AccountDetails } from '../../../Accounts/types';
import useGetClients from '../../../Accounts/hooks/useGetClients';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useDebounce from '../../../../hooks/useDebounce';
import { store, useAppSelector } from '../../../../redux/store';
import { useFormLabelsTheme } from '../../../../hooks/useFormLabelsTheme';
import CheckIcon from '@mui/icons-material/Check';
import { CustomAccountBranding } from '../../../Branding/type';
import { UpsertAssessmentPayload, AssessmentStatus, LiteAssessmentOverviewDetails } from '../../type';
import useGetAssessmentDefinitions from '../../hooks/useGetAssessmentDefinitions';
import usePutAssessment from '../../hooks/usePutAssessment';
import useGetAccountSettingsUsers from '../../../Accounts/hooks/useGetAccountSettingsUsers';
import { clearLiteAssessmentDetails } from '../../reducers/getLiteAssessmentDetails';
import { clearClientList } from '../../../Accounts/reducers/getClientList';
import { AccountSettingsUser } from '../../../Accounts/AccountSettings/AccountSettings';
import useGetLiteAssessmentDetails from '../../hooks/useGetLiteAssessmentDetails';

interface EditAssessmentProps {
    branding: CustomAccountBranding;
}

const EditAssessment: React.FC<EditAssessmentProps> = ({ branding }) => {
    const { id } = useParams();
    const [inputValue, setInputValue] = React.useState('');
    const formLabelsTheme = useFormLabelsTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const { debounce } = useDebounce(1000);

    const accountsResponse = useGetClients();
    const putAssessmentReducer = usePutAssessment();
    const { getAccountSettingsUsers } = useGetAccountSettingsUsers();
    const { data: assessmentDefinitions, loading, error } = useGetAssessmentDefinitions();
    const getLiteAssessmentDetailsReducer = useGetLiteAssessmentDetails();

    const liteAssessmentDetail = useAppSelector((state) => {
        return state.assessmentReducers.getLiteAssessmentDetailsReducer?.lite_assessment_details as LiteAssessmentOverviewDetails;
    });

    const [accountObject, setAccountObject] = React.useState<AccountDetails | null>(null);

    const [isAutoEmailReminderActive, setIsAutoEmailReminderActive] =
        useState(false);

    const form = useForm<FieldValues & UpsertAssessmentPayload>({
        defaultValues: {
            id: undefined,
            uuid: undefined,
            name: '',
            is_deleted: false,
            start_date: null,
            end_date: null,
            target_responses: 0,
            status: AssessmentStatus.DRAFT,
            send_email_reminders: false,
            assessment_definition_id: '',
            account_id: '',
            recipient_list: [{ notify_email: '' }],
            temporary_recipient_list: [{ notify_email: '' }],
        },
    });

    const {
        register,
        formState: { errors },
        control,
        watch,
        setValue,
        handleSubmit,
        clearErrors,
    } = form;

    const assessment_definition_id = watch('assessment_definition_id');
    const recipients = watch('temporary_recipient_list'); // This will subscribe to changes of `recipients`
    const [assessmentName, setAssessmentName] = useState<string>('');
    const [targetResponses, setTargetResponses] = useState<number>(Number(liteAssessmentDetail?.target_responses ?? recipients?.length));
    const [isAnyFieldTouched, setAnyFieldTouched] = useState<boolean>(false);
    const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
    const [accountExecutives, setAccountExecutives] = useState<AccountSettingsUser[]>([]);

    const companyList = useAppSelector((state) => {
        return state.allClientReducer.clientListReducer.accountListResponse?.results;
    });

    React.useEffect(() => {
        if (id) {
            getLiteAssessmentDetailsReducer.getLiteDetails(id);
        }
    }, []);

    useEffect(() => {
        if (liteAssessmentDetail && companyList && companyList.length > 0) {
            const accountWithEvaluation = companyList.find(
                (company) => company.name === liteAssessmentDetail.account.name,
            );
            if (accountWithEvaluation) {
                getAccountSettingsUsers(accountWithEvaluation.id.toString());
                setAccountObject(accountWithEvaluation);
            }
        }
    }, [liteAssessmentDetail, companyList]);

    const { fields, append, remove } = useFieldArray<
        FieldValues & UpsertAssessmentPayload
    >({
        name: 'temporary_recipient_list',
        control,
    });

    useEffect(() => {
        if (liteAssessmentDetail) {
            accountsResponse.clientList({
                search: liteAssessmentDetail.account.name,
            });
        }
    }, [liteAssessmentDetail]);

    useEffect(() => {
        if (
            companyList &&
            companyList.length > 0 &&
            liteAssessmentDetail &&
            companyList.find(
                (company) => company.name === liteAssessmentDetail.account.name,
            )
        ) {
            setAccountObject(
                companyList.find((company) => {
                    company.name === liteAssessmentDetail.account.name;
                }) || null,
            );
        }
    }, []);

    useEffect(() => {
        if (accountObject && assessment_definition_id) {
            const nameOfAccount =
                companyList &&
                companyList.find(
                    (company) => company.id.toString() == accountObject.id.toString(),
                )?.name;
            const nameOfEvaluation =
                assessmentDefinitions &&
                assessmentDefinitions.find((definition) => definition.id == assessment_definition_id)
                    ?.name;
            if (nameOfAccount && nameOfEvaluation && isAnyFieldTouched) {
                setAssessmentName(nameOfAccount + ' ' + nameOfEvaluation);
                clearErrors('name');
            }
        }
    }, [accountObject, assessment_definition_id, setValue, clearErrors]);

    useEffect(() => {
        setValue('name', assessmentName);
    }, [assessmentName, setValue]);

    useEffect(() => {
        setValue('target_responses', Number(targetResponses));
    }, [targetResponses, setValue]);

    const onFormSubmit = (data: UpsertAssessmentPayload) => {
        setSubmitButtonClicked(true);
        if (accountObject) {
            if (data.temporary_recipient_list) {
                const emailSet = new Set(data.temporary_recipient_list.map(recipient => recipient.notify_email));
                if (emailSet.size !== data.temporary_recipient_list.length) {
                    alert('Duplicate emails found! Please remove one of the duplicate emails and try again.');
                    return;
                }
            }
            const payload: UpsertAssessmentPayload = {
                ...data,
                recipient_list: data.temporary_recipient_list,
                start_date: data.start_date
                    ? moment(data.start_date, 'MM/DD/YYYY', true).format('YYYY-MM-DD')
                    : null,
                end_date: data.end_date
                    ? moment(data.end_date, 'MM/DD/YYYY', true).format('YYYY-MM-DD')
                    : null,
                account_id: accountObject.id.toString(),
            };
            if (!putAssessmentReducer.loading) {
                putAssessmentReducer.updateAssessment(payload);
            }
        }
    };

    const [selectedEvaluationId, setSelectedEvaluationId] = useState<number>(0);

    const allAccountUsers = useSelector((state: RootStoreType) => {
        return state.allClientReducer?.accountSettingReducer?.accountSetting;
    });

    useEffect(() => {
        if (allAccountUsers) {
            setAccountExecutives(
                allAccountUsers.filter(
                    (user) => user.role == 'account_executive',
                )
            )
        }
    }, [allAccountUsers]);

    useEffect(() => {
        if (selectedEvaluationId) {
            setValue('assessment_definition_id', selectedEvaluationId);
            clearErrors('assessment_definition_id');
        }
    }, [selectedEvaluationId]);

    useEffect(() => {
        if (liteAssessmentDetail) {
            setValue('id', liteAssessmentDetail.id);
            setValue('uuid', liteAssessmentDetail.uuid);
            setValue('assessment_definition_id', liteAssessmentDetail.assessment_definition.id);
            setValue('start_date', liteAssessmentDetail.start_date ? moment(liteAssessmentDetail.start_date) : null);
            setValue('end_date', liteAssessmentDetail.end_date ? moment(liteAssessmentDetail.end_date) : null);
            setValue('name', liteAssessmentDetail.name);
            setValue('status', liteAssessmentDetail.status);
            setIsAutoEmailReminderActive(liteAssessmentDetail.send_email_reminders);
            setValue('account', accountObject || null);
            setAssessmentName(liteAssessmentDetail.name);
            setTargetResponses(liteAssessmentDetail.target_responses);
            const allRecipients = liteAssessmentDetail.assessment_recipients?.map(
                (eachRecipient) => {
                    return { notify_email: eachRecipient.email };
                },
            );
            setValue('temporary_recipient_list', allRecipients?.length > 0 ? allRecipients : [{ notify_email: '' }]);
        }
    }, [liteAssessmentDetail, accountObject]);

    const assessmentIsRequired: string = branding?.evaluation_property_name_translation ?? "Evaluation" + " is required.";
    const assessmentTypeIsRequired: string = branding?.evaluation_property_name_translation ?? "Evaluation" + " type is required.";

    return (
        <>
            <Box>{liteAssessmentDetail && liteAssessmentDetail.name}</Box>
            {error ||
                loading ||
                !companyList ||
                !assessmentDefinitions ||
                !liteAssessmentDetail ? (
                <CircularProgress sx={{ margin: '20vh 48%' }} />
            ) : (
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                    <ThemeProvider theme={formLabelsTheme}>
                        <Grid
                            container
                            sx={{
                                flexWrap: 'wrap-reverse',
                            }}
                        >
                            <Grid item sm={7} xs={10}>
                                <TitleTypography marginBottom={'34px'}>
                                    {translation.general_info}
                                </TitleTypography>
                                <Box sx={{ marginBottom: '70px' }}>
                                    <FieldContainer>
                                        <FormLabel required>{translation.account_name}</FormLabel>
                                        <Autocomplete
                                            id="free-solo-demo"
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    padding: '0',
                                                },
                                            }}
                                            value={accountObject}
                                            onChange={(_, newAccount) => {
                                                if (newAccount) {
                                                    setAnyFieldTouched(true);
                                                    setAccountObject(newAccount);
                                                    getAccountSettingsUsers(newAccount.id.toString());
                                                }
                                            }}
                                            inputValue={inputValue}
                                            onInputChange={(_, newInputValue) => {
                                                if (!newInputValue) {
                                                    setAccountObject(null);
                                                }
                                                debounce(() => {
                                                    accountsResponse.clientList({
                                                        search: newInputValue,
                                                    });
                                                });
                                                setInputValue(newInputValue);
                                            }}
                                            disablePortal
                                            options={companyList}
                                            getOptionLabel={(option) => option.name}
                                            isOptionEqualToValue={(opt, val) => {
                                                return opt.id === val.id;
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Search Account By Name"
                                                />
                                            )}
                                        />

                                        {!accountObject && submitButtonClicked && (
                                            <ErrorMessage>
                                                {translation.account_name_is_required}
                                            </ErrorMessage>
                                        )}
                                    </FieldContainer>
                                </Box>

                                <TitleTypography marginBottom={'34px'}>
                                    {translation.configuration}
                                </TitleTypography>
                                <Box>
                                    <FieldContainer>
                                        <FormLabel required>
                                            {branding?.evaluation_property_name_translation} Type
                                        </FormLabel>
                                        <Controller
                                            name="assessment_definition_id"
                                            control={control}
                                            rules={{
                                                required: assessmentTypeIsRequired,
                                            }}
                                            render={({ field }) => (
                                                <Select
                                                    size="small"
                                                    displayEmpty
                                                    defaultValue={field.value || ''}
                                                    placeholder={translation.select_an_option}
                                                    {...field}
                                                    onChange={(event) => {
                                                        setAnyFieldTouched(true);
                                                        setSelectedEvaluationId(Number(event.target.value));
                                                    }}
                                                >
                                                    {assessmentDefinitions &&
                                                        assessmentDefinitions.map((definition) => {
                                                            return (
                                                                <MenuItem key={definition.id} value={definition.id}>
                                                                    {definition.name}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                </Select>
                                            )}
                                        />
                                        {errors.assessment_definition_id && (
                                            <ErrorMessage>
                                                {errors.assessment_definition_id.message?.toString()}
                                            </ErrorMessage>
                                        )}
                                    </FieldContainer>
                                </Box>

                                <Box>
                                    <FieldContainer>
                                        <FormLabel required>
                                            Title / Name
                                        </FormLabel>
                                        <Controller
                                            name="name"
                                            control={control}
                                            rules={{
                                                required: assessmentIsRequired,
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    size="small"
                                                    {...field}
                                                    value={assessmentName}
                                                    onChange={(event) => {
                                                        field.onChange(event);
                                                        setAssessmentName(event.target.value);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.name && (
                                            <ErrorMessage>
                                                {errors.name.message?.toString()}
                                            </ErrorMessage>
                                        )}
                                    </FieldContainer>
                                </Box>

                                <Box>
                                    <FieldContainer>
                                        <FormLabel>{translation.start_date}</FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Controller
                                                control={control}
                                                name="start_date"
                                                render={({ field }) => (
                                                    <DatePicker sx={{ height: '50px' }} {...field} />
                                                )}
                                            />
                                        </LocalizationProvider>
                                        {errors.start_date && (
                                            <ErrorMessage>
                                                {errors.start_date.message?.toString()}
                                            </ErrorMessage>
                                        )}
                                    </FieldContainer>
                                </Box>

                                <Box marginBottom={'70px'}>
                                    <FieldContainer>
                                        <FormLabel>{translation.end_date}</FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <Controller
                                                control={control}
                                                name="end_date"
                                                render={({ field }) => (
                                                    <DatePicker sx={{ height: '50px' }} {...field} />
                                                )}
                                            />
                                        </LocalizationProvider>
                                        {errors.end_date && (
                                            <ErrorMessage>
                                                {errors.end_date.message?.toString()}
                                            </ErrorMessage>
                                        )}
                                    </FieldContainer>
                                </Box>
                            </Grid>
                            <Grid item sm={5} xs={10}>
                                {allAccountUsers &&
                                    (accountExecutives.length < 1 ||
                                        accountExecutives.filter(
                                            (user) => user.fullname == null,
                                        ).length == accountExecutives.length) ? (
                                    <Typography14px500
                                        sx={{
                                            color: '#B5B5C3',
                                            paddingBottom: '20px',
                                        }}
                                    >
                                        No {branding.account_executive_name_translation} has been assigned.
                                    </Typography14px500>
                                ) : (
                                    allAccountUsers &&
                                    allAccountUsers
                                        .filter((user) => user.role == 'account_executive')
                                        .map((user) => {
                                            return (
                                                <React.Fragment key={user.id}>
                                                    <Box marginBottom={'10px'}>
                                                        <Typography12px400
                                                            sx={{
                                                                color: '#181C32',
                                                            }}
                                                        >
                                                            {branding.account_executive_name_translation}
                                                        </Typography12px400>
                                                    </Box>

                                                    <Box
                                                        key={user.id}
                                                        sx={{
                                                            display: 'flex',
                                                            gap: '20px',
                                                            margin: '15px 0',
                                                        }}
                                                    >
                                                        <Avatar
                                                            sx={{
                                                                height: '50px',
                                                                width: '50px',
                                                            }}
                                                        >
                                                            {user.fullname[0]}
                                                        </Avatar>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                gap: '20px',
                                                                margin: '15px 0',
                                                            }}>
                                                            <Typography18px500
                                                                sx={{
                                                                    color: '#464E5F',
                                                                    paddingBottom: '10px',
                                                                }}
                                                            >
                                                                {user.fullname}
                                                            </Typography18px500>
                                                        </Box>
                                                    </Box>
                                                </React.Fragment>
                                            );
                                        })
                                )}

                                {allAccountUsers &&
                                    allAccountUsers
                                        .filter((user) => user.role == 'support')
                                        .map((user, index) => {
                                            return (
                                                user.role == 'support' && (
                                                    <React.Fragment key={user.id}>
                                                        {index == 0 && (
                                                            <Box marginBottom={'10px'}>
                                                                <Typography12px400
                                                                    sx={{
                                                                        color: '#181C32',
                                                                    }}
                                                                >
                                                                    {translation.support}
                                                                </Typography12px400>
                                                            </Box>
                                                        )}

                                                        <Box
                                                            key={user.id}
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                gap: '20px',
                                                                margin: '15px 0',
                                                            }}
                                                        >
                                                            <Avatar
                                                                sx={{
                                                                    height: '50px',
                                                                    width: '50px',
                                                                }}
                                                            >
                                                                {user.fullname[0]}
                                                            </Avatar>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    gap: '20px',
                                                                    margin: '15px 0',
                                                                }}>
                                                                <Typography18px500
                                                                    sx={{
                                                                        color: '#464e5f',
                                                                        paddingBottom: '10px',
                                                                    }}
                                                                >
                                                                    {user.fullname}
                                                                </Typography18px500>
                                                            </Box>
                                                        </Box>
                                                    </React.Fragment>
                                                )
                                            );
                                        })}
                            </Grid>
                        </Grid>

                        <Box marginBottom={'70px'}>
                            <TitleTypography>{translation.direct_recipient}</TitleTypography>
                            <SubTitleTypography marginBottom={'25px'}>
                                {translation.evaluation_direct_recipient_subtext}
                            </SubTitleTypography>
                            <FormLabel>{translation.user_email}</FormLabel>
                            {fields.map((field, index) => {
                                return (
                                    <div key={field.id}>
                                        <FieldContainer sx={{ marginBottom: '10px' }}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '0 5px',
                                                }}
                                            >
                                                <TextField
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    size="small"
                                                    sx={{ width: '75%' }}
                                                    {...register(`temporary_recipient_list.${index}.notify_email`, {
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: translation.invalid_email,
                                                        },
                                                    })}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {index != 0 && (
                                                                    <Box
                                                                        component={'span'}
                                                                        onClick={() => {
                                                                            remove(index);
                                                                            setTargetResponses((prev) => Math.max(0, prev - 1));
                                                                        }}
                                                                        sx={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    >
                                                                        <CloseIcon />
                                                                    </Box>
                                                                )}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Box>
                                            {errors.temporary_recipient_list &&
                                                errors.temporary_recipient_list[index]?.notify_email && (
                                                    <ErrorMessage>
                                                        {errors.temporary_recipient_list[
                                                            index
                                                        ]?.notify_email?.message?.toString()}
                                                    </ErrorMessage>
                                                )}
                                        </FieldContainer>
                                        {index === fields.length - 1 && (
                                            <Typography14px400
                                                marginLeft={'5px'}
                                                sx={{
                                                    color: '#1C1C1C',
                                                    lineHeight: '20px',
                                                    marginLeft: '5px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    append({ notify_email: '' });
                                                    setTargetResponses((Number(targetResponses) + 1));
                                                }}
                                            >
                                                + Add Recipient
                                            </Typography14px400>
                                        )}
                                    </div>
                                );
                            })}
                        </Box>

                        <Box>
                            <FieldContainer>
                                <FormLabel>
                                    {translation.target_responses}
                                </FormLabel>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    type="number"
                                    size="small"
                                    sx={{ width: '58.5%' }}
                                    inputProps={{
                                        min: 0,
                                        onKeyDown: (e) => {
                                            if (e.key === '-' || e.key === 'e') {
                                                e.preventDefault();
                                            }
                                        },
                                    }}
                                    {...register(`target_responses`, {
                                        setValueAs: (value) => {
                                            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                                            if (value === "" || isNaN(value)) return 0;
                                            return Number(value);
                                        },
                                        pattern: {
                                            value: /^\d+$/,
                                            message: 'Only positive numbers are allowed.',
                                        },
                                    })}
                                    value={targetResponses} // Ensure it stays in sync with state
                                    onBlur={(event) => {
                                        let value = event.target.value;

                                        if (value === "") {
                                            value = "0"; // Default to 0 if empty
                                        } else {
                                            value = String(Number(value)); // Remove leading zeros
                                        }

                                        setValue("target_responses", Number(value));
                                        setTargetResponses(Number(value)); // Update state
                                        event.target.value = value;
                                    }}
                                    onChange={(event) => {
                                        let value = event.target.value;

                                        // Prevents non-numeric input
                                        if (!/^\d*$/.test(value)) return;

                                        // Remove leading zeros
                                        value = value.replace(/^0+/, "");

                                        setTargetResponses(Number(value)); // Sync state
                                        event.target.value = value;
                                    }}
                                />
                                {errors.target_responses && (
                                    <ErrorMessage>
                                        {errors.target_responses.message?.toString()}
                                    </ErrorMessage>
                                )}
                            </FieldContainer>
                        </Box>

                        <Box>
                            <TitleTypography>{translation.reminder}</TitleTypography>
                            <SubTitleTypography marginBottom={'25px'}>
                                {translation.evaluation_reminder_subtext}
                            </SubTitleTypography>
                            <FieldContainer>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            icon={<CustomCheckBox />}
                                            checkedIcon={
                                                <CustomCheckBox
                                                    sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            fill: 'black',
                                                            width: '14px',
                                                            height: '14px',
                                                        },
                                                    }}
                                                >
                                                    <CheckIcon />
                                                </CustomCheckBox>
                                            }
                                            {...register('is_auto_email_reminder_active')}
                                            size="small"
                                            checked={isAutoEmailReminderActive}
                                            onChange={() =>
                                                setIsAutoEmailReminderActive(!isAutoEmailReminderActive)
                                            }
                                        />
                                    }
                                    sx={{
                                        fontSize: '10px',
                                    }}
                                    label={
                                        <FormLabel>{translation.send_reminder_email}</FormLabel>
                                    }
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <FormLabel>{translation.days_before_close_date}</FormLabel>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    type="number"
                                    size="small"
                                    sx={{ width: '58.5%' }}
                                    {...register(`days_before_close_date`, {
                                        pattern: {
                                            value: /^\d*\d+$/,
                                            message: 'Only positive numbers are allowed.',
                                        },
                                    })}
                                />
                                {errors.days_before_close_date && (
                                    <ErrorMessage>
                                        {errors.days_before_close_date.message?.toString()}
                                    </ErrorMessage>
                                )}
                            </FieldContainer>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                paddingBottom: '20px',
                            }}
                        >
                            <CustomButton
                                sx={{
                                    width: '150px',
                                }}
                                type="submit"
                                variant="contained"
                                size="medium"
                            >
                                Update
                            </CustomButton>
                            <CancelButton
                                sx={{
                                    width: '150px',
                                    marginLeft: '12px',
                                }}
                                onClick={() => {
                                    store.dispatch(clearLiteAssessmentDetails());
                                    store.dispatch(clearClientList());
                                    navigate(-1);
                                }}
                            >
                                Cancel
                            </CancelButton>
                        </Box>
                    </ThemeProvider>
                </Form>
            )}
        </>
    );
};

export default EditAssessment;
