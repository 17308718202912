import React from 'react';

interface FilterIconProps {
    width?: string;
    height?: string;
    color?: string;
}

function FilterIcon({
    width = '12px',
    height = '13px',
    color = 'none',
}: FilterIconProps) {
    return (
        <svg width={width} height={height} viewBox="0 0 12 13" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path d="M8.03085 11.9053C8.05752 12.1053 7.99085 12.3186 7.83752 12.4586C7.77584 12.5204 7.70258 12.5695 7.62193 12.6029C7.54128 12.6364 7.45483 12.6536 7.36752 12.6536C7.2802 12.6536 7.19375 12.6364 7.1131 12.6029C7.03245 12.5695 6.95919 12.5204 6.89752 12.4586L4.22418 9.78531C4.15151 9.7142 4.09626 9.62726 4.06272 9.53128C4.02919 9.4353 4.01828 9.33287 4.03085 9.23198V5.81864L0.837516 1.73198C0.729255 1.593 0.680405 1.41682 0.701641 1.24193C0.722877 1.06705 0.812472 0.907675 0.950849 0.798644C1.07752 0.705311 1.21752 0.651978 1.36418 0.651978H10.6975C10.8442 0.651978 10.9842 0.705311 11.1109 0.798644C11.2492 0.907675 11.3388 1.06705 11.3601 1.24193C11.3813 1.41682 11.3324 1.593 11.2242 1.73198L8.03085 5.81864V11.9053ZM2.72418 1.98531L5.36418 5.35864V9.03864L6.69752 10.372V5.35198L9.33752 1.98531H2.72418Z" fill="#2E2E2E" />
        </svg>
    );
}

export default FilterIcon;
