export const COMPANY_SIZE_RANGES = {
  SMALL: { max: 50, label: 'Small Manufacturer' },
  MEDIUM: { min: 51, max: 500, label: 'Medium Manufacturer' },
  LARGE: { min: 501, label: 'Large Manufacturer' },
};

export const getCompanySizeLabel = (employeeCount: number | string | null | undefined): string => {
  if (employeeCount === null || employeeCount === undefined) return 'Unknown';
  
  const count = typeof employeeCount === 'string' ? parseInt(employeeCount, 10) : employeeCount;
  
  if (isNaN(count)) return 'Unknown';
  
  if (count <= COMPANY_SIZE_RANGES.SMALL.max) {
    return COMPANY_SIZE_RANGES.SMALL.label;
  } else if (count <= COMPANY_SIZE_RANGES.MEDIUM.max) {
    return COMPANY_SIZE_RANGES.MEDIUM.label;
  } else {
    return COMPANY_SIZE_RANGES.LARGE.label;
  }
};

export const formatCompanySize = (employeeCount: number | string | null | undefined): string => {
  if (employeeCount === null || employeeCount === undefined) return '';
  
  const count = typeof employeeCount === 'string' ? parseInt(employeeCount, 10) : employeeCount;
  
  if (isNaN(count)) return '';
  
  const label = getCompanySizeLabel(count);
  return `${label} (${count} Employees)`;
};
