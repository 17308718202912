import React, { memo, useEffect, useLayoutEffect, useRef } from 'react';
import { GridStack, GridStackNode } from 'gridstack';
import { CustomAccountBranding } from '../../Branding/type';
import { LoginResponse } from '../../Auth/reducers/authenticateUser';
import { DashboardComponent, DashboardConfigurationItem, DashboardConfigurationItemComponent } from '../type';
// import 'gridstack/dist/h5/gridstack-dd-native';
import 'gridstack/dist/gridstack.min.css';
import './GridStack.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import usePutDashboardComponentConfig from '../hooks/usePutDashboardComponentConfig';
import ComponentMap from './components/DynamicComponentMapping/ComponentMap';
import ComponentDataStoreMap from './components/DynamicComponentMapping/ComponentDataStoreMap';
import { RootState, store, useAppSelector } from '../../../redux/store';
import { AssessmentDefinitionResponse } from '../../Assessments/type';
import { useSelector } from 'react-redux';

interface GridStackComponentProps {
    branding: CustomAccountBranding;
    loggedInUser: LoginResponse;
    dashboardConfiguration: DashboardConfigurationItemComponent[] | null;
    selectedAssessmentDefinition: AssessmentDefinitionResponse;
    setDashboardConfigCopy: React.Dispatch<React.SetStateAction<DashboardConfigurationItemComponent[] | null>>;
}

const GridStackComponent: React.FC<GridStackComponentProps> = memo(({ branding, loggedInUser, dashboardConfiguration, selectedAssessmentDefinition, setDashboardConfigCopy }) => {
    const navigate = useNavigate();
    const gridRef = useRef<HTMLDivElement>(null);
    const componentRoots = useRef<Map<string, ReactDOM.Root>>(new Map());
    const isRendering = useRef(false);
    const question_response_details = useSelector((state: RootState) =>
        state.assessmentReducers.getAssessmentQuestionResponseDetailsReducer.question_response_details
    );

    const { updateDashboardComponentConfig } = usePutDashboardComponentConfig();

    useEffect(() => {
        if (!gridRef.current || !dashboardConfiguration || !selectedAssessmentDefinition) return;

        isRendering.current = true;

        const grid = GridStack.init(
            {
                // column: 12, // Set the number of columns
                float: true, // Enable floating widgets
                cellHeight: 100, // Height of each cell (affects row sizing)
                minRow: 1, // Minimum number of rows
            },
            gridRef.current
        );

        grid.removeAll();
        componentRoots.current.forEach((root) => root.unmount());
        componentRoots.current.clear();

        dashboardConfiguration
            .filter(item => item.assessment_definition_uuid === selectedAssessmentDefinition.uuid)
            .forEach((item) => {
                const widget = document.createElement('div');
                widget.innerHTML = `<div class="grid-stack-item-content"></div>`;
                widget.className = 'grid-stack-item';
                widget.setAttribute('gs-w', String(item.col_span || 1));
                widget.setAttribute('gs-h', String(item.row_span || 1));
                widget.setAttribute('gs-x', String(item.col_offset || 0));
                widget.setAttribute('gs-y', String(item.row_offset || 0));
                widget.setAttribute('component-config-id', String(item.id));

                // Add widget to the grid
                grid.makeWidget(widget);

                // Render the actual component inside the widget
                const contentDiv = widget.querySelector('.grid-stack-item-content');
                if (contentDiv) {
                    contentDiv.classList.add(`grid-stack-item-content-component-key-${String(item.component.key)}`);

                    const ComponentToRender = ComponentMap[item.component.key];
                    let componentData: any = null;

                    if (item.component.question) {
                        componentData = question_response_details[item.component.question] ? question_response_details[item.component.question] : null;

                    } else {
                        const selectComponentData = ComponentDataStoreMap.get(ComponentToRender);
                        componentData = selectComponentData ? selectComponentData(store.getState()) : null;
                    }

                    if (ComponentToRender) {
                        const wrapper = document.createElement('div');
                        wrapper.setAttribute('style', 'width: 100%; height: 100%');
                        contentDiv.appendChild(wrapper);

                        // Create a new React root for this component
                        const root = ReactDOM.createRoot(wrapper);
                        componentRoots.current.set(item.component.key, root);

                        root.render(
                            <BrowserRouter>
                                <ComponentToRender
                                    key={item.component.id}
                                    title={item.component.title}
                                    description={item.component.description}
                                    branding={branding}
                                    navigate={navigate}
                                    data={componentData}
                                    selectedAssessmentDefinition={selectedAssessmentDefinition}
                                />
                            </BrowserRouter>
                        );
                    }
                }
            });

        setTimeout(() => {
            isRendering.current = false;
        }, 0);

        // Listen for changes in widget positions/sizes
        grid.on('change', (event, items) => {
            const updatedItems: DashboardConfigurationItem[] = items.map((node: GridStackNode) => {
                const id = node.el?.getAttribute('component-config-id');
                if (!id) return null; // Skip if the id is null or undefined
                return {
                    id: id,
                    col_span: node.w || 1,
                    row_span: node.h || 1,
                    col_offset: node.x || 0,
                    row_offset: node.y || 0,
                };
            }).filter((update): update is DashboardConfigurationItem => update !== null); // Remove any null values

            // Update local state while keeping other properties unchanged
            setDashboardConfigCopy(prevConfig => {
                if (!prevConfig) return null;
                return prevConfig.map(item => {
                    const updatedItem = updatedItems.find(u => u?.id === item.id);
                    return updatedItem ? { ...item, ...updatedItem } : item;
                });
            });

            updateDashboardComponentConfig(updatedItems as DashboardConfigurationItem[]);
        });


        // Cleanup on unmount
        return () => {
            if (!isRendering.current) {
                grid.destroy(false); // Destroy grid instance
                setTimeout(() => {
                    componentRoots.current.forEach((root) => root.unmount());
                    componentRoots.current.clear();
                }, 0);
            }
        };
    }, []);


    if (!dashboardConfiguration) {
        return <div>No configuration found for this user.</div>
    }

    return <div className="grid-stack" ref={gridRef} style={{ minHeight: '500px' }} />;
});

GridStackComponent.displayName = "Gridstack";

export default GridStackComponent;
