import React, { useState } from 'react';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import MegaphoneIcon from '../../../../assets/svg-components/MegaphoneIcon';
import CopyIcon from '../../../../assets/svg-components/CopyIcon';
import { AssessmentDefinitionSolutions } from '../../type';

interface MostFrequentlyOfferedSolutionsProps {
    title: string;
    data: AssessmentDefinitionSolutions | null;
}

const MostFrequentlyOfferedSolutions: React.FC<MostFrequentlyOfferedSolutionsProps> = ({ title, data }) => {
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        const textToCopy = data?.solutions.map((solution, index) => `${index + 1}. ${solution}`).join("\n");
        navigator.clipboard.writeText(textToCopy as string);

        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    if (!data) {
        return <Box display="flex" justifyContent="center" alignItems="center" minHeight="100%">
            <CircularProgress />
        </Box>
    }

    return (
        <Grid container
            sx={{
                minHeight: '100%',
                display: 'flex'
            }}>
            <Grid item md={12}
                sx={{
                }}>
                <Typography
                    sx={{
                        fontFamily: 'Inter',
                        fontSize: '18px',
                        fontWeight: 600,
                        fontColor: '#FFFFFF',
                        lineHeight: '22px',
                        paddingBottom: '10px',
                        paddingTop: "24px",
                    }}>
                    Most Frequently Offered Solutions
                </Typography>
                <Box
                    sx={{
                        border: 'none',
                        borderTop: '1px solid white',
                        width: '100%',
                        // margin: '10px 0'
                    }}
                />
            </Grid>
            <Grid item md={12}
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Box sx={{
                    maxWidth: '25%'
                }}>
                    <MegaphoneIcon />
                </Box>
            </Grid>
            <Grid item md={12}
                sx={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                <Typography
                    sx={{
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontWeight: 500,
                        fontColor: '#FFFFFF',
                        lineHeight: '19px',
                        // paddingBottom: '10px'
                    }}>
                    These solutions have been offered <strong>{data.offering_frequency_count}</strong> times across <strong>{data.assessment_count}</strong> assessments.
                </Typography>
            </Grid>
            <Grid item md={12}
                sx={{

                }}>
                <Box sx={{
                    fontFamily: 'Inter',
                    backgroundColor: "white",
                    color: "black",
                    borderRadius: "8px",
                    padding: "20px",
                    textAlign: "left",
                }}>
                    <Typography
                        sx={{
                            fontFamily: 'Inter',
                            fontColor: '#434343',
                            fontSize: '18px',
                            fontWeight: 600,
                            lineHeight: '22px',
                            borderBottom: "1px solid black",
                            paddingBottom: "9px",
                            paddingLeft: "10px"
                        }}>
                        Solutions
                    </Typography>
                    <Box
                        component="ol"
                        sx={{
                            fontFamily: 'Inter',
                            fontColor: '#434343',
                            paddingLeft: '20px',  // Indentation of the ordered list
                            marginTop: '10px',    // Space between the title and the list
                            fontSize: '16px',
                            fontWeight: 500,
                            lineHeight: '19px',
                        }}
                    >
                        {data.solutions.map((solution, index) => (
                            <Box
                                component="li"
                                key={index}
                                sx={{
                                    marginBottom: '5px',  // Space between list items
                                }}
                            >
                                {solution}
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Grid>
            <Grid item md={12}
                sx={{

                }}>
                <Button
                    sx={{
                        fontFamily: 'Inter',
                        fontWeight: 500,
                        fontSize: '12px',
                        lineHeight: '15px',
                        color: copied ? "#FFFFFF" : "#5E6C84",
                        backgroundColor: copied ? "#00B050" : "#E9EFFF",
                        border: "none",
                        padding: "8px 16px",
                        gap: '4px',
                        borderRadius: "4px",
                        cursor: "pointer",
                        marginTop: "15px",
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: copied ? "#019946" : "#cfd3e0",
                        },
                    }}
                    onClick={handleCopy}
                >
                    <CopyIcon width="13" height="16" color={copied ? "#FFFFFF" : "#5E6C84"} />
                    {copied ? "Copied!" : "Copy"}
                </Button>
            </Grid>
        </Grid>
    );
};

export default MostFrequentlyOfferedSolutions;
