import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import { useCallback, useState } from 'react';
import { getAssessmentQuestionResponseDetails } from '../reducers/getAssessmentQuestionResponseDetails';
import { RequestStatus } from '../../../types/request';

interface assessmentQuestionResponseDetailsProp {
    assessmentQuestionResponseDetailsLoading: (questionId: number) => boolean;
    assessmentQuestionResponseDetailsError: (questionId: number) => unknown;
    getQuestionResponseDetails: (assessmentDefinitionId: string, assessmenQuestionId: number) => Promise<void>;
}

const useGetAssessmentQuestionResponseDetails = (): assessmentQuestionResponseDetailsProp => {
    const dispatch = useDispatch<AppDispatch>();

    // Select multiple responses and their statuses from Redux
    // const questionResponses = useSelector((state: RootState) => state.assessmentReducers.getAssessmentQuestionResponseDetailsReducer.question_response_details);
    const questionStatus = useSelector((state: RootState) => state.assessmentReducers.getAssessmentQuestionResponseDetailsReducer.status);

    const getQuestionResponseDetails = useCallback(async (assessmentDefinitionId: string, assessmenQuestionId: number): Promise<void> => {
        try {
            await dispatch(getAssessmentQuestionResponseDetails({ assessmentDefinitionId, questionId: assessmenQuestionId }));
        } catch (error) {
            console.error(`Error fetching details for question ${assessmenQuestionId}:`, error);
        }
    }, [dispatch]);

    return {
        assessmentQuestionResponseDetailsLoading: (questionId: number) => questionStatus[questionId] === RequestStatus.Loading,
        assessmentQuestionResponseDetailsError: (questionId: number) => questionStatus[questionId] === RequestStatus.Error,
        getQuestionResponseDetails,
    };
};

export default useGetAssessmentQuestionResponseDetails;
