import { useState } from "react";
import { useAppDispatch } from "../../../redux/store";
import { deleteDashboardConfigurationThunk } from "../reducers/deleteDashboardConfiguration";

interface deleteDashboardConfigurationProp {
  error: unknown;
  loading: boolean;
  deleteDashboardConfiguration: (assessmenDefinitionId: string) => Promise<void>;
}

const useDeleteDashboardConfiguration = (): deleteDashboardConfigurationProp => {
  const dispatch = useAppDispatch();
  const [error, setError] = useState<unknown>();
  const [loading, setLoading] = useState<boolean>(false);

  const deleteDashboardConfiguration = async (assessmenDefinitionId: string): Promise<void> => {
    setLoading(true)
    setError(null)
    try {
      const resultAction = await dispatch(deleteDashboardConfigurationThunk(assessmenDefinitionId));
      if (deleteDashboardConfigurationThunk.fulfilled.match(resultAction)) {
        setLoading(false)
      }
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setLoading(false)
    }
  }
  return { loading, error, deleteDashboardConfiguration }
}

export default useDeleteDashboardConfiguration