const regionalManuAssoc = [
  { id: 0, name: "None - N/A" },
  { id: 1, name: "Bay Area Manufacturers Association (BAMA)" },
  { id: 2, name: "Big Bend Manufacturers Association (BBMA)" },
  { id: 3, name: "Central Gulf Industrial Alliance (CGIA)" },
  { id: 4, name: "First Coast Manufacturers Association (FCMA)" },
  { id: 5, name: "Manufacturers Association of Central Florida (MACF)" },
  { id: 6, name: "Manufacturers Association of the Space Coast (MASC)" },
  { id: 7, name: "Manufacturing & Supply Chain Alliance of Mid Florida (MSCA)" },
  { id: 8, name: "Mid-FL Regional Manufacturers Association (MRMA)" },
  { id: 9, name: "Natures Coast Manufacturers Association (NCMA)" },
  { id: 10, name: "Sarasota-Manatee Area Manufacturers Association (SAMA)" },
  { id: 11, name: "South Florida Manufacturers Association (SFMA)" },
  { id: 12, name: "Southwest Regional Manufacturers Association (SRMA)" },
  { id: 13, name: "Technology Coast Manufacturing and Engineering Network (TecMEN)" },
  { id: 14, name: "Treasure Coast Manufacturers Association (TCMA)" },
  { id: 15, name: "Volusia Manufacturers Association (VMA)" }
];

export default regionalManuAssoc;
