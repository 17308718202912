import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { useCallback, useState } from 'react';
import { getAssessmentDefinitionQuestionScores } from '../reducers/getAssessmentDefinitionQuestionScores';

interface assessmentDefinitionQuestionScoresProp {
    assessmentDefinitionQuestionScoresLoading: boolean;
    assessmentDefinitionQuestionScoresError: unknown;
    getQuestionScores: (assessmenDefinitionId: string) => Promise<void>;
}

const useGetAssessmentDefinitionQuestionScores = (): assessmentDefinitionQuestionScoresProp => {
    const dispatch = useDispatch<AppDispatch>();
    const [assessmentDefinitionQuestionScoresLoading, setLoading] = useState(true);
    const [assessmentDefinitionQuestionScoresError, setError] = useState<unknown>();

    const getQuestionScores = useCallback(async (assessmenDefinitionId: string): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            const resultAction = await dispatch(getAssessmentDefinitionQuestionScores(assessmenDefinitionId));
            if (getAssessmentDefinitionQuestionScores.fulfilled.match(resultAction)) {
                setLoading(false);
                return;
            }
            throw new Error('Failed to fetch question scores');
        } catch (error) {
            setError(error);
            throw error;
        } finally {
            setLoading(false)
        }
    }, [dispatch]);

    return { assessmentDefinitionQuestionScoresLoading, assessmentDefinitionQuestionScoresError, getQuestionScores };
};

export default useGetAssessmentDefinitionQuestionScores;
