import React, { useState } from 'react';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { MultipleChoiceDistributionChartData } from '../../../type';
import { AssessmentDefinitionResponse, AssessmentQuestionResponseDetails } from '../../../../Assessments/type';
import { NavigateFunction } from 'react-router-dom';
import { CustomAccountBranding } from '../../../../Branding/type';
import ResultsTable from './ResultsTable';
import QuestionComponentBadge from '../../../../../assets/svg-components/QuestionComponentBadge';

interface ResponseDistributionChartProps {
  title: string;
  description: string;
  navigate: NavigateFunction;
  data: AssessmentQuestionResponseDetails;
  selectedAssessmentDefinition: AssessmentDefinitionResponse;
}

const ProgressBar: React.FC<{ percent: number; color?: string; height?: string }> = ({
  percent,
  color = "#4a90e2", // Default color
  height = "8px",   // Default height
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#f0f0f0',
        borderRadius: '8px',
        height: '8px',
        overflow: 'hidden',
        marginTop: '1em',
      }}
      style={{ height }}>
      <Box
        sx={{
          height: '100%',
          backgroundColor: '#4a90e2',
          borderRadius: '8px',
          transition: 'width 0.3s ease',
        }}
        style={{
          width: `${percent}%`,
          backgroundColor: color,
        }} />
    </Box>
  );
};

const ResponseDistributionChart: React.FC<ResponseDistributionChartProps> = ({ title, description, navigate, data, selectedAssessmentDefinition }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  if (!data || !data.multiple_choice_answer_distribution?.length) {
    return <Box>No Data</Box>;
  }

  const colorPalette = ["#53c6fd", "#394e80", "#c46dfa", "#c89430", "#FCE762", "#823329", "#56E39F", "#823329", "#1F0322"];

  return (
    <>
      {/* Grid Container */}
      <Grid container
        sx={{
          minHeight: '100%',
          width: '100%',
          overflow: 'auto',
          padding: "24px",
        }}>
        <Grid item md={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '1em',
          }}>
          {/* Question Image */}
          <QuestionComponentBadge />
          {/* Question Mark Image */}
          <Tooltip
            title="Click to View Individual Responses"
            arrow
          >
            <span
              role="button"
              tabIndex={0}
              onClick={handleOpenModal}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleOpenModal();
                }
              }}
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
                fontWeight: 600,
                color: "#2E3645",
                fontSize: "16px",
                fontStyle: "normal",
                lineHeight: "150%",
              }}
            >
              View all
            </span>
          </Tooltip>
        </Grid>
        <Grid item md={12}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            // marginBottom: '1em',
            width: '100%',
            gap: '0.5em',
          }}>
          <Typography
            sx={{
              color: '#2E3645',
              fontSize: '1em',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: 'normal',
              marginBottom: '1em',
            }}>
            {data.question_text}
          </Typography>
        </Grid>
        {/* Dynamic Bar Chart Rendering */}
        {data.multiple_choice_answer_distribution.map((entry, index) => {
          const percent = (entry.answer_response_count / data.total_response_count) * 100;
          const color = colorPalette[index % colorPalette.length];

          return (
            <Grid item md={12}
              key={index}
              sx={{
                marginBottom: '0.5em',
                border: '1px solid #E9EDF0',
                borderRadius: '8px',
                padding: '1em',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  paddingLeft: '1em',
                  paddingRight: '1em',
                }}
              >
                <span
                  style={{
                    color: '#828282',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal',
                    maxWidth: '100%',
                  }}
                >
                  {entry.answer_text}
                </span>
                <ProgressBar percent={percent} color={color} />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'end',
                  textAlign: 'right',
                  fontWeight: 600,
                  fontSize: '10px',
                  whiteSpace: 'nowrap',
                }}
              >
                <span style={{ fontSize: '14px' }}>{percent.toFixed(0)}%</span>
                <span>of companies</span>
              </Box>
            </Grid>
          );
        })}
      </Grid >

      {/* Modal */}
      <ResultsTable
        open={isModalOpen}
        onClose={handleCloseModal}
        navigate={navigate}
        data={data.answers}
        selectedAssessmentDefinition={selectedAssessmentDefinition}
      />
    </>
  );
};

export default ResponseDistributionChart;
