import React from 'react';
import { TextField, FormHelperText, FormControl, InputLabel, Box, Typography } from '@mui/material';
import { getCompanySizeLabel, COMPANY_SIZE_RANGES } from '../utils/companySize';

interface IntakeFormCompanySizeProps {
  value: string | number;
  onChange: (value: string | number) => void;
  error?: string;
  disabled?: boolean;
  required?: boolean;
}

const IntakeFormCompanySize: React.FC<IntakeFormCompanySizeProps> = ({
  value,
  onChange,
  error,
  disabled = false,
  required = false,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Only allow numeric input
    const numericValue = e.target.value.replace(/[^0-9]/g, '');
    onChange(numericValue);
  };

  // Get company size label based on current value
  const sizeLabel = value ? getCompanySizeLabel(value) : '';

  return (
    <Box>      
      <Typography variant="h5" color="primary" gutterBottom>
        Please enter the total number of employees in your organization
      </Typography>
      
      <FormControl fullWidth error={!!error} disabled={disabled} required={required}>
        <TextField
          id="company-size"
          label="Number of Employees"
          type="text"
          value={value || ''}
          onChange={handleChange}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          fullWidth
          error={!!error}
          margin="normal"
          placeholder="Enter employee count"
        />
        {error && <FormHelperText>{error}</FormHelperText>}
        
        {value && (
          <FormHelperText>
            Company size category: {sizeLabel}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

export default IntakeFormCompanySize;
