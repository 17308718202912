import React from 'react';
import { Paper, Typography, Box, Grid } from '@mui/material';
import { CommonTypography } from '../../../../styles/Global.styles';
import ReactSpeedometer, { Transition } from 'react-d3-speedometer';
import { max } from 'moment';

const styles = {
  dial: {
    display: 'flex', // changed from inline-block to flex
    justifyContent: 'center', // center horizontally
    alignItems: 'center',
    width: `300px`,
    height: `auto`,
    color: '#000',
    // border: '0.5px solid #fff',
    padding: '2px',
    margin: '-10px',
  },
  title: {
    fontSize: '1em',
    color: '#000',
  },
};

export default function GaugeChart({
  name = '', score, maxScore = 5, minScore = 1, useCard = true, forceRender = true,
  redLegendText = "Needs Attention", yellowLegendText = "Could Improve", greenLegendText = "Meeting Needs",
  gaugeHeight = 190, gaugeWidth = 290, ringWidth = 60, displayNeedleScore = false, needleColor = "black", gaugeBoxMargin = '10px 0px',
  valueTextFontSize = '16px', valuePaddingVertical = 0, needleTransitionDuration = 1000,
  legendWidth = '8px', legendHeight = '8px', legendMarginTop = '0', legendMarginBottom = '1em', legendFontSize = '13px', legendFontWeight = 400, legendMaxWidth = '30%' }) {
  const data = [
    { name: redLegendText, value: 52, color: '#E43E3E' },
    { name: yellowLegendText, value: 28, color: '#FFD50F' },
    { name: greenLegendText, value: 20, color: '#16B364' },
  ];

  const currentValueText =
    displayNeedleScore && score !== undefined
      ? String(score)
      : displayNeedleScore && score === undefined
        ? "No Scores Received"
        : displayNeedleScore && score < minScore
          ? "Not Enough Score Data"
          : " ";

  // This prevents the needle from glitching and dropping below the minimum value
  if (score < minScore) {
    score = minScore
  }

  const GaugeContent = (
    <>
      <Box
        margin={gaugeBoxMargin}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CommonTypography sx={{ fontSize: '19px', fontWeight: 500, textAlign: 'center' }}>
          {name}
        </CommonTypography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom="-15px"
      >
        <div style={styles.dial}>
          <ReactSpeedometer
            forceRender={forceRender}
            maxValue={maxScore}
            minValue={minScore}
            value={score || minScore}
            valueTextFontSize={valueTextFontSize}
            paddingVertical={valuePaddingVertical}
            height={gaugeHeight}
            width={gaugeWidth}
            ringWidth={ringWidth}
            needleTransitionDuration={needleTransitionDuration}
            needleColor={needleColor}
            needleHeightRatio={0.7}
            startColor=""
            endColor=""
            // segments={5}
            // customSegmentStops={[
            //   minScore,
            //   minScore + (maxScore - minScore) * 0.25,
            //   minScore + (maxScore - minScore) * 0.40,
            //   minScore + (maxScore - minScore) * 0.60,
            //   minScore + (maxScore - minScore) * 0.75,
            //   maxScore,
            // ]}
            // segmentColors={['#E43E3E', '#F28A26', '#FFD50F', '#8AC43A', '#16B364']}
            // customSegmentLabels={[
            //   { text: "" },
            //   { text: "" },
            //   { text: "" },
            //   { text: "" },
            //   { text: "" },
            // ]}
            segments={3}
            currentValueText={currentValueText}
            customSegmentStops={[
              minScore,
              minScore + (maxScore - minScore) * 0.375,
              minScore + (maxScore - minScore) * 0.75,
              maxScore,
            ]}
            segmentColors={['#E43E3E', '#FFD50F', '#16B364']}
            customSegmentLabels={[
              { text: "" },
              { text: "" },
              { text: "" },
            ]}
          />
        </div>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginTop={legendMarginTop}
        marginBottom={legendMarginBottom}
      >
        {data.map((item, i) => (
          <React.Fragment key={i}>
            <Box
              sx={{
                display: 'flex',
                maxWidth: legendMaxWidth,
                margin: '0 10px',
                alignItems: 'center'
              }}
            >
              <Box
                sx={{
                  width: legendWidth,
                  height: legendHeight,
                  borderRadius: '50%',
                  backgroundColor: item.color,
                  float: 'left',
                  marginRight: '5px',
                  position: 'relative',
                }}
              ></Box>
              <Typography
                sx={{
                  fontFamily: 'Inter',
                  fontSize: legendFontSize,
                  fontStyle: 'normal',
                  fontWeight: legendFontWeight,
                  lineHeight: 'normal',
                  color: '#6A6A6A',
                  // whiteSpace: 'nowrap',
                  // overflow: 'hidden',
                  // textOverflow: 'ellipsis',
                }}
              >
                {item.name}
              </Typography>
            </Box>
          </React.Fragment>
        ))}
      </Box>
    </>
  );

  if (!useCard) {
    return (
      <Grid container
        sx={{
          // height: '270px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {GaugeContent}
      </Grid>
    );
  }

  return (
    <Paper
      sx={{
        height: '270px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {GaugeContent}
    </Paper>
  );
}
