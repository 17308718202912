import React, { useState } from 'react';
import { Box, Grid, TextField, Tooltip } from '@mui/material';
import { Typography12px400 } from '../../../../styles/Global.styles';
import { useAppSelector } from '../../../../redux/store';
import { RequestStatus } from '../../../../types/request';
import { UpdateAssessorScoreData } from '../../type';

const AssessmentAssessorScoreInput = ({
  questionToScore,
  i,
  handleInputChange,
  currentScore,
  // handleBlurChange,
}) => {
  const [isHighlightedSuccess, setIsHighlightedSuccess] = useState(false);
  const [isHighlightedFailure, setIsHighlightedFailure] = useState(false);
  const [invalidInput, setInvalidInput] = useState(false);
  const [temporaryScore, setTemporaryScore] = useState(currentScore || '');
  const scoreMin = 1;
  const scoreMax = 5;

  const handleScoreChange = (event) => {
    setTemporaryScore(event.target.value);
  };

  const scoreChangeBlur = (event) => {
    let inputValue = temporaryScore;

    if (inputValue === '' || !isNaN(parseFloat(inputValue as string))) {
      // Limit to one decimal place if it has a decimal point
      if (inputValue.includes('.')) {
        const [wholePart, decimalPart] = inputValue.split('.');
        inputValue = `${wholePart as string}.${decimalPart.slice(0, 1) as string}`;
      }
      // Convert to a number and clamp between 1 and 5
      const numericValue = parseFloat(inputValue as string);
      if (numericValue >= scoreMin && numericValue <= scoreMax) {
        setInvalidInput(false);
        handleInputChange(i, event);
      } else if (inputValue === '') {
        // Allow empty input for clearing
        setInvalidInput(false);
        handleInputChange(i, event);
      } else {
        setInvalidInput(true); // Set error if input is invalid
      }
    }

    // handleBlurChange(i, event);
  };

  const assessmentAssessorScoreStatus = useAppSelector((state) => {
    return state.assessmentReducers.postAssessmentAssessorScoreReducer.status as RequestStatus;
  });

  const assessmentAssessorScoreResponse = useAppSelector((state) => {
    return state.assessmentReducers.postAssessmentAssessorScoreReducer.assessmentAssessorScoreResponse as (UpdateAssessorScoreData | null);
  });

  React.useEffect(() => {
    if (
      assessmentAssessorScoreStatus == 'done' &&
      assessmentAssessorScoreResponse?.assessment_question_id ==
      questionToScore.question_id
    ) {
      setIsHighlightedSuccess(true);
      setTimeout(() => setIsHighlightedSuccess(false), 2500);
    } else if (
      assessmentAssessorScoreStatus == 'error' &&
      assessmentAssessorScoreResponse?.assessment_question_id ==
      questionToScore.question_id
    ) {
      setIsHighlightedFailure(true);
      setTimeout(() => setIsHighlightedFailure(false), 2500);
    }
  }, [assessmentAssessorScoreStatus, assessmentAssessorScoreResponse, questionToScore.question_id]);

  return (
    <Grid
      container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        paddingLeft: '1em',
        gap: 1,
      }}
    >
      <Typography12px400
        sx={{
          lineHeight: '19px',
          // paddingRight: '3%',
          // textAlign: 'right',
          font: 'Inter',
          fontWeight: 600,
          color: '#5E6C84',
          fontSize: '11px',
        }}
      >
        Assessor Score:
      </Typography12px400>
      <Box key={`${i as string}-input`}>
        <Tooltip
          title={
            invalidInput ? `Value must be between ${scoreMin}.0 and ${scoreMax}.0` : ''}
          open={invalidInput} // Only show when invalidInput is true
          placement="top"
          arrow
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            lineHeight: '21px',
            maxHeight: '21px'
          }}
        >
          <TextField
            type="number"
            variant="outlined"
            // value={currentScore === 0 ? 0 : currentScore || ''}
            value={temporaryScore}
            onChange={handleScoreChange}
            onBlur={scoreChangeBlur}
            size="small"
            sx={{
              width: '38px',
              transition: 'border-color 0.3s ease',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: isHighlightedSuccess
                    ? 'green'
                    : isHighlightedFailure
                      ? 'red'
                      : 'gray',
                },
                //This is incase the user is hovering over the input
                '&:hover fieldset': {
                  borderColor: isHighlightedSuccess
                    ? 'green'
                    : isHighlightedFailure
                      ? 'red'
                      : 'gray',
                },
              },
            }}
            inputProps={{
              min: 0,
              max: 5,
              sx: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '13px',
                font: 'Inter',
                fontWeight: 600,
                textAlign: 'center',
                lineHeight: '21px',
                maxHeight: '21px',
                paddingY: '4px',
                paddingX: 0,
                // paddingRight: '0px', // remove padding to counter arrow offset
                // Hide the arrows
                '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                  display: 'none',
                },
                '&[type=number]': {
                  MozAppearance: 'textfield', // Hide arrows in Firefox
                },
              },
            }}
          />
        </Tooltip>
      </Box>
    </Grid>
  );
};

export default AssessmentAssessorScoreInput;
