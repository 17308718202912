import React from 'react';

interface SortHighToLowIconProps {
    width?: string;
    height?: string;
    color?: string;
}

function SortHighToLowIcon({
    width = '24px',
    height = '24px',
    color = 'none',
}: SortHighToLowIconProps) {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.5326 21C12.9218 21 13.295 20.842 13.5702 20.5607C13.8454 20.2794 14 19.8978 14 19.5C14 19.1022 13.8454 18.7206 13.5702 18.4393C13.295 18.158 12.9218 18 12.5326 18L10.25 18C9.86082 18 9.48759 18.158 9.2124 18.4393C8.93721 18.7206 8.78261 19.1022 8.78261 19.5C8.78261 19.8978 8.93721 20.2794 9.2124 20.5607C9.48759 20.842 9.86082 21 10.25 21L12.5326 21ZM15.5326 14.5C15.9218 14.5 16.295 14.342 16.5702 14.0607C16.8454 13.7794 17 13.3978 17 13C17 12.6022 16.8454 12.2206 16.5702 11.9393C16.295 11.658 15.9218 11.5 15.5326 11.5L7.72826 11.5C7.33909 11.5 6.96585 11.658 6.69066 11.9393C6.41547 12.2206 6.26087 12.6022 6.26087 13C6.26087 13.3978 6.41547 13.7794 6.69066 14.0607C6.96585 14.342 7.33909 14.5 7.72826 14.5L15.5326 14.5ZM20 6.5C20 6.89783 19.8454 7.27936 19.5702 7.56066C19.295 7.84197 18.9218 8 18.5326 8L5.46739 8C5.07822 8 4.70498 7.84197 4.42979 7.56066C4.1546 7.27936 4 6.89783 4 6.5C4 6.10218 4.1546 5.72065 4.42979 5.43934C4.70498 5.15804 5.07822 5 5.46739 5L18.5326 5C18.9218 5 19.295 5.15804 19.5702 5.43934C19.8454 5.72065 20 6.10218 20 6.5Z" fill="#00314B" />
        </svg>
    );
}

export default SortHighToLowIcon;
