import React, { ReactNode } from 'react';
import {
    Navigate, createBrowserRouter
} from 'react-router-dom';
import ResetPasswordComponent from './modules/Auth/ResetPassword/ResetPassword';
import InquiryForm from './modules/InquiryForm/InquiryForm';
import Dashboard from './modules/Dashboard/Dashboard';
import ClientList from './modules/Accounts/ClientList/ClientList';
import AssessmentListContianer from './modules/Assessments/AssessmentList/AssessmentList';
import AccountDetailView from './modules/Accounts/AccountDetailView/AccountDetailView';
import AssessmentDetailContainer from './modules/Assessments/AssessmentDetails/AssessmentDetailContainer';
import { RootStoreType } from './redux/store.type';
import AppRouter from './AppRouter';
import CreateClient from './modules/Accounts/CreateClient/CreateClient';
import CreateAssessmentContainer from './modules/Assessments/CreateAssessment/CreateAssessmentContainer';
import Settings from './modules/Settings/Settings';
import { SuccessPage } from './modules/InquiryForm/SuccessPage';
import EvaluationScore from './modules/Evaluations/EvaluationScore/EvaluationScore';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import LoginComponent from './modules/Auth/Login/Login';
import PageNotFound from './modules/PageNotFound';
import EditAssessmentContainer from './modules/Assessments/EditAssessment/EditAssessmentContainer';
import EditAccount from './modules/Accounts/EditAccount/EditAccount';
import EvaluationScoreAnalysisContainer from './modules/Assessments/CategoryAnalysis/AssessmentAnalysisScoreContainer';
import ForgetPasswordComponent from './modules/Auth/ForgetPassword/ForgetPassword';
import Scoin from './modules/Scoin/Scoin';
import IntakeFormMain from './components/IntakeForm/IntakeFormMain';



const ProtectedRoute = ({ children }: { children: React.ReactElement }) => {
    const { currentUser } = useSelector(
        //eslint-disable-next-line @typescript-eslint/no-unsafe-return
        (state: RootStoreType) => state.authReducers.user,
    );

    const toastId = "access-error";

    if (currentUser === undefined) {
        return <div>Loading...</div>;
    }

    if (!currentUser) {
        if (!toast.isActive(toastId)) {
            toast.error("No user is currently logged in", { toastId });
        }
        return <Navigate to="/" replace />;
    }

    if (
        currentUser &&
        !Array.isArray(currentUser?.user_type) ||
        !(
            currentUser.user_type.includes("admin") ||
            currentUser.user_type.includes("account_executive") ||
            currentUser.user_type.includes("client")
        )
    ) {
        // Show a toast and redirect
        if (!toast.isActive(toastId)) {
            toast.error("You do not have access permissions.", { toastId });
        }
        return <Navigate to="/" replace />;
    }

    return children;
};

const AdminOrAeProtectedRoute = ({ children }: { children: React.ReactElement }) => {
    const { currentUser } = useSelector(
        (state: RootStoreType) => state.authReducers.user
    );

    const toastId = "admin-ae-error";

    if (currentUser === undefined) {
        // While loading, show a spinner or placeholder
        return <div>Loading...</div>;
    }

    if (
        !currentUser ||
        !Array.isArray(currentUser?.user_type) ||
        !(
            currentUser.user_type.includes("admin") ||
            currentUser.user_type.includes("account_executive")
        )
    ) {
        if (!toast.isActive(toastId)) {
            toast.error("Access denied. Admin or Account Executive role required.", { toastId });
        }
        return <Navigate to="/" replace />;
    }

    return children;
};

export const router = createBrowserRouter([
    {
        path: '/',
        element: (<LoginComponent />) as ReactNode,
        errorElement: (<PageNotFound />) as ReactNode,
    },
    {
        path: '/forget-password',
        element: (<ForgetPasswordComponent />) as ReactNode,
        errorElement: (<PageNotFound />) as ReactNode,
    },
    {
        path: '/reset-password',
        element: (<ResetPasswordComponent />) as ReactNode,
        errorElement: (<PageNotFound />) as ReactNode,
    },
    {
        path: '/evaluate/:id',
        element: (<InquiryForm />) as ReactNode,
        errorElement: (<PageNotFound />) as ReactNode,
    },
    {
        path: '/assessment/:id',
        element: (<InquiryForm />) as ReactNode,
        errorElement: (<PageNotFound />) as ReactNode,
    },
    {
        path: '/success-evaluation-assessment',
        element: (<SuccessPage />) as ReactNode,
        errorElement: (<PageNotFound />) as ReactNode,
    },
    {
        path: 'intake-form',
        element: (<IntakeFormMain />) as ReactNode,
    },
    {
        path: '/',
        element: (
            <ProtectedRoute>
                <AppRouter />
            </ProtectedRoute>
        ),
        errorElement: (<PageNotFound />) as ReactNode,
        children: [
            {
                path: 'scoin',
                element: (
                    <AdminOrAeProtectedRoute>
                        <Scoin />
                    </AdminOrAeProtectedRoute>
                ) as ReactNode,
            },
            {
                path: 'dashboard',
                element: (
                    <Dashboard />
                ) as ReactNode,
            },
            {
                path: 'evaluate/:id',
                element: (<AdminOrAeProtectedRoute><InquiryForm /></AdminOrAeProtectedRoute >) as ReactNode,
            },
            {
                path: 'account-list',
                element: (
                    <AdminOrAeProtectedRoute>
                        <ClientList />
                    </AdminOrAeProtectedRoute>
                ) as ReactNode,
            },
            {
                path: `evaluation-assessment-list`,
                element: (
                    <AdminOrAeProtectedRoute>
                        <AssessmentListContianer />
                    </AdminOrAeProtectedRoute>
                ) as ReactNode,
            },
            {
                path: 'account-detail-view/:id',
                element: (
                    <AdminOrAeProtectedRoute>
                        <AccountDetailView />
                    </AdminOrAeProtectedRoute>
                ) as ReactNode,
            },
            {
                path: 'evaluation-assessment-detail/:id',
                element: (
                    <AdminOrAeProtectedRoute>
                        <AssessmentDetailContainer />
                    </AdminOrAeProtectedRoute>
                ) as ReactNode,
            },
            {
                path: 'evaluation-assessment-detail/:id/evaluation-assessment-analysis-score',
                element: (
                    <AdminOrAeProtectedRoute>
                        <EvaluationScoreAnalysisContainer />
                    </AdminOrAeProtectedRoute>
                ) as ReactNode,
            },
            {
                path: 'create-evaluation-assessment',
                element: (
                    <AdminOrAeProtectedRoute>
                        <CreateAssessmentContainer />
                    </AdminOrAeProtectedRoute>
                ) as ReactNode,
            },
            {
                path: 'edit-evaluation-assessment/:id',
                element: (
                    <AdminOrAeProtectedRoute>
                        <EditAssessmentContainer />
                    </AdminOrAeProtectedRoute>
                ) as ReactNode,
            },
            {
                path: 'create-account',
                element: (
                    <AdminOrAeProtectedRoute>
                        <CreateClient />
                    </AdminOrAeProtectedRoute>
                ) as ReactNode,
            },
            {
                path: 'edit-account/:id',
                element: (
                    <AdminOrAeProtectedRoute>
                        <EditAccount />
                    </AdminOrAeProtectedRoute>
                ) as ReactNode,
            },
            {
                path: 'settings',
                element: (
                    <AdminOrAeProtectedRoute>
                        <Settings />
                    </AdminOrAeProtectedRoute>
                ) as ReactNode,
            },
            {
                path: 'evaluation-assessment-score',
                element: (
                    <AdminOrAeProtectedRoute>
                        <EvaluationScore />
                    </AdminOrAeProtectedRoute>
                ) as ReactNode,
            },
        ],
    },
]);
